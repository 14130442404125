import Styles from './Header.scss';

import Template from './Header.hbs';
import CrumblepathButton from './crumblepathButton/CrumblepathButton'
import CrumblepathItem from './crumblepathItem/CrumblepathItem'
import ShapeList from 'util/ShapeLoader';

export default class Header extends BaseView {

    get iconContainerElement() {
        return this.el.querySelector('.js-icon-container')
    }

    initialize() {
        _.bindAll(
            this,
            'setTitle',
            'addButton',
            'clearButtons',
            'setCrumblepath'
        )

        this.setElement(Template({
            Styles
        }))

        this.buttons = []
        this.crumblepathItems = []
        this.crumblepathButtons = []

        if ('ResizeObserver' in window) {
            const observer = new ResizeObserver(() => {
                window.requestAnimationFrame(() => {
                    this.resizeCrumblepath()
                })
            })
            observer.observe(this.el)
        }

        this.setCrumblepath()
    }

    // Set the title of the page. This can either be a string or a view (like the greeting)
    setTitle(title = '', prependedIcon) {
        this.titleView?.destroy()
        this.el.style.display = ''
        this.iconContainerElement.innerHTML = ''

        if (typeof title === 'string') {
            this.el.querySelector('.js-page-title').textContent = title
            this.el.querySelector('.js-page-title').setAttribute('title', title)
        }

        if (typeof title === 'object') {
            this.el.querySelector('.js-page-title').textContent = ''
            this.el.querySelector('.js-page-title').removeAttribute('title')
            this.titleView = this.addChildView(title, '.js-page-title')
        }

        if (prependedIcon) {
            this.iconContainerElement.innerHTML = ShapeList[prependedIcon]
        }
    }

    // The 'action' parameter should be something like 'progress', 'author' or 'planner'. It is used for
    // the group chooser in the menu bar and for the crumblepath buttons
    setCrumblepath(models = [], action = '') {

        // Destroy previously used items and buttons
        for (const crumblepathItem of this.crumblepathItems) {
            crumblepathItem.destroy()
        }
        this.crumblepathItems = []
        for (const crumblepathButton of this.crumblepathButtons) {
            crumblepathButton.destroy()
        }
        this.crumblepathButtons = []

        // Use a dark header background to separate author from the other views
        if (action === 'author') {
            this.el.classList.add(Styles['author'])
        } else {
            this.el.classList.remove(Styles['author'])
        }

        // Hide the crumblepath if no models are present
        if (models.length === 0) {
            this.el.querySelector('.js-crumblepath-container').style.display = 'none'
            return
        }

        this.el.style.display = ''
        this.el.querySelector('.js-crumblepath-container').style.display = ''
        models.forEach((model, index) => {

            // We do not want task groups in the crumblepath, except in author
            if (model.get('level') === 'task_groups' && action !== 'author') {
                return
            }

            // If we encounter a group model, update the selected group in the menubar
            if (model.get('level') === 'groups') {
                const groupModel = Backbone.Collection.groups.get(model.get('path_id'))
                Backbone.View.menubar?.setGroup(groupModel, action)
            }

            this.crumblepathItems.push(this.addChildView(
                new CrumblepathItem({
                    model,
                    action,
                    isFirstItem: index === 0
                }),
                '.js-crumblepath'
            ))
        })

        // Add crumblepath buttons if needed
        const lastModel = _.last(models)
        const buttonActions = ['show', 'progress', 'author']
        const buttonLevels = ['chapters', 'sections', 'activities', 'task_groups']
        if (!ISMOBILE &&
            Backbone.Model.user.get('is_teacher') &&
            buttonActions.includes(action) &&
            buttonLevels.includes(lastModel.get('level')))
        {
            for (const buttonAction of buttonActions) {
                // Since /progress/task_groups/ requires a student-specific parameter we don't have, create a link
                // to /progress/activities/ instead.
                const model = lastModel.get('level') === 'task_groups' && buttonAction === 'progress' ?
                    _.last(models, 2)[0] :
                    lastModel

                this.crumblepathButtons.push(this.addChildView(
                    new CrumblepathButton({
                        model,
                        action: buttonAction,
                        isActive: buttonAction === action
                    }),
                    '.js-crumblepath-buttons'
                ))
            }
        }

        // Change document title for show, progress and author pages to make browser history easier to navigate.
        const labelForDocumentTitle = lastModel.get('level') === 'task_groups' ?
            models[models.length - 2]?.get('label') :
            lastModel.get('label')
        switch (action) {
            case 'show':
            case 'progress':
            case 'author':
                document.title = (labelForDocumentTitle ? `${labelForDocumentTitle} | ` : '') + window.app_version.label
                break
            default:
                document.title = window.app_version.label
                break
        }

        this.resizeCrumblepath()
    }

    // Add a background image to the header, based on a given model. The model should implement
    // both getHeaderImage and getBackgroundImage
    setBackgroundImage(model) {
        if (!model) {
            this.removeBackgroundImage()
            return
        }

        const headerImage = model.getHeaderImage()
        const backgroundImage = model.getBackgroundImage()
        const url = headerImage || backgroundImage
        if (!url) {
            this.removeBackgroundImage()
            return
        }

        this.el.style.backgroundImage = 'url(' + url + ')'

        // If the image is not optimized for the header, we need to blur it
        this.el.classList.toggle(Styles['blur-image'], !headerImage)
    }

    // Remove the background image from the header (if present)
    removeBackgroundImage() {
        this.el.style.backgroundImage = ''
        this.el.classList.remove(Styles['blur-image'])
    }

    // Add a button to the header. Set prepend to true to insert the button before existing buttons.
    addButton(view, prepend = false) {
        const button = this.addChildView(
            view,
            '.js-page-buttons',
            prepend ? 'prepend' : undefined
        )
        this.buttons.push(button)
        return button
    }

    // Clear all buttons from the header. This is needed when loading a new page.
    clearButtons() {
        for (const button of this.buttons) {
            button.destroy()
        }
        this.buttons = []
    }

    // Hide the header completely. Use this when the page does not need the header.
    hide() {
        this.el.style.display = 'none'
    }

    resizeCrumblepath() {
        const crumblepath = this.el.querySelector('.js-crumblepath')

        // Start by showing the full crumblepath
        for (const label of crumblepath.querySelectorAll('.js-index-label[data-hide=true]')) {
            label.removeAttribute('data-hide')
        }

        // If the crumblepath does not fit, look for the first visible label with an index and hide it
        // If that is not enough, continue to the next one
        let label = crumblepath.querySelector('.js-index-label:not([data-hide=true])')
        while (label && crumblepath.scrollWidth > crumblepath.clientWidth) {
            label.setAttribute('data-hide', 'true')
            label = crumblepath.querySelector('.js-index-label:not([data-hide=true])')
        }
    }

}
