import Styles from './Template11.scss';

import Template from './Template11.hbs';
import Item from 'views/components/taskGroups/tasks/template11/item/Item';
import AnswerItem from 'views/components/taskGroups/tasks/template11/Template11Answer.hbs';
import ItemStyles from 'views/components/taskGroups/tasks/template11/item/Item.scss';

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'onDragStart',
            'onDragStop',
            'createDraggableItem',
            'lockAnswer',
            'unlockAnswer'
        );

        this.taskView = options.task_view;

        // If no inputType is defined, fall back on 'text'.
        this.inputType = this.model.get('task_info_json').inputType || 'text';

        this.renderTask()
    },

    /**
         * renderTask
         *
         * This method will render the template of the task. It will be
         * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
         * if only the answer view is necessary
         *
         */
    renderTask() {
        this.setElement(Template({
            Styles
        }));

        this.columnAElement = this.$('.js-column-a');
        this.columnBElement = this.$('.js-column-b');

        // task_info_json
        // {
        //  "answer":{
        //      "a":["De val van het Romeinse Rijk","De kroning van Karel de Grote tot keizer "],
        //      "b":["Het jaar 476","Het jaar 800"]
        //  },
        //  "options":{"partialGrading":true}
        // }

        // json_answer
        // [[1,"Het jaar 800"],[2,"Het jaar 476"]]

        this.columnAItems = this.convertToCollection(this.model.get('task_info_json').answer.a);
        this.columnBItems = this.convertToCollection(this.model.get('task_info_json').answer.b);

        // Student answer data. Convert index number to int to avoid type mismatches.
        // If viewed from the author, use the correct answer as the given answer.
        let answer = []
        if (APPLICATION === 'author') {
            answer = _.map(this.model.get('task_info_json').correct_answer, (answer, index) => {
                return [index + 1, answer]
            })
        } else {
            answer = _.map(this.taskView.response.get('json_answer'), (answer) => {
                return [parseInt(answer[0]), answer[1]];
            })
        }

        // For each item in the left column, see if student has an answer for this item. If so,
        // set the model of this answer as chained to this item in the left column by removing it
        // from the right column. Then create a view for this item and append it to the left column.
        this.columnAItems.each((modelA, index) => {
            modelA.set({
                chained: this.columnBItems.remove(
                    this.columnBItems.find((modelB) => {
                        return _.findWhere(answer, [(index + 1), modelB.get('data')]);
                    })
                )
            });

            this.addChildView(new Item({
                model: modelA,
                columnBItems: this.columnBItems,
                isColumnA: true,
                inputType: this.inputType,
                taskID: this.model.id
            }), this.columnAElement);

        })

        // For each item in the right column, create a list item view that is draggable.
        this.columnBItems.each(this.createDraggableItem);

        // Each time an item is added back to the right column, reflect this change for the user.
        this.listenTo(this.columnBItems, 'add', this.onResetItem);

        // If user is student, save the answer every time the answerUpdate custom event has triggered.
        if (Backbone.Model.user.get('is_student')) {
            this.listenTo(this.columnBItems, 'answerUpdate', this.saveAnswer);
        }

        // Make the right column sticky when it is less high than the window (if browser supports it)
        if ('ResizeObserver' in window) {
            const columnBObserver = new ResizeObserver(entries => {
                requestAnimationFrame(() => {
                    for (const entry of entries) {
                        entry.target.classList.toggle(
                            Styles.column__sticky,
                            entry.contentRect.height < window.innerHeight
                        )
                    }
                })
            });
            columnBObserver.observe(this.el.querySelector('.js-column-b'));
        }
    },

    /**
     * Converts a plain 1 dimensional array to a Backbone Collection.
     *
     * @param  {Array}               a  Array of values representing the name or media in each column item.
     * @return {Backbone.Collection}    Collection where each model has a attribute data with array's value.
     */
    convertToCollection(a) {
        return new Backbone.Collection(a.map((v) => {
            return {
                data: v
            };
        }));
    },

    /**
     * Creates a draggable item view using the item data model.
     *
     * @param  {Backbone.Model} model item data
     */
    createDraggableItem(model) {
        const item = this.addChildView(new Item({ model }), this.columnBElement)
        item.$el.draggable({
            revert: 'invalid',
            cursor: 'move',
            containment: this.$el,
            appendTo: this.$('.js-template-11-draggable'),
            helper: 'clone',
            cursorAt: {
                top: 25,
                left: 25
            },
            start: this.onDragStart,
            stop: this.onDragStop,
            scope: this.model.id,
            scrollSensitivity: 60,
            scrollSpeed: 40
        });
    },

    /**
     * At dragging start, disable visibility of the original element.
     *
     * @param  {jQuery.event} e  jQuery event data.
     * @param  {Object} ui       jQuery UI data for the object being dragged.
     */
    onDragStart(e, ui) {
        const dragItem = $(ui.helper)
        // Find the original element by searching the items list element for a element with the same data attribute
        // as the element that is being dragged and make it invisible.
        this.columnBElement.find(`.js-item[data-cid="${dragItem.data('cid')}"]`).css({
            visibility: 'hidden'
        });
    },

    /**
     * At dragging has ended, enable visibility of the original element.
     *
     * @param  {jQuery.event} e  jQuery event data.
     * @param  {Object} ui       jQuery UI data for the object being dragged.
     */
    onDragStop(e, ui) {
        const dragItem = $(ui.helper)
        // TODO fix origin point after scroll.
        // Find the original element by searching the items list element for a element with the same data attribute
        // as the element that is being dragged and make it visible.
        this.columnBElement.find(`.js-item[data-cid="${dragItem.data('cid')}"]`).css({
            visibility: 'visible'
        });
    },

    /**
     * When removing an item from a category, unhide this item in the item list. If this item doesn't exist due
     * to it not being present when the page was loaded, create a new item view.
     *
     * @param  {Backbone.Model} model item data
     */
    onResetItem(model) {
        const itemElement = this.columnBElement.find(`.js-item[data-cid="${model.cid}"]`)
        if (itemElement.length === 1) {
            itemElement.show();
        } else {
            this.createDraggableItem(model);
        }
    },

    /**
     * Create a simple array containing arrays with the index and the data of each answer.
     */
    saveAnswer() {
        const savedAnswer = [];
        this.columnAItems.each((model, index) => {
            if (model.get('chained')) {
                savedAnswer.push([index + 1, model.get('chained').get('data')]);
            }
        });
        this.taskView.saveResponse(savedAnswer);
    },

    /**
     * Show the correct answer to the task.
     */
    showAnswer() {
        // Abort method if origin is the author.
        if (APPLICATION === 'author') {
            return;
        }

        const correctAnswers = this.model.get('task_info_json').correct_answer
        this.columnAItems.each((modelA, index) => {

            const itemElement = this.columnAElement.find(`.js-item[data-cid="${modelA.cid}"]`)
            itemElement.addClass('js-show-answers');
            var chainedElement = itemElement.find('.js-chained-item .js-item');
            chainedElement.addClass('js-show-answers');

            // If item has something chained to it and is also the correct answer, mark the element
            // as such in the DOM by making it green. Otherwise, make it read and show a label with
            // the name of the item it should have been.
            const chained = modelA.get('chained')
            if (chained && _.isEqual(chained.get('data'), correctAnswers[index])) {
                itemElement.addClass(ItemStyles['item--correct'])
                chainedElement.addClass(ItemStyles['item--correct'])
            } else {
                itemElement.addClass(ItemStyles['item--incorrect'])
                chainedElement.addClass(ItemStyles['item--incorrect'])
                itemElement.find('.js-correct-answer div').last().html(correctAnswers[index]);
                itemElement.find('.js-correct-answer').last().css({
                    display: 'inline-flex'
                });
            }
        })

        // Mark all items that should have been in chained that are still left in the right column as red.
        this.columnBItems.each((modelB) => {

            // Find item element related to the model in the DOM
            const itemElement = this.columnBElement.find(`.js-item[data-cid="${modelB.cid}"]`)

            if (_.contains(correctAnswers, modelB.get('data'))) {
                itemElement.addClass(ItemStyles['item--incorrect'])
            } else {
                // If the draggable shouldn't be chained to an answer, it's a distractor
                itemElement.addClass(ItemStyles['item--distractor'])
            }
        })
    },

    showAuthorAnswer() {},

    /**
     * Hide the correct answer to the task.
     */
    hideAnswer() {
        this.$('.js-item').removeClass([
            'js-show-answers',
            ItemStyles['item--correct'],
            ItemStyles['item--incorrect'],
            ItemStyles['item--distractor']
        ])

        this.$('.js-item .js-correct-answer div').text('');
        this.$('.js-item .js-correct-answer').hide();
    },

    /**
     * Generate summary of the correct answer to use above the list of student answers.
     *
     * @return {string} HTML blob to be inserted above the list of student answers.
     */
    getCorrectAnswer() {
        return AnswerItem({
            isImage: (this.inputType === 'image'),
            Styles,
            ItemStyles,
            options: this.model.get('task_info_json').answer.a,
            correctAnswers: this.model.get('task_info_json').correct_answer
        });
    },

    /**
     * Generate summary of the answer student has given for use in the list of student answers.
     *
     * @param  {Object} responseModel Student response data
     * @return {string}          Template for student answer
     */
    getStudentAnswer(responseModel) {
        if (responseModel.get('json_answer')) {
            // Create array of the same length as items in column A.
            const fixedResponse = new Array(this.model.get('task_info_json').answer.a.length)

            _.each(responseModel.get('json_answer'), (item) => {
                fixedResponse[(item[0] - 1)] = item[1];
            });

            return AnswerItem({
                isImage: (this.inputType === 'image'),
                Styles,
                ItemStyles,
                options: this.model.get('task_info_json').answer.a,
                answers: fixedResponse,
                correctAnswers: this.model.get('task_info_json').correct_answer
            });
        }
        return ''
    },

    /**
     * Triggered when the lock-answers event is sent from a Presentation view.
     * It will make sure students can't change their answer. This is typically
     * done when the taskState inside the presentation is 1 or 2, meaning the
     * teacher is showing the student's answers.
     */
    lockAnswer() {
        this.columnBElement.find('.js-item').draggable('disable');
        _.invoke(this.getChildViewsOfInstance(Item), 'lock');
    },

    /**
     * Triggered when the unlock-answers event is sent from a Presentation view.
     * It will make sure students chan fill an answer again.
     */
    unlockAnswer() {
        this.columnBElement.find('.js-item').draggable('enable');
        _.invoke(this.getChildViewsOfInstance(Item), 'unlock');
    }

});
