import Styles from './Exam.scss';

import Template from './Exam.hbs';
import ExamReportModel from 'models/ExamReportModel'
import LinearActivity from 'views/pages/activities/show/types/linear/Linear'
import TaskGroup from 'views/components/taskGroups/TaskGroup'
import Summary from 'views/pages/activities/show/types/exam/summary/Summary'
import End from 'views/pages/activities/show/types/exam/end/End'
import LogoutView from 'views/pages/activities/show/types/exam/logout/Logout'
import HeroButton from 'views/components/heroButton/HeroButton'
import Avatar from 'views/components/avatar/Avatar'
import StandbyMode from 'util/StandbyMode'
import FullscreenMode from 'util/FullscreenMode'
import Button from 'views/components/button/Button'
import Util from 'util/util'
import HeroDropdown from 'views/components/heroButton/heroDropdown/HeroDropdown'
import Clock from 'views/components/clock/Clock';

export default class ExamActivity extends LinearActivity {

    initialize({
        activityShow,
        currentWorkOnPageId,
    }) {

        _.bindAll(
            this,
            'addNavigationBar',
            'exitSEBLaunchPage',
            'onClickFinishExamActivity',
            'onNavigationKeyUp',
            'onPaste',
            'onVisibilityChange',
            'onBlur',
            'replaceWithEndView',
            'onFullscreenChange',
            'setupGlobalLayout',
            'onClickAboutTaskGroup',
            'onClickPrintOnlySources',
            'onClickPrintWithAnswers',
            'onClickPrintWithoutAnswers',
            'showSidebarActivityNavigation',
        )

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle()

        this.activityShow = activityShow

        const isStudent = ACL.checkRole(ACL.roles.STUDENT)

        // User goes into exam mode if it is a student, correct answers are invisible,
        // user has an exam session that hasn't got a close timestamp.
        this.isStudentExamMode = (
            isStudent &&
            !this.model.get('show_answers') &&
            this.model.has('exam_session') &&
            this.model.get('exam_session').closed === null
        );

        // Check if isStudentExamMode + not_in_safe_exam_browser === true, meaning
        // that the student is currently in Learnbeat in a normal browser on its
        // way to be redirected to Safe Exam Browser.
        this.isStudentRedirectedToSEB = (
            this.isStudentExamMode &&
            this.model.getMetadata('not_in_safe_exam_browser')
        );

        // Check if isStudentExamMode + exam safe exam browser enforcement +
        // not is safe exam browser === false, meaning that the student is
        // currently in the Safe Exam Browser applicaton.
        this.isStudentInSEB = (
            this.isStudentExamMode &&
            !!parseInt(this.model.getMetadata('exam_safe_exam_browser_enforced')) &&
            this.model.getMetadata('not_in_safe_exam_browser') === false
        );

        if (this.isStudentExamMode) {

            if (!this.isStudentInSEB) {
                // Try to disable the auto standby for the student's device for the
                // duration of the exam.
                StandbyMode.disableAutoStandby()
            }
        }

        this.setElement(Template({
            Styles,
            isStudentExamMode: this.isStudentExamMode,
            isStudent,
            name: this.model.get('name'),
            isDiagnosticExam: this.isDiagnosticExam(),
        }));

        // Remove the menubar and crumblepath and prevent user from navigating to
        // another page using their history or other means that isn't typing in
        // an exact URL.
        this.setupGlobalLayout()

        // hide pagination bar initially to prevent it showing up at summary view
        this.$('.js-pagination-bar').hide();

        $('body').addClass('is-work-on');

        if (
            ISMOBILE &&
            this.isStudentExamMode &&
            !this.isDiagnosticExam()
        ) {

            this.activeItem = this.addChildView(
                new LogoutView(),
                '.js-activity-viewport'
            );

        } else {

            if (ACL.checkRole(ACL.roles.TEACHER)) {
                const printOptions = [
                    {
                        label: window.i18n.gettext('Print only text'),
                        icon: 'print',
                        callback: this.onClickPrintOnlySources,
                    },
                    {
                        label: window.i18n.gettext('Print without answers'),
                        icon: 'print',
                        callback: this.onClickPrintWithoutAnswers,
                    },
                    {
                        label: window.i18n.gettext('Print with answers'),
                        icon: 'print',
                        callback: this.onClickPrintWithAnswers,
                    },
                ]

                this.printDropdown = Backbone.View.header.addButton(
                    new HeroDropdown({
                        firstLine: window.i18n.gettext('Print'),
                        icon: 'print',
                        dropdownItems: printOptions,
                    })
                )

                this.optionsDropdown = Backbone.View.header.addButton(
                    new HeroDropdown({
                        firstLine: window.i18n.gettext('Options'),
                        icon: 'arrow-drop-down-circle',
                        dropdownItems: [
                            ...printOptions,
                            {
                                label: window.i18n.gettext('About content'),
                                icon: 'info-circle',
                                callback: this.onClickAboutTaskGroup,
                            },
                        ],
                    })
                )
            }

            // Only show exit exam button in student exam mode.
            if (this.isStudentExamMode) {
                if (!this.isDiagnosticExam()) {
                    Backbone.View.header.setTitle(
                        new Avatar({
                            avatar: Backbone.Model.user.get('avatar'),
                            label: Backbone.Model.user.first_name_last_name(),
                            isHero: true
                        })
                    );

                    // Add button to the hero for the student to log out.
                    this.logoutButton = Backbone.View.header.addButton(
                        new HeroButton({
                            firstLine: window.i18n.gettext('Log out'),
                            icon: 'off',
                            callback: Backbone.Model.user.logOut
                        })
                    )
                }

                this.exitExamBtn = Backbone.View.header.addButton(
                    new HeroButton({
                        firstLine: this.isDiagnosticExam() ?
                            window.i18n.gettext('Hand in diagnostic exam') :
                            window.i18n.gettext('Hand in exam'),
                        icon: 'take-in',
                        callback: this.onClickFinishExamActivity,
                    })
                )
            }

            // If in student exam mode that does not involve SEB, add listeners that detect
            // suspicious events and report them as ExamReportModel to the teacher.
            if (this.isStudentExamMode &&
                !this.isStudentRedirectedToSEB &&
                !this.isStudentInSEB &&
                !this.isDiagnosticExam()
            ) {

                // If the activity has fullscreen mode enabled for students, have them open
                // fullscreen (if supported) and listen to the fullscreenchange event
                if (parseInt(this.model.getMetadata('fullscreen_enabled'))) {
                    this.setupFullscreenMode()
                }

                // Listen for when student recovers from losing focus on the current browser tab.
                // Throttle to the saving of exam reports only 1 every 100ms to prevent creating
                // duplicate information on the same event, overwhelming the teacher with a wall
                // of notification on this student.
                this.onVisibilityChange = _.throttle(this.onVisibilityChange, 100, {trailing: false});
                document.addEventListener('visibilitychange', this.onVisibilityChange);

                // Listen for the blur and focus event to add an overlay when the exam is not focused.
                // This helps prevent cheating using other apps such as screenshot tools
                this.onBlur = _.throttle(this.onBlur, 100, {trailing: false});
                window.addEventListener('blur', this.onBlur)
                window.addEventListener('focus', this.onFocus)

                // Listen to paste events
                document.addEventListener('paste', this.onPaste);

                // Listen to paste events on iframes
                this.addPasteEventListenersToIframes();

                // Clear the clipboard of the student to prevent using previously stored text.
                // This should happen after user interaction.
                $('body').on('mousedown', this.clearClipboard)
            }

            if (this.isStudentRedirectedToSEB) {
                // If student has to be redirected to opening the exam in SEB, do not add activity
                // navigation and instead show the activity start page directly.
                this.parseSummary(-1)
                this.$('.js-taskgroup').html(this.activeItem.$el)
                this.activeItem.onInView()
            } else {
                this.addNavigationBar(currentWorkOnPageId)

                // Scroll to task element for when if URL hash is set.
                _.defer(this.scrollToTask)

                this.addPaginationNavigation()

                if (!ISMOBILE) {
                    // Create keyup listener for navigation task groups with left and right arrow keys.
                    $(document).on('keyup', this.onNavigationKeyUp)
                }
            }

            // Only listen for teacher closing exam and check other sessions during when the
            // student is making the exam.
            if (
                this.isStudentExamMode ||
                (this.isDiagnosticExam() && isStudent)
            ) {
                // Listen for socket message when the teacher has taken in the exam.
                this.listenTo(Backbone.Model.user, 'close-exam-by-teacher', this.replaceWithEndView);

                // Students are not allowed to have multiple sessions during an exam.
                // Do not do this check if the student is in the Safe Exam Browser.
                if (!this.isStudentInSEB && !this.isDiagnosticExam()) {
                    Backbone.Model.user.logOutOtherSessionsBySameStudent();
                }
            }

            // When students are reviewing an exam, make it difficult to copy the content
            if (isStudent && this.model.get('show_answers')) {
                document.addEventListener('contextmenu', this.onContextMenu)
                document.addEventListener('copy', this.onCopy)
            }
        }

    }

    setupGlobalLayout() {
        if (this.isStudentExamMode && !this.isDiagnosticExam()) {
            Backbone.View.layout.destroyMenubar()
            Backbone.View.header.setCrumblepath()
        }
    }

    /**
     * If the student is detected making Learnbeat invisible by going to another tab
     * in the browser or covering it with another window, report this event with the
     * last visited URL to the teacher.
     */
    onVisibilityChange() {
        if (document.visibilityState === 'hidden') {
            var examReportModel = new ExamReportModel({
                activity_id: this.model.id,
                type: 'visibilitychange',
                info: {
                    last_page: location.pathname
                }
            });
            examReportModel.save();
        }
    }

    // When the blur event occurs, it probably means the students switched to another application
    // We add an overlay to the exam and send a report
    onBlur() {

        // Use a 10ms timeout so that document.hasFocus() is updated
        setTimeout(() => {

            // Check if anything in the document has focus, to prevent triggering this on iframes
            if (document.hasFocus()) {
                return
            }

            // On iPad, closing the on screen keyboard triggers a blur event. Prevent sending a report in that case.
            const isIpad = Util.hasSupportForTouch() && ['iOS', 'Mac OS'].includes(window.uaparser.getOS().name)
            if (isIpad && ['textarea', 'input'].includes(document.activeElement.tagName.toLowerCase())) {
                return
            }

            document.body.classList.add('has-blur-lock')

            // Check again after 1 second, if the document still does not have focus, send a report
            // Also check the visibility state to prevent duplicate reports
            setTimeout(() => {
                if (document.hasFocus() || document.visibilityState === 'hidden') {
                    return
                }

                const examReportModel = new ExamReportModel({
                    activity_id: this.model.id,
                    type: 'blur',
                    info: {
                        last_page: location.pathname
                    }
                })
                examReportModel.save()
            }, 1000)
        }, 10)
    }

    // Remove the blur overlay when focus is present again
    onFocus() {
        document.body.classList.remove('has-blur-lock')
    }

    /**
     * If the student is detected pasting some text this will be reported to
     * the teacher.
     *
     * @param  {Event} e paste event
     */
    onPaste(e) {
        // IE and Edge have the clipboard stored under the window object.
        // http://stackoverflow.com/a/5552340/3091836
        var pastedText = '';
        if (window.clipboardData) {
            pastedText = window.clipboardData.getData('text');
        } else {
            pastedText = (e.originalEvent || e).clipboardData.getData('text/plain');
        }
        if (pastedText) {

            // Attempt to find task element of event. If found, submit the taskId with the exam report to the teacher
            // can see the exact location of the paste event.
            const task_id = $(e.target).parents('article').data('task-id')

            const examReportModel = new ExamReportModel({
                activity_id: this.model.id,
                type: 'paste',
                info: {
                    last_page: location.pathname,
                    pasted_text: pastedText,
                    task_id
                }
            });
            examReportModel.save();
        }
    }

    /**
     * This method will parse a new taskgroup and store it within the global
     * active item variable.
     *
     * @param   {TaskGroupModel} taskGroupModel  task group model
     */
    parseNewItemTaskgroup(taskGroupModel) {
        if (!this.isStudentExamMode || this.isDiagnosticExam()) {
            Backbone.View.header.setCrumblepath(
                taskGroupModel.getAllCrumblepathModels(),
                'show'
            );

            // Show dropdown.
            this.optionsDropdown?.$el.show()
            this.printDropdown?.$el.hide()
        }

        // Show finish exam hero button.
        this.exitExamBtn?.$el.show();

        // Show bottom pagination controls.
        this.$('.js-pagination-bar').show();

        // Hide log out button on task group pages to prevent confusion with the finised exam button.
        this.logoutButton?.$el.hide()

        // Create a new taskGroup
        this.activeItem = new TaskGroup({
            work_on: this,
            model: taskGroupModel,
        });

        // Register taskgroup as childview
        this.registerChildView(this.activeItem);

        // Set the navigation url to pass the taskgroup id. This is usefull for debuggin
        Backbone.history.navigate(

            `/activities/show/${this.model.id}/${taskGroupModel.id}`,

            // Do not trigger navigation since it is not a valid URL
            {trigger: false}

        );
    }

    /**
     * Create start/end summary page of activity.
     *
     * @param   {Integer} activeItemKey          sequence key of item in navigation order
     */
    parseSummary(activeItemKey) {

        // Hide bottom pagination controls.
        this.$('.js-pagination-bar').hide();

        // Show current task group in crumblepath only if its available.
        // (that is when the exam is visible for the student).
        // When a student is making the exam the crumblepath isn't available.
        if (!this.isStudentExamMode) {
            Backbone.View.header.setCrumblepath(
                this.model.getAllCrumblepathModels(),
                'show'
            );
        }

        const isExamStart = activeItemKey === -1
        if (isExamStart) {
            Backbone.history.navigate('/activities/show/' + this.model.id, {trigger: false})
        } else if (activeItemKey === this.model.task_groups.size()) {
            Backbone.history.navigate('/activities/show/' + this.model.id + '/end', {trigger: false})
        }

        // Hide dropdown.
        this.optionsDropdown?.$el.hide()
        this.printDropdown?.$el.toggle(isExamStart)

        // Hide finish exam hero button.
        this.exitExamBtn?.$el.hide();

        // Show log out button on exam start page
        this.logoutButton?.$el.toggle(isExamStart)

        // Set Summary is the current active item in the navigation.
        this.activeItem = new Summary({
            work_on: this,
            model: this.model,
            isExamStart,
        });
        this.registerChildView(this.activeItem);
    }

    /**
     * When ending the current exam session, ask to confirm if the student is sure it wants to
     * hand in the exam. If confirmed, replace the current active item with an end screen.
     * If user is a teacher, return to section show view of the current activity.
     */
    onClickFinishExamActivity() {
        if (ACL.checkRole(ACL.roles.STUDENT) && !this.model.get('show_answers')) {
            const unfinishedTasksText = this.getUnfinishedTasks().length ?
                '<b>' + this.getUnfinishedTasksText() + '</b><br>' :
                ''
            Backbone.View.layout.openConfirmStatus(
                unfinishedTasksText +
                window.i18n.sprintf(window.i18n.gettext(
                    'If you hand in this %s you can no longer edit your answers. Are you sure you\'re finished?'
                ), this.model.getActivityTypeLabel().toLowerCase()),
                () => { this.replaceWithEndView() },
                () => {},
                this.model.get('type') === 'diagnostic_exam' ?
                    window.i18n.gettext('Hand in diagnostic exam') :
                    window.i18n.gettext('Hand in exam')
            );
        } else {
            Backbone.history.navigate(
                '/sections/show/' + this.model.get('section_id'),
                {trigger: true}
            );
        }
    }

    /**
     * Get array of all tasks in the current activity that haven't been touched yet.
     *
     * @return {Array}  Flattend array of task models
     */
    getUnfinishedTasks() {
        return _.flatten(this.model.task_groups.map((taskGroupModel) => {
            return taskGroupModel.tasks.difference(taskGroupModel.getFinishedTasks(this.model))
        }))
    }

    /**
     * Create an appropriate title to put above the list of unanswered tasks labels.
     *
     * @return {string}  unfinished tasks title
     */
    getUnfinishedTasksText() {
        const unfinishedTaskCount = this.getUnfinishedTasks().length
        if (!unfinishedTaskCount) {
            return ''
        }

        return window.i18n.sprintf(
            window.i18n.ngettext(
                'You haven\'t answered %s task.',
                'You haven\'t answered %s tasks.',
                unfinishedTaskCount
            ),
            unfinishedTaskCount
        )
    }

    /**
     * Replace current view with end screen.
     *
     * @param  {number} activityClosedByTeacher     optional activity ID of an exam thats being
     *                                              closed by a teacher. Check if this ID is
     *                                              that of the current exam, otherwise abort
     *                                              this method.
     */
    replaceWithEndView(activityClosedByTeacher) {
        const isClosedByTeacher = activityClosedByTeacher === this.model.id

        // Remove finish exam hero button.
        this.exitExamBtn?.remove();

        // Remove the clock.
        this.clock?.remove();

        // Remove bottom pagination controls.
        this.$('.js-pagination-bar').remove();

        // Remove exam child views and exam activity event listeners.
        this.destroyChildViews();
        this.removeEvents();

        // Close fullscreen view if present
        document.removeEventListener(FullscreenMode.getFullscreenChangeEvent(), this.onFullscreenChange);
        if (FullscreenMode.checkFullscreen()) {
            FullscreenMode.exitFromFullscreen()
        }
        $('body').removeClass('has-fullscreen-lock')

        // Remove blur overlay and listeners
        window.removeEventListener('blur', this.onBlur)
        window.removeEventListener('focus', this.onFocus)
        document.body.classList.remove('has-blur-lock')

        this.addChildView(new End({
            model: this.model,
            work_on: this,
            isClosedByTeacher
        }), '.js-activity-viewport', 'html');
    }

    /**
     * If student does not have a valid exam session, redirect them to Learnbeat home.
     * Otherwise tell them that they still need to make the exam before they can leave.
     *
     * @param  {Object} response    GET call response object
     */
    exitSEBLaunchPage(response) {
        // If student does not have a valid exam session.
        if (response.err_code === 40404) {
            this.gotoHome();
        } else {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You\'re not allowed to exit this exam yet.'),
                'warning'
            );
        }
    }

    /**
     * After having finished the exam, redirect the student to Learnbeat home
     * by first removing the URL restriction, re-adding the root navigation
     * elements that where removed during the exam and removing the special
     * global work-on styling and standby prevention logic.
     */
    gotoHome() {

        // Make sure router is unlocked so user can navigate again.
        Backbone.Model.user.unset('lockToURL');

        // Initialize menubar and crumblepath.
        Backbone.View.layout.createMenubar();

        // Remove logic to prevent auto standby on the student's device.
        StandbyMode.enableAutoStandby();

        $('body').removeClass('is-work-on')

        // Navigate to home.
        Backbone.history.navigate('/users/home', {trigger: true});

    }

    /**
     * Add paste event listener to iframes that come from internal
     * sources (e.g. tinymce editor).
     */
    addPasteEventListenersToIframes() {
        Backbone.View.layout.on('editorLoaded', () => {
            const iframes = document.querySelectorAll('iframe:not([src])')

            // Use wrapper since NodeList.prototype.forEach() is not supported in IE.
            _.each(iframes, (iframe) => {
                iframe.contentWindow.addEventListener('paste', this.onPaste);
            });
        });
    }

    // When students enter a fullscreen enabled exam, have them open
    // fullscreen (if supported) and listen to the fullscreenchange event
    setupFullscreenMode() {

        // Do not enable fullscreen mode if the student is on a tablet
        // iPads do support fullscreen, but not good enough to be useable (e.g. keyboard closes fullscreen)
        // TODO: research Android
        const isTablet = Util.hasSupportForTouch() &&
            ['iOS', 'Mac OS', 'Android'].includes(window.uaparser.getOS().name)

        // If the browser supports full screen mode, make the exam visible only when
        // the student is in full screen mode.
        if (!isTablet && FullscreenMode.checkIfFullscreenIsPossible()) {

            // Add button to enter fullscreen mode
            this.fullscreenBtn = this.addChildView(new Button({
                label: window.i18n.gettext('Open fullscreen'),
                size: 'medium',
                callback: this.onClickEnterFullscreen,
            }), '.js-fullscreen-btn')

            // Add a lock when the user is not in fullscreen mode yet
            if (!FullscreenMode.checkFullscreen()) {
                $('body').addClass('has-fullscreen-lock')
            }

            // Listen to changes in the fullscreen state so that the overlay can be hidden/shown
            // and a report can be sent when leaving full screen mode
            document.addEventListener(FullscreenMode.getFullscreenChangeEvent(), this.onFullscreenChange)

            // Show a clock with current time to inform students on time left
            this.clock = Backbone.View.header.addButton(
                new Clock(), true
            )
        }
    }

    // Put the student in fullscreen mode
    onClickEnterFullscreen() {
        FullscreenMode.launchIntoFullscreen()
    }

    // Hide or show the overlay based on the fullscreen mode
    onFullscreenChange() {
        if (FullscreenMode.checkFullscreen()) {
            // The student entered fullscreen, remove the lock
            $('body').removeClass('has-fullscreen-lock')
        } else {
            // The student left fullscreen, add the lock and send a report
            $('body').addClass('has-fullscreen-lock')

            var examReportModel = new ExamReportModel({
                activity_id: this.model.id,
                type: 'fullscreenchange',
                info: {
                    last_page: location.pathname
                }
            })
            examReportModel.save()
        }
    }

    isDiagnosticExam() {
        return this.model.get('type') === 'diagnostic_exam'
    }

    // Do not allow right click actions to make copying and screenshotting more difficult
    onContextMenu(e) {
        e.preventDefault()
    }

    // Do not allow the copy action to prevent students from copying exam answers
    onCopy(e) {
        e.preventDefault()
    }

    // Overwrite clipboard contents with a space character to prevent cheating
    async clearClipboard() {

        // If the student needs to enable fullscreen, let them do that first
        // That should avoid 'permission denied' errors while transitioning to fullscreen
        if (document.body.classList.contains('has-fullscreen-lock')) {
            return
        }

        // Try this once to prevent logging lots of errors when permission is denied
        try {
            $('body').off('mousedown', this.clearClipboard)
            await navigator.clipboard.writeText(' ');
        } catch (error) {
            window.sentry.withScope(scope => {
                scope.setExtra('error', error)
                window.sentry.captureException('Could not clear clipboard during exam')
            })
        }
    }

}
