<script>
    import Reply from 'views/components/feedback/reply/Reply.svelte';
    import Button from 'views/components/button/Button.svelte';
    import Textarea from 'views/components/textarea/Textarea.svelte';
    import TimeUtil from 'util/TimeUtil';
    import Util from 'util/util';
    import EditFeedback from '../sidebar/EditFeedback.svelte';
    import { getRecipient, getSender } from 'views/pages/users/feedback/FeedbackUtil';

    export let conversation;
    export let activityModel

    const studentReplyInputs = [];
    let sendButtonDisabled = false;

    const isStudent = ACL.checkRole(ACL.roles.STUDENT)
    const isWholeGroupMessage = conversation.conversation_context.group_id !== null

    function onClickSendReplyToFeedback(message) {
        const replyValue = studentReplyInputs[message.id]?.getValue();
        if (!replyValue) {
            Backbone.View.layout.openStatus(window.i18n.gettext('A reply can not be empty'));
            return;
        }

        sendButtonDisabled = true;
        jQuery.post({
            url: '/feedback/messages/reply.json',
            data: {
                message: replyValue,
                conversation_id: message.conversation_id,
                reply_to_id: message.id,
            },
            success: (response) => {
                studentReplyInputs[message.id]?.clearValue();
                conversation = response
            },
            complete: () => {
                sendButtonDisabled = false;
            }
        });
    }

    function onClickRemoveFeedback(messageId) {
        Backbone.View.layout.openConfirmStatus(
            window.i18n.gettext('Are you sure you want to remove this feedback?'),
            () => removeFeedback(messageId),
            undefined,
            window.i18n.gettext('Remove feedback')
        );
    }

    function removeFeedback(messageId) {
        jQuery.ajax({
            url: `/feedback/messages/${messageId}.json`,
            type: 'DELETE',
            success: (response) => {

                // A deleted message can result in a deleted conversation if it is the last one. In that case,
                // remove the model and the view
                if (response.message === 'conversation_deleted') {
                    activityModel.feedback = activityModel.feedback.filter(element => element.id !== conversation.id)
                    conversation = null
                    return
                }

                // Otherwise, remove only the message from the conversation
                conversation.messages = conversation.messages.filter(message => message.id !== messageId)
            }
        });
    }

    function onClickEditFeedback(message) {
        Backbone.View.sidebar.showSidebar(
            new Backbone.View(),
            window.i18n.gettext('Send feedback'),
            630
        );

        Backbone.View.sidebar.addSvelteChildView(
            '.js-inner',
            EditFeedback,
            {
                message,
                conversation,
                callback: (updatedConversation) => {
                    conversation = updatedConversation
                }
            }
        )
    }
</script>

{#if conversation}
    <div class="feedback">
        {#each conversation.messages as message}
            <div feedback-id={message.id}>
                <div class="header">
                    <div>
                        <span>{getSender(message).name}</span>
                        {window.i18n.gettext('to')}
                        <span class="recipient">{getRecipient(conversation).name}</span>
                        <span class="timestamp">{TimeUtil.prettyDate(message.created_at)}</span>
                    </div>
                    {#if message.user_id === Backbone.Model.user.id}
                        <div class="actions">
                            <Button
                                label={window.i18n.gettext('Edit')}
                                theme="transparent"
                                icon="pencil"
                                callback={() => onClickEditFeedback(message)}></Button>
                            <Button
                                label={window.i18n.gettext('Remove')}
                                theme="transparent"
                                icon="trash"
                                callback={() => onClickRemoveFeedback(message.id)}></Button>
                        </div>
                    {/if}
                </div>
                <div class="message">
                    {@html Util.renderContentSafely(message.message)}
                </div>
            </div>

            {#if message.replies}
                <div class="js-replies-holder">
                    {#each message.replies as reply (reply.id)}
                        <Reply {reply}></Reply>
                    {/each}
                </div>
            {/if}

            {#if isStudent && !isWholeGroupMessage}
                <div class="student-reply-input">
                    <Textarea bind:this={studentReplyInputs[message.id]} placeholder={window.i18n.gettext('Reaction')} minLines={1}></Textarea>
                    <Button
                        label={window.i18n.gettext('Send')}
                        callback={() => onClickSendReplyToFeedback(message)}
                        inline={true}
                        disabled={sendButtonDisabled}>
                    </Button>
                </div>
            {/if}
        {/each}
    </div>
{/if}

<style lang="scss">
    .feedback {
        @include large-text;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            color: $blue-black-50;

            .recipient {
                color: $blue-black;
                font-weight: 500;
            }

            .actions {
                display: flex;
                gap: 8px;
            }
        }

        .message {
            background: $background-gray;
            border-radius: 12px;
            padding: 12px 16px;
            display: inline-block;
            margin-bottom: $spacing-8;

            &:has(p) {
                padding: 0 16px;
            }
        }
    }

    .timestamp {
        padding-left: 4px;
    }

    .student-reply-input {
        display: flex;
        flex-direction: column;
        gap: 8px;

        :global(button) {
            align-self: flex-end;
        }
    }
</style>
