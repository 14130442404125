// TODO: Make compatible with GoogleDriveSDK and remove those in favor of this one.

/**
 * Create two classes for wrapping external resources that shouldn't
 * be initialized more than once. This classes can be used to load
 * external libraries, for example: OneDrive, Office365 and
 * Azure player.
 *
 * The LoadExternalStaticResource class will act like some sort of
 * singleton to keep track of initialized files, of the ExternalResource
 * type class, so it can enforce there will only be one instance
 * loaded of an external library.
 *
 * @class ExternalResource
 * @class LoadExternalStaticResources
 */

class ExternalResource {

    /**
     * Creates an instance of ExternalResource.
     *
     * @param {Object} {
     *  identifier {String} containing an unique identifier for this resource
     *  url        {String} containing the url to load
     *  type       {String} containing the type of the resource
     * }
     * @param {Function} callback callback to call when resource is loaded
     * @memberof ExternalResource
     */
    constructor({ identifier, url, type }, callback) {

        // Create an immutable callback list to always keep it an instance of set
        Object.defineProperty(this, 'callbackList', {
            value: new Set()
        });

        Object.assign(this, {
            url,
            type,
            identifier,
            initialized: false,
            mountElement: document.body
        });

        // Do this outside of the assign, else the props won't be available
        this.element = this._createElement();
        this.addCallback(callback);
    }

    /**
     * Method to add an additional callback for when this resource is loaded.
     * It will deduplicate the callbacks so every callback is only called once
     *
     * @param {Function} callback callback to call on resource load
     * @memberof ExternalResource
     */
    addCallback(callback) {
        this.callbackList.add(callback);
    }

    /**
     * Method to mount the resource to, this will probably be
     * document.body but, for example, for CSS it needs to be head.
     *
     * @param {Node} element element to append the resource to
     * @memberof ExternalResource
     */
    mount(element) {
        // Keep track of the mount element, we need to know this
        // for correctly destroying and recreation of a resource
        this.mountElement = element;
        element.append(this.element);
    }

    /**
     * Method to recreat this resource, it will remove the element
     * and reinintialize it. For example, this is needed for the
     * OneDrive SDK because Microsoft destroys their SDK when
     * something fails. In that case we need to be able to reload
     * the resource.
     *
     * @memberof ExternalResource
     */
    recreate() {
        this._removeElement();
        delete this.element;
        this.initialized = false;
        this.element = this._createElement();
        this.mount(this.mountElement);
    }

    /**
     * Method to destroy this resource
     *
     * @memberof ExternalResource
     */
    destroy() {
        this._removeElement();
    }

    /**
     * Method to remove the element from the DOM
     *
     * @memberof ExternalResource
     */
    _removeElement() {

        if (
            this.element
            // When the DOM was overwritten by Backbone, the parentNode
            // will get lost. So to fix this and allow us to remove the
            // node reattach it to the document body
            && this.element.parentNode === null
        ) {
            // Disable the onload since we're only adding the element
            // to the DOM to destroy it. If we would call onload it
            // would maybe create unwanted behaviour
            this.element.onload = null;
            this.mountElement.append(this.element);
        }

        this.mountElement.removeChild(this.element);
    }

    /**
     * Method to create the static resource element
     *
     * @returns {DOMNode} of the created resource
     * @memberof ExternalResource
     */
    _createElement() {
        let resourceElement;

        switch (this.type) {
            case 'javascript':
                resourceElement = document.createElement('script');
                resourceElement.type = 'text/javascript';
                resourceElement.src = this.url;
                break;
            case 'css':
                resourceElement = document.createElement('link');
                resourceElement.href = this.url;
                resourceElement.setAttribute('rel', 'stylesheet');
                break;
        }

        resourceElement.onload = () => {
            if (this.initialized === false) {
                this.callbackList.forEach(callback => callback());
                this.initialized = true;
            }
        };

        return resourceElement;
    }
}
class LoadExternalStaticResource {

    /**
     * Allow getting an instance via a static method, this
     * will be the main exposed method and should be used
     * when loading new external files.
     *
    * @static
     * @returns {LoadExternalStaticResource} instance of this class
     * @memberof LoadExternalStaticResource
     */
    static getInstance() {
        if (!LoadExternalStaticResource.instance) {
            return new LoadExternalStaticResource();
        }

        return LoadExternalStaticResource.instance;
    }

    /**
     * Make getting instance more easy from within the
     * class. For using this class in other files, getInstance
     * should be used.
     *
     * @readonly
     * @memberof LoadExternalStaticResource
     */
    get instance() {
        return LoadExternalStaticResource.instance;
    }

    /**
     * Create a singleton like constructor which
     * will only initialize once and after that
     * return the created instance.
     *
     * @memberof LoadExternalStaticResource
     */
    constructor() {
        if (!this.instance) {

            this.resources = {};

            // Use the define property to make the instance property
            // immutable by default.
            Object.defineProperty(
                LoadExternalStaticResource,
                'instance', {
                    value: this
                }
            );

            // Make the whole class and the single initialized instance
            // immutable to make sure it won't override it with a new
            // version of this class. This will ensure that there will
            // only be a single source of truth
            Object.freeze(LoadExternalStaticResource);
            Object.freeze(this);
        }

        return LoadExternalStaticResource.getInstance();
    }

    /**
     * Method for adding an external resource. It will append the
     * correct tag based on the type to the DOM and keep track
     * whether it is already initialized or not using the identifier
     * as unique key.
     *
     * It supports the following types:
     * - * javascript   -> <script>-tag
     * - * css          -> <link>-tag
     *
     * @param {Object} {
     *  identifier {String} containing an unqiue id to keep of this specific resource
     *  url {string} containing the url where to find the resource
     *  type {string} containing the type of resource, to initialized the correct tag
     * }
     * @param {Function} callback function to call after the resource is initialized
     * @returns {Object} Configuration of the initialized resource
     * @memberof LoadExternalStaticResource
     */
    addResource({ identifier, url, type }, callback) {

        const resourceRoot = (type) => type === 'css'
            ? document.head
            : document.body;

        if (!this.instance.resources[identifier]) {
            this.instance.resources[identifier] = new ExternalResource({
                identifier,
                url,
                type
            }, callback);
        }

        const externalResource = this.instance.resources[identifier];
        externalResource.addCallback(callback);

        if (!externalResource.initialized) {
            externalResource.mount(resourceRoot(type));
        } else {
            callback();
        }

        return externalResource;
    }

    /**
     * Publically exposed method to destroy the resource. It will
     * get the correct ExternalResource and call its destroy method
     * to destroy it completely
     *
     * @param {String} identifier to get the correct ExternalResource
     * @returns {boolean | undefined} use return for early exit
     * @memberof LoadExternalStaticResource
     */
    destroy(identifier) {
        if (!identifier) {
            console.warn('Called destroy on LoadExternalStaticResource without identifier');
            return false;
        }

        if (!this.instance.resources[identifier]) {
            console.log('Destroying non existing resource');
            return true;
        }

        const externalResource = this.instance.resources[identifier];
        externalResource.destroy();
        delete this.instance.resources[identifier];
    }

    /**
     * Publically exposed method for reloading a resource. It will
     * get the correct ExternalResource and call its recreate method
     * to reload the external script.
     *
     * @param {String} identifier to get the correct ExternalResource
     * @returns {boolean | undefined} use return for early exit
     * @memberof LoadExternalStaticResource
     */
    reload(identifier) {
        if (!identifier) {
            console.warn('Called reload on LoadExternalStaticResource without identifier');
            return false;
        }

        if (!this.instance.resources[identifier]) {
            console.log('Reloading non existing resource');
            return true;
        }

        const externalResource = this.instance.resources[identifier];
        externalResource.recreate();
    }
}

// Expose only the getInstance method
export default LoadExternalStaticResource.getInstance;
