import Template from './Template32.hbs';
import WordSelection from 'views/components/taskGroups/tasks/wordSelection/WordSelection';
import FragmentTemplate from 'views/components/taskGroups/tasks/wordSelection/Fragment.hbs';
import GapTemplate from 'views/components/taskGroups/tasks/wordSelection/Gap.hbs';
import WordSelectionStyles from 'views/components/taskGroups/tasks/wordSelection/WordSelection.scss';
import ShapeList from 'util/ShapeLoader';
import Util from 'util/util';

export default WordSelection.extend({

    events: {
        'click .js-text .js-gap': 'resetChoice'
    },

    initialize(options) {

        _.bindAll(
            this,
            'lockAnswer',
            'onDrop',
            'onStartAnswerMove',
            'renderTask'
        );

        this.taskView = options.task_view;

        this.showingAnswers = false;

        this.prepareData();

        // The entire text with gaps. Structured like this:
        // {
        //     "text": [
        //         "De vernieuwingen in de landbouw waren",
        //         {
        //             "id": 1,
        //             "answers": [
        //                 {
        //                     "text": "Oorzaak"
        //                 }
        //             ]
        //         },
        //         "van de hogere landbouwopbrengsten."
        //     ],
        //     "choices": [
        //          {
        //              "text": "Afleider"
        //          },
        //          {
        //              "text": "Oorzaak"
        //          }
        //     ],
        //     "options": {"partialGrading": true}
        // }
        //
        // The answers array for each gap is not be present if the answers are hidden for the user.

        this.fragments = this.model.get('task_info_json').text;

        // Array of both possible answers and distractors in random order.
        // The choices attribute is not defined in the author, so use the distractors attribute instead.
        this.choices = this.model.get('task_info_json').choices || this.model.get('task_info_json').distractors;

        // Remove choices that are already present in the user's answer.
        var choiceAnswerFilterArray = _.pluck(this.JSONAnswer, 'answer');
        this.choices = _.reject(this.choices, (object) => {
            // Find if choice already exist in given answer. If found, remove this answer from the
            // list to prevent it from being rejected more than once. This ensures choices with
            // duplicate content are not removed.
            var index = _.findIndex(choiceAnswerFilterArray, {
                text: object.text
            });
            if (index !== -1) {
                choiceAnswerFilterArray.splice(index, 1);
                return true;
            }
        });

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles: WordSelectionStyles
        }));

        // Element containing the text with gaps.
        this.taskTextElement = this.$('.js-text');

        // Element containing the choices that haven't been placed in a gap yet.
        this.choicesElement = this.$('.js-choices');

        this.renderTask()
    },

    /**
     * renderTask
     *
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     *
     */
    renderTask() {

        // Remove existing DOM so it can be replaced.
        this.taskTextElement.empty();

        // Reduce gap templates and plain text into a single string and append
        // it to the DOM. This makes it possible for the browser to correct
        // with any invalid HTML automatically without having to normalize
        // and parse each individual fragment, which can result in errors.
        this.taskTextElement.append(_.reduce(this.fragments, (content, fragment) => {
            if (fragment instanceof Object) {
                return content + GapTemplate({
                    fragmentID: fragment.id,
                    Styles: WordSelectionStyles
                });
            }
            return content + this.insertPlainText(fragment);
        }, ''))

        _.each(this.fragments, (fragment) => {
            if (fragment instanceof Object) {

                // Get template we just added by searching for its data-gap-id.
                var gapElement = this.$('.js-gap[data-gap-id=' + fragment.id + ']');

                if (APPLICATION === 'author') {

                    gapElement.append(FragmentTemplate({
                        Styles: WordSelectionStyles,
                        fragment: fragment.answers[0].text,
                        fragmentStatus: WordSelectionStyles['fragment--selected']
                    }));

                } else {

                    // Get user answer for the current gap.
                    var givenAnswer = _.findWhere(this.JSONAnswer, {
                        id: fragment.id
                    });

                    // Assume that the given answer is wrong untill proven otherwise.
                    // This is only relevant when this.showingAnswers == true.
                    var isCorrectAnswer = false;
                    if (givenAnswer && this.showingAnswers) {
                        // Check if givenAnswer text is equal to the correct answer.
                        isCorrectAnswer = fragment.answers[0].text === givenAnswer.answer.text;
                    }

                    // If an answer is given use the fragment--selected style. otherwise use
                    // the fragment--droppable style to indicate that this is a drop target.
                    var fragmentStatus = givenAnswer ?
                        WordSelectionStyles['fragment--selected'] :
                        WordSelectionStyles['fragment--droppable'];
                    // If answers are shown apply styles to indicate the given answer being
                    // correct or incorrect.
                    if (this.showingAnswers) {
                        fragmentStatus = isCorrectAnswer ?
                            WordSelectionStyles['fragment--correct'] :
                            WordSelectionStyles['fragment--incorrect'];
                    }

                    // We use &nbsp; for empty fragments so that the fragment does not collapse (it has some content)
                    gapElement.append(FragmentTemplate({
                        Styles: WordSelectionStyles,
                        fragment: givenAnswer ? givenAnswer.answer.text : '&nbsp;',
                        fragmentStatus,
                        showAnswerTooltip: this.showingAnswers && !isCorrectAnswer,
                        tooltipText: this.showingAnswers ? fragment.answers[0].text : ''
                    }));

                    // Make the gap at least as wide as the tooltip, to prevent
                    // the tooltip from falling outside of the viewport
                    const tooltipWidth = gapElement.find('.js-answer-tooltip').width()
                    // Only apply when tool tip is wider than 100px (the min width of a fragment).
                    if (tooltipWidth > 100) {
                        gapElement.find('.js-fragment').css('min-width', tooltipWidth)
                    }

                }
            }
        })

        // If correct answers aren't shown, initialize a droppable for each
        // element with the class js-empty-gap.
        if (!this.showingAnswers) {
            this.taskTextElement.find('.js-gap').droppable({
                tolerance: 'pointer',
                accept: '.js-fragment',
                drop: this.onDrop,
            });
        }

        // Remove any existing DOM so it can be replaced.
        this.choicesElement.empty();

        // Create draggable fragment element for each choice that hasn't been
        // placed in any gap yet.
        this.choicesElement.append(_.reduce(this.choices, (content, choice) => {
            return content + FragmentTemplate({
                Styles: WordSelectionStyles,
                fragment: choice.text,
                fragmentStatus: WordSelectionStyles['fragment--draggable'],
                showDragHandle: true
            });
        }, ''))

        if (!this.showingAnswers) {
            this.choicesElement.find('.js-fragment').draggable({
                revert: true,
                cursorAt: {
                    top: 18,
                    left: 20
                },
                cursor: 'move',
                containment: this.$el,
                scrollSensitivity: 60,
                scrollSpeed: 40
            });
        }

        var givenAnswerFragments = this.taskTextElement.find('.js-fragment');

        _.each(givenAnswerFragments, (givenAnswerFragment) => {

            // Check if the fragment is not empty, which means it is a valid answer option
            if ($(givenAnswerFragment).text().trim() !== '') {

                // If not, make it draggable
                $(givenAnswerFragment).draggable({
                    cursorAt: {
                        top: 18,
                        left: 20
                    },
                    cursor: 'move',
                    containment: this.$el,
                    start: this.onStartAnswerMove,
                    revert: this.renderTask,
                    scrollSensitivity: 60,
                    scrollSpeed: 40
                });
            }
        })

    },

    /**
     * onDrop
     *
     * When an element with the .js-fragment class is dropped
     * on a .js-gap element, update the data by removing the
     * relevant choice from this.choices and adding it to
     * this.JSONAnswer.
     *
     * @param  {jQuery.event} e  the droppable element drop event
     * @param  {jQuery.event} ui draggable element
     */
    onDrop(e, ui) {

        this.stopAllEvents(e);

        // Get dragged element trimmed text and look it up in the choices array.
        // Also trim these since the data for gap texts converted from the old
        // format are not always properly trimmed. See issue LB6712.
        var dragElementText = ui.draggable.find('.js-fragment-key').html().trim();

        const existingChoice = this.choices.find(
            choice => choice.text.trim() === Util.unescape(dragElementText)
        )

        if (existingChoice) {

            // Deep clone this.JSONAnswer to sever the reference so ensure the old answer
            // data is different from the new data.
            // This is needed because _.clone() will see the this.JSONAnswer Array also as an object.
            // Due to shallow cloning, the objects inside will therefore not be cloned.
            // jscs:disable maximumLineLength
            // developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Deep_Clone
            this.JSONAnswer = JSON.parse(JSON.stringify(this.JSONAnswer));

            // If there is already an answer for the given gap ID replace the
            // answer at this ID. Otherwise append data for the gap at the end
            // of this.JSONAnswer.
            var gapID = parseInt(e.target.dataset.gapId);
            var existingAnswerIndex = _.findIndex(this.JSONAnswer, {
                id: gapID
            });
            if (existingAnswerIndex >= 0) {
                this.choices.push(this.JSONAnswer[existingAnswerIndex].answer);
                this.JSONAnswer[existingAnswerIndex].answer = existingChoice;
            } else {
                this.JSONAnswer.push({
                    id: gapID,
                    answer: existingChoice
                });
            }

            // Now the answer has been saved in this.JSONAnswer remove it from this.choices.
            this.choices = _.without(this.choices, existingChoice);

            // Render the DOM again to reflect the changes in the data.
            this.renderTask();

            // Save updated answer to server.
            this.saveAnswer();
        }

    },

    /**
     * resetChoice
     *
     * When clicking on a gap that is filled in, remove that choice from said gap by first
     *  updating the data and then re-rendering the DOM to reflect this change.
     *
     * @param  {jQuery.event} e click event
     */
    resetChoice(e) {
        this.stopAllEvents(e);
        var gapID = parseInt(e.currentTarget.dataset.gapId);
        var existingAnswer = _.findWhere(this.JSONAnswer, {
            id: gapID
        });
        if (existingAnswer && !this.showingAnswers) {
            this.choices.push(existingAnswer.answer);
            this.JSONAnswer = _.without(this.JSONAnswer, existingAnswer);

            this.renderTask();
            this.saveAnswer();
        }

    },

    /**
     * onStartAnswerMove
     *
     * When dragging an already placed fragment starts, remove this fragment from
     * gap it gets pulled from.
     *
     * @param  {jQuery.event} e     jQuery UI dragstart event
     * @param  {jQuery.event} ui draggable element
     */
    onStartAnswerMove(e, ui) {
        var gapID = parseInt(e.currentTarget.parentElement.dataset.gapId);
        if (gapID) {
            var existingAnswer = _.findWhere(this.JSONAnswer, {
                id: gapID
            });

            // Check if the existing answer was found, and is not just a placeholder
            if (existingAnswer) {
                this.choices.push(existingAnswer.answer);
                this.JSONAnswer = _.without(this.JSONAnswer, existingAnswer);

                this.saveAnswer();
            }
        }
        ui.helper.css('z-index', 1);
    },

    /**
     * showAnswer
     *
     * Show the correct answer to the task.
     */
    showAnswer() {
        if (APPLICATION !== 'author') {
            this.showingAnswers = true;
            this.renderTask();
        }
    },

    showAuthorAnswer() { },

    /**
     * hideAnswer
     *
     * Hide the correct answer to the task.
     */
    hideAnswer() {
        this.showingAnswers = false;
        this.renderTask();

        // Because we completely rerender the task, we also need to relock it (if needed)
        if (this.isLocked) {
            this.lockAnswer()
        }
    },

    /**
     * lockAnswer
     *
     * Locks functionality of template visually
     */
    lockAnswer() {
        this.isLocked = true
        this.$('.js-fragment .js-handle').html(ShapeList.lock);
        this.$('.js-fragment').addClass(WordSelectionStyles['fragment--locked']);
        this.undelegateEvents();
        this.$el.find('.ui-draggable').draggable('disable')
    },

    /**
     * unlockAnswer
     *
     * Enables functionality of template visually
     */
    unlockAnswer() {
        this.isLocked = false
        this.$('.js-fragment .js-handle').html(ShapeList.move);
        this.$('.js-choices .js-fragment').removeClass(WordSelectionStyles['fragment--locked']);
        this.delegateEvents();
        this.$el.find('.ui-draggable').draggable('enable')
    }

});
