import Styles from './PresentationMode.scss';

import Template from './PresentationMode.hbs';
import Button from 'views/components/button/Button'
import Source from 'views/components/taskGroups/sources/Source'
import SideNavigation from 'views/pages/activities/show/types/linear/PresentationMode/SideNavigation/SideNavigation'
import FullscreenMode from 'util/FullscreenMode'
import SendStudentSidebar from 'views/pages/activities/show/types/presentation/sidebar/SendStudentSidebar'
import Util from 'util/util'
import Task from 'views/components/taskGroups/tasks/Task'
import OptionGroup from 'views/components/optionGroup/OptionGroup'
import PresentationSource from './presentationSource/PresentationSource'

export default class PresentationMode extends BaseView {

    initialize({
        work_on,
        initialTaskGroup,
    }) {

        // Present mode does not use a proper fullscreen modal since other fullscreen modals needs to be able
        // to be opened within the present mode, such as the one of Template16. It also allows the sidebar for
        // sending students to the activity to go over instead of under this view. And it removes any distracting
        // borders from the user's view.
        $(document).off('keyup', work_on.onNavigationKeyUp)
        Backbone.View.sidebar.closeSidebar()

        _.bindAll(
            this,
            'setActiveElement',
            'onNavigationKeyUp',
        )

        this.work_on = work_on

        // If present mode is started from the activity start screen, set activity's first task group as the start.
        if (initialTaskGroup === this.model) {
            initialTaskGroup = this.model.task_groups.first()
        }

        this.setElement(Template({
            Styles,
        }))

        this.addChildView(new Button({
            icon: 'cross',
            label: window.i18n.gettext('Close'),
            theme: 'secondary',
            callback: () => {
                this.destroy()
            },
        }), '.js-bottom-nav-left')
        this.addChildView(new Button({
            icon: 'fullscreen',
            label: window.i18n.gettext('Full screen'),
            theme: 'secondary',
            callback() {
                if (FullscreenMode.checkFullscreen()) {
                    FullscreenMode.exitFromFullscreen()
                    this.icon = 'fullscreen'
                } else {
                    FullscreenMode.launchIntoFullscreen()
                    this.icon = 'fullscreen-exit'
                    this.label = window.i18n.gettext('Close full screen')
                }
            },
        }), '.js-bottom-nav-left')
        this.addChildView(new Button({
            icon: 'zoom-out',
            title: window.i18n.gettext('Zoom out'),
            theme: 'secondary',
            callback: () => {
                this.setScaleFactor(-0.2)
            },
        }), '.js-bottom-nav-left')
        this.addChildView(new Button({
            icon: 'zoom-in',
            title: window.i18n.gettext('Zoom in'),
            theme: 'secondary',
            callback: () => {
                this.setScaleFactor(0.2)
            },
        }), '.js-bottom-nav-left')
        this.addChildView(new Button({
            label: window.i18n.gettext('Send students to activity'),
            icon: 'present',
            theme: 'secondary',
            callback: () => {
                if (this.sendStudentSidebar) {
                    this.sendStudentSidebar.destroy()
                }
                this.sendStudentSidebar = new SendStudentSidebar({
                    model: this.model,
                    buttonLabel: window.i18n.gettext('Send students to activity'),
                    callback: (selection) => {
                        Backbone.View.layout.openStatus(
                            window.i18n.sprintf(window.i18n.ngettext(
                                '%s student has been sent to this activity',
                                '%s students have been sent to this activity',
                                selection.length
                            ), selection.length),
                            'info'
                        )
                    }
                })
                this.registerChildView(this.sendStudentSidebar)
                Backbone.View.sidebar.showSidebar(
                    this.sendStudentSidebar,
                    window.i18n.gettext('Send students to activity'),
                    360,
                )
            }
        }), '.js-bottom-nav-left')

        this.sideNavigation = this.addChildView(
            new SideNavigation({
                collection: this.model.task_groups,
                presentationMode: this
            }),
            '.js-side-navigation'
        )

        this.setActiveElement(initialTaskGroup)

        $(document).on('keyup', this.onNavigationKeyUp)

    }

    setupSideElementInput(taskGroupModel, elementModel) {
        if (this.sideViewInput) {
            this.sideViewInput.destroy()
        }
        if (elementModel.get('element_type') === 'task') {
            const choices = [
                {
                    label: window.i18n.gettext('Task only'),
                    icon: elementModel.getElementIcon(),
                    type: 'closeSideView',
                },
                {
                    label: window.i18n.gettext('Answers by students'),
                    icon: 'people',
                    type: 'studentAnswers',
                },
            ]
            if (_.size(taskGroupModel.learningTexts) || taskGroupModel.elements.some({element_type: 'source'})) {
                choices.push({
                    label: window.i18n.gettext('Source'),
                    icon: 'learning-text',
                    type: 'openSources',
                })
            }
            this.sideViewInput = this.addChildView(new OptionGroup({
                choices,
                callback: ({type}) => {
                    switch (type) {
                        case 'closeSideView':
                            this.closeSideElement()
                            break
                        case 'studentAnswers':
                            this.showStudentAnswer(this.activeElementView.openCheckAnswer())
                            break
                        case 'openSources':
                            this.setSideSource(elementModel)
                            break
                    }
                },
            }), '.js-bottom-nav-right')
        }
    }

    setActiveElement(taskGroupModel, elementModel = taskGroupModel.elements.first()) {
        if (this.activeElementView) {
            if (this.activeElementView.model === elementModel) {
                return
            }
            this.activeElementView.destroy()
        }

        // Add task group introduction, task group index and buttons for sources contained within this task group.
        if (taskGroupModel !== this.activeTaskGroupModel) {
            this.el.querySelector('.js-task-group-introduction').innerHTML = Util.renderContentSafely(taskGroupModel.get('introduction'))
            this.el.querySelector('.js-task-group-index').innerHTML = taskGroupModel.get('index')
        }

        this.activeTaskGroupModel = taskGroupModel

        if (!elementModel) {
            return
        }
        if (elementModel.get('element_type') === 'task') {
            this.activeElementView = new Task({
                model: elementModel,
                activityModel: this.model,
                work_on: this.work_on,
                isInPresentMode: true,
            })
        } else if (elementModel.get('element_type') === 'source'){
            this.activeElementView = new Source({
                model: elementModel,
                activityModel: this.model,
                work_on: this.work_on,
            })
        }

        this.setupSideElementInput(taskGroupModel, elementModel)

        this.closeSideElement()
        this.addChildView(this.activeElementView, '.js-active-element')
        this.sideNavigation.showActiveElement(elementModel)
    }

    setSideSource(elementModel) {
        if (this.sideElementView instanceof PresentationSource) {
            return
        }
        this.closeSideElement()
        this.sideElementView = this.addChildView(new PresentationSource({
            model: this.activeTaskGroupModel,
            initialTaskModel: elementModel,
        }), '.js-side-element')
    }

    showStudentAnswer(studentAnswersView) {
        if (this.sideElementView instanceof studentAnswersView.constructor) {
            this.closeSideElement()
            return
        }
        this.closeSideElement()
        this.sideElementView = studentAnswersView
        this.$('.js-side-element').append(studentAnswersView.el)
    }

    closeSideElement() {
        if (this.sideElementView) {
            this.sideElementView.destroy()
            delete this.sideElementView
        }
    }

    setScaleFactor(increment) {
        const currentScaleFactor = parseFloat(getComputedStyle(this.el).getPropertyValue('--scale-factor'))
        this.el.style.setProperty('--scale-factor', Math.min(Math.max(1, currentScaleFactor + increment), 4))
        window.sentry.withScope(scope => {
            scope.setExtra('screen resolution', `${window.screen.width}x${window.screen.width}`)
            scope.setExtra('devicePixelRatio', window.devicePixelRatio)
            scope.setExtra('zoom direction', increment < 0 ? 'negative' : 'positive')
            scope.setExtra('scale factor', currentScaleFactor)
            window.sentry.captureMessage('User changed scale factor presentation mode')
        })
    }

    onNavigationKeyUp(e) {
        if (Util.shouldPreventDocumentLevelKeyEvent(e, true)) {
            return
        }

        if (e.keyCode === 37 || e.key === 'ArrowLeft') {
            const previousItemIndex = this.model.elements.indexOf(this.activeElementView?.model) - 1
            const previousItem = this.model.elements.at(previousItemIndex)
            if (previousItemIndex > -1 && previousItem) {
                this.setActiveElement(
                    this.model.task_groups.get(previousItem.get('task_group_id')),
                    previousItem
                )
            }
        } else if (e.keyCode === 39 || e.key === 'ArrowRight') {
            const nextItem = this.model.elements.at(this.model.elements.indexOf(this.activeElementView?.model) + 1)
            if (nextItem) {
                this.setActiveElement(
                    this.model.task_groups.get(nextItem.get('task_group_id')),
                    nextItem
                )
            }
        }
    }

    onDestroy() {
        FullscreenMode.exitFromFullscreen()
        $(document).off('keyup', this.onNavigationKeyUp)
        $(document).on('keyup', this.work_on.onNavigationKeyUp)
        if (this.activeTaskGroupModel) {
            this.work_on.navigationBar.setActiveItem(this.activeTaskGroupModel.get('sequence'))
        }
    }

}
