import Styles from './Template28.scss';

import Template from './Template28.hbs';
import Util from 'util/util';
import Category from 'views/components/taskGroups/tasks/template28/category/Category';
import Item from 'views/components/taskGroups/tasks/template28/item/Item';
import AnswerItem from 'views/components/taskGroups/tasks/template28/Template28Answer.hbs';

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            'onDragStart',
            'onDragStop',
            'createDraggableItem',
            'lockAnswer',
            'unlockAnswer'
        );

        this.taskView = options.task_view;

        // Collection with the answer of the current user.
        this.JSONAnswer = new Backbone.Collection(this.taskView.response.get('json_answer'));
        // Collection with each category. If answers are visible, each category also has a collection with the
        // items to belong to said category.
        this.categoriesCollection = new Backbone.Collection(this.model.get('task_info_json').categories);
        // Randomly orderd list of all possible items that can be part of a category.
        this.itemsCollection = new Backbone.Collection(_.shuffle(this.model.get('task_info_json').items));

        this.categoriesCollection.each((model) => {

            // Convert items array to Backbone Collection.
            model.set({
                items: new Backbone.Collection(model.get('items'))
            });

            // Check if the category exists in the answer of the user.
            // If not, clone the category model and replace the items collection with an empty one and add it the
            // answer collection. This makes it possible to add categories to this category. Without doing this
            // it wouldn't be possible submit an answer initially.
            // If true, convert items array to a Backbone Collection.
            var categoryAnswer = this.JSONAnswer.get(model.get('id'));
            if (categoryAnswer === undefined) {
                categoryAnswer = model.clone();

                // Leave model answer intact when in author view
                if (APPLICATION !== 'author') {
                    categoryAnswer.set({
                        items: new Backbone.Collection()
                    });
                }

                this.JSONAnswer.add(categoryAnswer);
            } else {
                categoryAnswer.set({
                    items: new Backbone.Collection(categoryAnswer.get('items'))
                });
            }

        })

        this.renderTask()
    },

    /**
         * renderTask
         *
         * This method will render the template of the task. It will be
         * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
         * if only the answer view is necessary
         *
         */
    renderTask() {
        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        // Element for the list of categories.
        this.categoriesElement = this.$('.js-categories');

        this.categoriesCollection.each((model) => {

            // Check if the category exists in the answer of the user.
            // If not, clone the category model and replace the items collection with an empty one and add it the
            // answer collection. This makes it possible to add categories to this category. Without doing this
            // it wouldn't be possible submit an answer initially.
            // If true, convert items array to a Backbone Collection.
            var categoryAnswer = this.JSONAnswer.get(model.get('id'));

            // Create category view.
            this.addChildView(new Category({
                // Category model.
                model,
                // Answer (items that are already in the category).
                categoryAnswer,
                // All items that can be possibly added to this category.
                itemsCollection: this.itemsCollection
            }), this.categoriesElement)

        })

        // Filter response data for categories or items that don't exist (anymore).
        this.JSONAnswer.each((categoryAnswer) => {
            if (!this.categoriesCollection.get(categoryAnswer.id)) {
                this.unregisterAndDestroyChildView(categoryAnswer);
            } else {
                categoryAnswer.get('items').each((categoryAnswerItem) => {
                    categoryAnswer.get('items').remove(this.itemsCollection.where({
                        id: categoryAnswerItem.id
                    }))
                })
            }
        })

        // Element for the list of items.
        this.itemsListElement = this.$('.js-items-column');

        // Create an item view for each item model and append it to the item list element.
        this.itemsCollection.each(this.createDraggableItem);

        // When draggable item gets removed from a category, update the view to reflect this change in the DOM.
        this.listenTo(this.itemsCollection, 'add', this.onResetItem);
        // Whenever an item gets removed or added from a category, save the answer to the server.
        if (Backbone.Model.user.get('is_student')) {
            this.listenTo(this.itemsCollection, 'update', this.saveAnswer);
        }

        const columnElement = this.el.querySelector('.js-items-column')

        if ('ResizeObserver' in window) {

            const resizeObserver = new ResizeObserver(entries => {
                requestAnimationFrame(() => {
                    for (const entry of entries) {
                        const stickyStyle = Styles.column__sticky
                        columnElement.classList.toggle(stickyStyle, entry.contentRect.height < window.innerHeight)
                    }
                })
            })

            resizeObserver.observe(columnElement)

        }

    },

    /**
         * onDragStart
         *
         * At dragging start, disable visibility of the original element.
         *
         * @param  {jQuery.event} e  jQuery event data.
         * @param  {Object} ui       jQuery UI data for the object being dragged.
         */
    onDragStart(e, ui) {
        var dragItem = $(ui.helper);
        // Find the original element by searching the items list element for a element with the same data attribute
        // as the element that is being dragged and make it invisible.
        this.itemsListElement.find('.js-item[data-id="' + dragItem.data('id') + '"]').css({
            visibility: 'hidden'
        });
    },

    /**
         * onDragStop
         *
         * At dragging has ended, enable visibility of the original element.
         *
         * @param  {jQuery.event} e  jQuery event data.
         * @param  {Object} ui       jQuery UI data for the object being dragged.
         */
    onDragStop(e, ui) {
        var dragItem = $(ui.helper);
        // TODO fix origin point after scroll.
        // Find the original element by searching the items list element for a element with the same data attribute
        // as the element that is being dragged and make it visible.
        this.itemsListElement.find('.js-item[data-id="' + dragItem.data('id') + '"]').css({
            visibility: 'visible'
        });
    },

    /**
         * onResetItem
         *
         * When removing an item from a category, unhide this item in the item list. If this item doesn't exist due
         * to it not being present when the page was loaded, create a new item view.
         *
         * @param  {Backbone.Model} model item data
         */
    onResetItem(model) {
        var itemElement = this.itemsListElement.find('.js-item[data-id="' + model.id + '"]');
        if (itemElement.length === 1) {
            itemElement.show();
        } else {
            this.createDraggableItem(model);
        }
    },

    /**
         * createDraggableItem
         *
         * Creates a draggable item view using the item data model.
         *
         * @param  {Backbone.Model} model item data
         */
    createDraggableItem(model) {
        var item = this.addChildView(new Item({
            model
        }), this.itemsListElement);

        item.$el.draggable({
            revert: 'invalid',
            cursor: 'move',
            containment: this.$el,
            appendTo: this.$('.js-template-28-draggable'),
            helper: 'clone',
            cursorAt: {
                top: 23,
                left: 20
            },
            start: this.onDragStart,
            stop: this.onDragStop,
            scrollSensitivity: 60,
            scrollSpeed: 40
        });

    },

    /**
         * getCorrectCategory
         *
         * Find the category the item belongs to, if any.
         *
         * @param  {Backbone.Model} model item model
         * @return {Backbone.Model}       category model
         */
    getCorrectCategory(model) {
        return this.categoriesCollection.find((category) => {
            return category.get('items').get(model.id);
        });
    },

    /**
         * saveAnswer
         *
         * Convert this.JSONAnswer to a simple object and pass it to the saveResponse method of the parent taskView
         * to patch the answer to the server.
         */
    saveAnswer() {
        var savedAnswer = this.JSONAnswer.toJSON();
        savedAnswer.forEach((category) => {
            if (category.items) {
                category.items = category.items.toJSON();
            }
        });
        this.taskView.saveResponse(savedAnswer);
    },

    /**
         * showAnswer
         *
         * Show the correct answer to the task.
         */
    showAnswer() {

        // Abort method if origin is the author.
        if (APPLICATION === 'author') {
            return;
        }

        // Disable draggable interactions.
        this.itemsListElement.find('.js-item').draggable('disable');

        this.JSONAnswer.each((categoryAnswer) => {

            // Retrieve correct answer for category.
            var categoryModelAnswer = this.categoriesCollection.get(categoryAnswer.id);

            categoryAnswer.get('items').each((answerItem) => {

                // If answered item is contained in the itemlist of the correct answer for the category
                // apply styling to show this is correctly answered. Otherwise, if the answer appears in the list of
                // possible items, but not the correct category, apply styling to show the answer is incorrect and
                // show the name of the category it should have been. If the answer does not appear in the list of
                // possible items, do nothing. This may occur when the teacher removes an item after a response has
                // already been given by the student. This should not be counted as an incorrect answer.
                var itemElement = this.categoriesElement.find('.js-item[data-id="' + answerItem.id + '"]');
                itemElement.addClass('js-show-answers');
                if (categoryModelAnswer.get('items').get(answerItem.id)) {
                    itemElement.addClass(Styles['show-answers--correct']);
                } else if (_.any(this.model.get('task_info_json').items, {
                    id: answerItem.id
                })) {
                    itemElement.addClass(Styles['show-answers--incorrect']);
                    itemElement.find('.js-correct-answer div').html(
                        this.getCorrectCategory(answerItem).get('name')
                    );
                    itemElement.find('.js-correct-answer').css('display', 'flex');
                }

            })

        })

        // Mark all items that are still left in the right column as incorrect, as they should have been sorted
        // into a category.
        this.itemsCollection.each((looseItem) => {
            var itemElement = this.itemsListElement.find('.js-item[data-id="' + looseItem.id + '"]');
            itemElement.addClass(Styles['show-answers--incorrect']);
            itemElement.find('.js-correct-answer div').html(
                this.getCorrectCategory(looseItem).get('name')
            );
            itemElement.find('.js-correct-answer').css('display', 'flex');
        })

    },

    showAuthorAnswer() { },

    /**
         * hideAnswer
         *
         * Hide the correct answer to the task.
         */
    hideAnswer() {
        this.$('.js-item').removeClass(
            'js-show-answers ' +
                Styles['show-answers--correct'] + ' ' + Styles['show-answers--incorrect']
        );
        this.$('.js-item .js-correct-answer div').text('');
        this.$('.js-item .js-correct-answer').hide();
    },

    /**
         * getCorrectAnswer
         *
         * Generate summary of the correct answer to use above the list of student answers.
         *
         * @return {string} HTML blob to be inserted above the list of student answers.
         */
    getCorrectAnswer() {
        return this.categoriesCollection.reduce((answerString, category) => {

            const categoryItemString = category.get('items').reduce((m, item) => {
                return m + '<div>' + item.get('name') + '</div>'
            }, '')

            return answerString + AnswerItem({
                Styles,
                category: Util.renderContentSafely(category.get('name')),
                items: Util.renderContentSafely(categoryItemString)
            });
        }, '')
    },

    // (TEACHER ONLY) Create HTML blob containing the answer of each student
    /**
         * getStudentAnswer
         *
         * Generate summary of the answer student has given for use in the list of student answers.
         *
         * @param  {Object} responseModel Student response data
         * @return {string}               HTML blob to be used for the student answer view.
         */
    getStudentAnswer(responseModel) {
        const responseCollection = new Backbone.Collection(
            _.filter(responseModel.get('json_answer'), (responseCategory) => {
                return this.categoriesCollection.get(responseCategory.id);
            })
        );
        return responseCollection.reduce((answerString, category) => {

            var categoryItems = category.get('items');
            if (categoryItems) {
                categoryItems = new Backbone.Collection(categoryItems);

                return answerString + AnswerItem({
                    Styles,
                    category: category.get('name'),
                    items: categoryItems.reduce((categoryItemString, item) => {

                        // This if statement is added to fix the podio issue LB6398. It will only render answers
                        // that are still relevant and will prevent the frontend to crash.
                        const correctCategory = this.getCorrectCategory(item)
                        if (correctCategory) {
                            // Is correct category.
                            if (category.id === correctCategory.id) {
                                return categoryItemString + '<div data-correct=1>' + item.get('name') + '</div>'
                            }
                            return categoryItemString + '<div data-correct=0>' + item.get('name') + '</div>'
                        }

                        return categoryItemString

                    }, '')
                });

            }

            return answerString

        }, '')
    },

    /**
         * lockAnswer
         *
         * Triggered when the lock-answers event is sent from a Presentation view.
         * It will make sure students can't change their answer. This is typically
         * done when the taskState inside the presentation is 1 or 2, meaning the
         * teacher is showing the student's answers.
         */
    lockAnswer() {
        _.invoke(this.getChildViewsOfInstance(Category), 'lock');
        _.invoke(this.getChildViewsOfInstance(Item), 'lock');
    },

    /**
         * unlockAnswer
         *
         * Triggered when the unlock-answers event is sent from a Presentation view.
         * It will make sure students chan fill an answer again.
         */
    unlockAnswer() {
        _.invoke(this.getChildViewsOfInstance(Category), 'unlock');
        _.invoke(this.getChildViewsOfInstance(Item), 'unlock');

    }

});
