<script>
    import TaskGroupModel from 'models/TaskGroupModel';
    import {onDestroy, tick} from 'svelte';
    import ACL from 'util/ACL';

    export let activeItemKey
    export let itemKey = Infinity
    export let model = new Backbone.Model()
    export let disabled = null

    let isActive = false

    let itemElement

    activeItemKey.subscribe((value) => {
        isActive = itemKey === value
        scrollIntoView()
    })

    scrollIntoView()

    const activityModel = model.getActivityModel?.()

    let status
    setStatus()

    if (activityModel) {
        // Update status when the activity-wide 'showScores' flag is changed in linear activity.
        activityModel.on('change:showScores', setStatus)
        // Update status when score of or saved stated (server, local) of a response changes.
        activityModel.responses.on('change:score change:saved_state', setStatus)
    }
    // Update statgus when the taskgroup-specific 'showScores' flag is enabled when clicking in
    // the 'show scores' button in an adaptive activity task group.
    model.on('change:showScores', setStatus)

    function setStatus() {
        if (
            !activityModel ||
            !(model instanceof TaskGroupModel) ||
            model.tasks.length === 0
        ) {
            return
        }

        const {countChecked, countMade, score} = model.getProgress(Backbone.Model.user, false)
        if (
            // If user is student or supervisor
            ACL.checkRole([ACL.roles.STUDENT, ACL.roles.SUPERVISOR]) &&
            // If answers are set to visible in this activity.
            activityModel.get('show_answers') &&
            // If the activity or task group has the showScores flag.
            // Or if the activity is of an exam type, applicable for student exam answer review mode.
            (
                activityModel.get('showScores') || model.get('showScores') || activityModel.isExam()
            ) &&
            // If number of tasks checked equals number of tasks in the task group.
            countChecked === model.tasks.length
        ) {
            if (score === 0) {
                status = 'isIncorrect'
            } else if (score === countChecked) {
                status = 'isCorrect'
            } else {
                status = 'isSomewhatCorrect'
            }
        } else if (countMade === model.tasks.length) {
            status = 'isFinished'
        } else {
            status = 'hasTasks'
        }
    }

    function setActive() {
        activeItemKey.set(itemKey)
    }

    async function scrollIntoView() {
        if (!itemElement) {
            await tick()
        }
        if (isActive && itemElement) {
            itemElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            })
        }
    }

    onDestroy(() => {
        if (activityModel) {
            activityModel.off('change:showScores', setStatus)
            activityModel.responses.off('change:score add', setStatus)
        }
        model.off('change:showScores', setStatus)
    })
</script>

<button
    bind:this={itemElement}
    class:is-active={isActive}
    {disabled}
    on:click={setActive}
>
    <div class="index"><slot></slot></div>
    <div
        class="status"
        class:has-status={status !== null}
        class:status--has-tasks={status === 'hasTasks'}
        class:status--is-finished={status === 'isFinished'}
        class:status--is-incorrect={status === 'isIncorrect'}
        class:status--is-somewhat-correct={status === 'isSomewhatCorrect'}
        class:status--is-correct={status === 'isCorrect'}
    ></div>
</button>

<style lang="scss">
    button {
        user-select: none;
        position: relative;
        display: flex;
        align-items: stretch;
        background-color: $white;
        height: 40px;
        width: 40px;
        border: 1px solid $line-gray;
        color: $blue-black-50;
        fill: currentColor;
        border-radius: 12px;

        &.is-active {
            color: $white;
            background-color: $blue;
            border-color: $blue;

            &:before {
                content: '';
                border: solid transparent;
                border-right: solid $blue;
                border-width: 6px;
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                right: 100%;
            }
        }

        &:hover:not(.is-active):not(:disabled) {
            color: $blue-black;
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    .index {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        padding: 15px 0;
        font-size: 16px;

        > :global(svg) {
            width: 20px;
            height: 20px;
        }
    }

    .status {
        display: none;
        position: absolute;
        right: -1px;
        bottom: -1px;
        width: 10px;
        height: 10px;
        border-radius: 50%;

        &.has-status {
            display: flex;
        }

        &--has-tasks {
            background-color: $white;
            border: 2px solid $blue-black;
        }

        &--is-finished {
            background-color: $blue-black;
        }

        &--is-incorrect {
            background-color: $status-red;
        }

        &--is-somewhat-correct {
            background-color: $status-orange;
        }

        &--is-correct {
            background-color: $status-green;
        }
    }
</style>
