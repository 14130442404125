<script>
    import TimeUtil from 'util/TimeUtil';
    import { getSender } from 'views/pages/users/feedback/FeedbackUtil';

    export let reply
</script>

<div class="reply">
    <div class="header">
        <span class="sender">
            {getSender(reply).name}
        </span>
        <span class="timestamp">
            {TimeUtil.prettyDate(reply.created_at)}
        </span>
    </div>
    <div class="feedback-text">
        {reply.message}
    </div>
</div>

<style lang="scss">
    .reply {
        padding-left: 32px;

        .sender {
            color: $blue-black;
        }
    }

    .header {
        margin-bottom: 8px;
        color: $blue-black-50;
    }

    .timestamp {
        padding-left: 4px;
    }

    .feedback-text {
        background: $background-gray;
        border-radius: 12px 16px;
        padding: 16px;
        display: inline-block;
        margin-bottom: $spacing-8;
    }
</style>
