<script>
    export let clickCallback
    export let label

</script>

<button class="task-label" on:click={clickCallback}>{label}</button>

<style lang="scss">
    .task-label {
        font-size: 16px;
        padding: 8px 16px;
        background: $black-10;
        border-radius: 12px;
        cursor: pointer;

        &:hover {
            background: $black-25;
        }
    }
</style>
