<script>
    import Icon from 'views/components/icon/Icon.svelte'

    let navigationBarElement
    let itemsElement
    let showArrows = true
    let isTopArrowDisabled = false
    let isBottomArrowDisabled = false

    function initElements(el) {
        navigationBarElement = el
        itemsElement = el.querySelector('.items')
        const resizeObserver = new ResizeObserver(() => {
            window.requestAnimationFrame(() => {
                const itemsElementHeight = itemsElement.scrollHeight
                showArrows = itemsElementHeight > el.clientHeight
            })
        })
        resizeObserver.observe(el)
        toggleArrows()
    }

    function toggleArrows() {
        if (showArrows && itemsElement.hasChildNodes()) {
            // 40 is the height of the arrow buttons in pixels.
            isTopArrowDisabled = itemsElement.firstElementChild.getBoundingClientRect().top > 40
            isBottomArrowDisabled =
                itemsElement.lastElementChild.getBoundingClientRect().bottom < navigationBarElement.clientHeight - 40
        }
    }

    function scrollUp() {
        scrollBy(-1)
    }

    function scrollDown() {
        scrollBy(1)
    }

    function scrollBy(direction) {
        const moveDistance = itemsElement.clientHeight / 2
        itemsElement.scrollBy({
            top: direction * moveDistance,
            behavior: 'smooth',
        })
    }
</script>

<nav class="navigation-bar" use:initElements>
    {#if showArrows}
        <button
            class="navigation-arrow navigation-arrow-up"
            disabled={isTopArrowDisabled}
            on:click={scrollUp}
        ><Icon name="arrow-up"></Icon></button>
    {/if}
    <div class="items" on:scroll={toggleArrows}><slot></slot></div>
    {#if showArrows}
        <button
            class="navigation-arrow navigation-arrow-down"
            disabled={isBottomArrowDisabled}
            on:click={scrollDown}
        ><Icon name="arrow-down"></Icon></button>
    {/if}
</nav>

<style lang="scss">
    .navigation-bar {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .items {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0 12px;

        overflow-y: auto;

        // Prevent showing scrollbar for firefox on windows
        scrollbar-width: none;
        flex: 1 1 auto;
        overscroll-behavior: none;

        // Prevent showing scrollbar on Chrome on Windows
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .items:is(:first-child) {
        padding-top: 8px;
    }

    .items:is(:last-child) {
        padding-bottom: 8px;
    }

    .navigation-arrow {
        user-select: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        background-color: $white;
        height: 40px;
        width: 40px;
        border: 1px solid $line-gray;
        color: $blue-black-50;
        fill: currentColor;
        border-radius: 12px;
        margin: 8px 12px;

        > :global(svg) {
            width: 20px;
            height: 20px;
        }

        &:disabled {
            opacity: 0.5;
        }

        &:hover:not(:disabled) {
            color: $blue-black;
        }
    }
</style>
