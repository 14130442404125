import Styles from './PaginationNavigation.scss';

import Template from './PaginationNavigation.hbs';
import Button from 'views/components/button/Button'

export default class PaginationNavigation extends BaseView {

    get events() {
        return {
            'click .js-pagination-container': 'onClickNavigate',
        }
    }

    /**
     * @param {string|Function} [options.previousUrlOrCallback] Url or callback of previous item if
     *  existent, else undefined.
     * @param {string} [options.previousLabel] Label of previous item if existent, else undefined.
     * @param {string|Function} [options.nextUrlOrCallback] Url or callback of next item if
     *  existent, else undefined.
     * @param {string} [options.nextLabel] Label of next item if existent, else undefined.
     * @param {string} [options.buttonUrlOrCallback] Url or callback of the higher level where the items are part of.
     * @param {string} [options.buttonLabel] Button text indicating the higher level the item is part of.
     * @param {boolean} [options.isActivity] if current page is activity
     */
    initialize({
        previousUrlOrCallback,
        previousLabel,
        nextUrlOrCallback,
        nextLabel,
        buttonUrlOrCallback,
        buttonLabel,
        previousTheme = 'transparent',
        nextTheme = 'transparent',
        isActivity
    }) {
        _.bindAll(
            this,
            'onClickNavigate',
            'navigateToCallback',
        );

        this.buttonUrlOrCallback = buttonUrlOrCallback;
        this.previousUrlOrCallback = previousUrlOrCallback;
        this.nextUrlOrCallback = nextUrlOrCallback;

        this.setElement(Template({
            Styles,
            isActivityOnMobile: ISMOBILE && isActivity,
            isOnlyItem: !this.nextUrlOrCallback && !this.previousUrlOrCallback,
        }));
        // make classnames of  PaginationNavigation accessible from views that
        // initiate
        this.styles = Styles;

        if (this.previousUrlOrCallback) {
            this.addChildView(
                new Button({
                    label: !ISMOBILE && previousLabel,
                    theme: previousTheme,
                    icon: 'arrow-left',
                    inline: true,
                    callback: (e) => this.onClickNavigate(e, 'previous'),
                    externalStyle: Styles['pagination-button'],
                }),
                '.js-button-previous'
            )
        }

        if (nextUrlOrCallback) {
            this.addChildView(
                new Button({
                    label: !ISMOBILE && nextLabel,
                    theme: nextTheme,
                    iconRight: 'arrow-right',
                    inline: true,
                    callback: (e) => this.onClickNavigate(e, 'next'),
                    externalStyle: Styles['pagination-button'],
                }),
                '.js-button-next'
            )
        }

        // only render button if button URL is passed, else .js-pagination-middle-section
        // will be left empty unless there are elements attached to it from the views
        if (buttonUrlOrCallback) {
            this.addChildView(
                new Button({
                    label: buttonLabel,
                    callback: buttonUrlOrCallback instanceof Function ?
                        buttonUrlOrCallback :
                        this.navigateToCallback,
                }),
                '.js-pagination-middle-section'
            );
        }

    }

    /**
     * Navigate to / load previous or next item
     *
     * @param  {MouseEvent} e   Mouse event
     * @param {'previous' | 'next'} direction which direction to navigate to
     */
    onClickNavigate(e, direction) {
        const navigationUrlOrCallback = this[`${direction}UrlOrCallback`];

        if (navigationUrlOrCallback instanceof Function) {
            // pass the event object back to the callback
            navigationUrlOrCallback(e, direction);
            return;
        }

        Backbone.history.navigate(
            navigationUrlOrCallback,
            {
                trigger: true
            }
        );
    }

    navigateToCallback() {
        Backbone.history.navigate(
            this.buttonUrlOrCallback, {
                trigger: true
            }
        );
    }

}
