<script>
    import TaskGroupsCollection from 'collections/TaskGroupsCollection'
    import {get, writable} from 'svelte/store';
    import ContentNavigationBar from 'views/components/contentNavigationBar/ContentNavigationBar.svelte'
    import ContentNavigationBarItem from 'views/components/contentNavigationBar/ContentNavigationBarItem.svelte'
    import Icon from 'views/components/icon/Icon.svelte'

    export let taskGroups = new TaskGroupsCollection()
    export let initialActiveItemKey = 0

    export const activeItemKey = writable(initialActiveItemKey)

    export function toNextItem() {
        activeItemKey.set(Math.min(taskGroups.size(), Math.max(-1, get(activeItemKey) + 1)))
    }
</script>

{#if !window.ISMOBILE}
    <div class="navigation-bar-outer">
        <ContentNavigationBar>
            {#each taskGroups.models as taskGroup}
                <ContentNavigationBarItem
                    itemKey={taskGroup.get('sequence')}
                    {activeItemKey}
                    model={taskGroup}
                    disabled={true}
                >
                    {taskGroup.get('index')}
                </ContentNavigationBarItem>
            {/each}
            <ContentNavigationBarItem
                itemKey={taskGroups.size()}
                {activeItemKey}
                disabled={true}
            >
                <Icon name="assignment"></Icon>
            </ContentNavigationBarItem>
        </ContentNavigationBar>
    </div>
{/if}

<style lang="scss">
    .navigation-bar-outer {
        grid-area: menu-right;
        position: sticky;
        top: 0;
        height: 100vh;
        /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
        height: 100dvh;
        @include z-index-work-on-navigation;

        -webkit-overflow-scrolling: touch;
    }
</style>
