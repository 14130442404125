import TasksCollection from 'collections/TasksCollection';
import ElementsCollection from 'collections/ElementsCollection';
import TheorySourcesCollection from 'collections/TheorySourcesCollection'
import Util from 'util/util';

export default class TaskGroupModel extends Backbone.Model {

    preinitialize() {
        this.constructor.type = 'taskGroup'
    }

    initialize() {
        this.setIndex()
        this.on('change:sequence', _.debounce(this.setIndex))
    }

    url() {
        if (this.id) {
            if (this.collection) {
                return '/task_groups/backbone_backend/' + this.collection.activity_id + '/' + this.id + '.json';
            }
            return '/task_groups/backbone_backend/0/' + this.id + '.json'
        }
        return '/task_groups/backbone_backend/' + this.collection.activity_id + '.json'
    }

    addToActivity(activity_id) {
        $.post('/task_groups/add_to_activity/' + this.id + '.json', {
            activity_id
        }, this.onLoadAddToActivity);
    }

    /**
     * Set a new task group set ID for this task group after updating the backend.
     *
     * @param {Number|null} examSetId   task group set ID or null if task group set is being removed.
     */
    async updateExamSet(examSetId) {
        return $.post(`/task_groups/update_exam_set/${this.collection.activity_id}/${this.id}.json`, {
            examSetId
        }, () => {
            this.set({
                generated_exam_set_id: examSetId
            })
        })
    }

    getShortText() {

        // First use the introduction of the task group
        let introduction = Util.stripTags(this.get('introduction')?.trim())

        // If we haven't found an introduction yet, use the tasks to generate the short text
        if (!introduction) {
            this.tasks?.some((model) => {
                introduction = model.getShortText()

                // If we found an introduction, return true so that the loop stops
                if (introduction) {
                    return true
                }
            })
        }

        // If we haven't found an introduction yet, continue to sources
        if (!introduction) {
            this.elements?.some((model) => {
                if (model.get('element_type') === 'source') {
                    introduction = model.getShortText()

                    // If we found an introduction, return true so that the loop stops
                    if (introduction) {
                        return true
                    }
                }
            })
        }

        // If we could not generate an introduction, use a fallback
        if (!introduction && this.tasks?.length > 0) {
            introduction = this.tasks.length === 1 ?
                window.i18n.gettext('Task') :
                window.i18n.gettext('Tasks')
        }
        if (!introduction && this.elements?.length > 0) {
            introduction = this.elements.length === 1 ?
                window.i18n.gettext('Source') :
                window.i18n.gettext('Sources')
        }
        if (!introduction) {
            introduction = this.activityModel.get('type') === 'presentation' ?
                introduction = window.i18n.gettext('Empty slide') :
                introduction = window.i18n.gettext('Empty task group')
        }

        if (introduction.length > 70) {
            introduction = introduction.substring(0, 69) + '…'
        }

        return introduction
    }

    getFinishedTasks(activityModel) {
        const responses = activityModel.responses
        return this.tasks.filter((taskModel) => {
            return responses.any((responseModel) => {
                return (
                    responseModel.get('task_id') === taskModel.id &&
                    responseModel.get('user_id') === Backbone.Model.user.id &&
                    responseModel.has('json_answer')
                )
            })
        })
    }

    setIndex() {
        this.set('index', this.get('custom_index') || this.get('sequence') + 1)
    }

    parse(response) {

        // If the backend responds with just a simple success or error status, do not set this response
        // as the task group model data.
        if (response.status === 'success' || response.status === 'error') {
            return;
        }

        return this.formatData(response);
    }

    /**
     * @param {Object} attributes   format data with the correct data types
     * @returns {Object} formatted attributes
     */
    formatData(attributes) {
        // Only format elements as a ElementsCollection if they come straight from the backend.
        if (Array.isArray(attributes.Element)) {
            this.elements = new ElementsCollection(attributes.Element);
            this.elements.parent_id = attributes.id;
            this.tasks = new TasksCollection(this.elements.where({
                element_type: 'task'
            }));
            this.tasks.parent_id = attributes.id
            this.tasks.each((task, sequence) => {
                task.set('index', Util.numToLetter(sequence))
            })
        }
        delete attributes.Element

        if (Array.isArray(attributes.LearningTexts)) {
            this.learningTexts = new TheorySourcesCollection(attributes.LearningTexts, {parse: true})
            this.learningTexts.parent_id = attributes.id
        }
        delete attributes.Learningtexts

        // Flatten TaskGroup object into attributes
        attributes = Object.assign(attributes, attributes.TaskGroup)
        delete attributes.TaskGroup

        return attributes
    }

    getAboutTaskGroup() {

        return {
            subjects: _.countBy(this.getSubjects()),
            questionTypes: _.countBy(this.getQuestionTypes()),
        };

    }

    /**
     * Get all subjects in this task group.
     *
     * @returns {Array} Array of subject label strings
     */
    getSubjects() {
        return this.elements.map(task => task.get('Subject').subject)
    }

    /**
     * Get all question types in this task group.
     *
     * @returns {Array} Array of question type label strings
     */
    getQuestionTypes() {
        return this.tasks.map(task => task.get('QuestionType').question_type)
    }

    getActivityModel() {
        return this.activityModel;
    }

    getSectionModel() {
        return Backbone.Collection.sections.get(
            this.getActivityModel()?.get('section_id')
        )
    }

    getChapterModel() {
        return Backbone.Collection.chapters.get(this.getSectionModel()?.get('chapter_id'))
    }

    getGroupModel() {
        return Backbone.Collection.groups.get(this.getChapterModel()?.get('group_id'))
    }

    getAllCrumblepathModels() {
        const groupModel = this.getGroupModel()
        const crumblepathModels = []
        crumblepathModels.push(groupModel.getCrumblepathModel())
        if (groupModel.get('layers') > 2) {
            crumblepathModels.push(this.getChapterModel().getCrumblepathModel())
        }
        if (groupModel.get('layers') > 1) {
            crumblepathModels.push(this.getSectionModel().getCrumblepathModel())
        }
        crumblepathModels.push(this.getActivityModel().getCrumblepathModel())
        crumblepathModels.push(this.getCrumblepathModel())
        return crumblepathModels
    }

    getCrumblepathModel() {
        return new Backbone.Model({
            index: this.get('index'),
            label: '',
            level: 'task_groups',
            path_id: this.activityModel.get('id') + '/' + this.get('id')
        });
    }

    /**
     * Returns a string containing the index of this task group and a specific task within
     * this task group. For example: "2C" (where 2 is task group index and C the to letter
     * converted index of the 3th task in this task group).
     *
     * @param  {number} taskID  ID of a task model that should be present in this task group.
     * @return {string}         task label
     */
    getTaskLabel(taskID) {
        return this.get('index') + this.tasks.get(taskID).get('index')
    }

    // Check if the user has edit rights for this taskgroup
    canBeEdited() {
        if (this.get('user_id') === Backbone.Model.user.id) {
            return true
        }

        const groupModel = this.getGroupModel()
        if (groupModel && groupModel.get('collaboration_enabled') && groupModel.get('teachers').has(this.get('user_id'))) {
            return true
        }

        return false
    }

    // Check if the user has copy rights for this taskgroup
    canBeCopied() {
        if (this.get('cloneable')) {
            return true
        }

        return this.canBeEdited();
    }

    // Get the label for the explanation button or sidebar. The copy is based on the type of source.
    getExplanationLabel(includeShow = false) {
        if (!this.learningTexts.length) {
            return false
        }

        let label
        if (this.learningTexts.at(0).get('theory_collection_id') > 0) {
            label = window.i18n.gettext('Theory')
        } else if ([6, 12].includes(this.learningTexts.at(0).get('template_id'))) {
            label = window.i18n.gettext('Text')
        } else {
            label = window.i18n.gettext('Source')
        }

        if (includeShow) {
            label = window.i18n.gettext('Show') + ' ' + label.toLowerCase()
        }
        return label
    }

    getProgress(model, original = true) {

        const isGroup = (model.constructor.type === 'group');

        let countMade = 0
        let countChecked = 0
        let score = 0.0
        let countTasks = this.tasks.length || 0;
        let progress = 0;
        let humanFriendlyScore = 0;

        if (isGroup) {
            countTasks *= model.students.length || 1;
        }

        const activityModel = this.getActivityModel();
        const responses = (original)
            ? activityModel.responses_original
            : activityModel.responses;

        responses.each(response => {
            if (!this.tasks.has(response.get('task_id'))) {
                return;
            }

            if (response.get('user_id') !== model.id && !isGroup) {
                return;
            }

            // Do not count responses that are completely empty.
            if (!response.has('json_answer')) {
                return
            }

            countMade++;

            if (response.get('score') !== -1) {
                countChecked++;
                score += parseFloat(response.get('score'))
            }
        });

        humanFriendlyScore = countChecked
            ? Math.round(score / countChecked * 100)
            : 0

        progress = Math.round(countMade / countTasks * 100)

        return {
            countMade,
            countChecked,
            humanFriendlyScore,
            score,
            countTasks,
            progress
        }
    }

    async getAuthorName() {
        let authorName = ''
        await $.get({
            url: '/task_groups/author_name/' + this.id + '.json',
            success: (response) => {
                if (response.status === 'success') {
                    authorName = response.author_name
                }
            }
        })
        return authorName
    }

}
