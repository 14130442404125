import Styles from './Adaptive.scss';

import Template from './Adaptive.hbs';
import Summary from 'views/pages/activities/show/types/adaptive/summary/Summary'
import SubjectsCollection from 'collections/SubjectsCollection'
import SubjectProgressLabel from 'views/components/subjectProgressLabel/SubjectProgressLabel'
import OptionGroup from 'views/components/optionGroup/OptionGroup.svelte'
import Button from 'views/components/button/Button'
import HeroButton from 'views/components/heroButton/HeroButton'
import TaskGroup from 'views/components/taskGroups/TaskGroup'
import NavigationBar from 'views/pages/activities/show/types/adaptive/NavigationBarAdaptive.svelte'
import SelectSubjects from 'views/pages/activities/show/types/adaptive/selectSubjects/SelectSubjects'
import StatusCard from 'views/components/statusCard/StatusCard';
import AdaptiveTaskGroupsCollection from 'collections/AdaptiveTaskGroupsCollection'

export default class AdaptiveActivity extends BaseView {

    initialize({
        params,
        activityShow,
    }) {

        // Make activityShow accessible within methods
        this.activityShow = activityShow

        // Make this accessible with following methods
        _.bindAll(this,
            'restart',
            'addNavigationBar',
            'parseSummary',
            'onLoadNewActiveItem',
            'onChangePickedOption',
            'onSaveSelectedSubjects',
        );

        const isPractice = (this.model.get('type') === 'adaptive_student')

        const isEmptyPractice = (
            isPractice &&
            this.model.get('practiceTasksForChapter') === 0 &&
            this.model.get('practiceTasksForSection') === 0
        );

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles,
            isPractice,
            made: this.model.get('tasks_made'),
            isEmptyPractice
        }));

        this.startButton = this.addChildView(
            new Button({
                label: window.i18n.gettext('Begin'),
                iconRight: 'arrow-right',
                theme: 'secondary',
                size: 'medium',
                callback: this.restart
            }),
            '.js-start-activity'
        );

        if (isEmptyPractice) {
            this.startButton.disable()
        }

        this.model.task_groups = new AdaptiveTaskGroupsCollection(null, { activityModel: this.model, parse: true })

        // Get the chapter model and make it accessible for submethods. This is needed within
        // the response model for exercise type of tasks.
        this.chapterModel = this.model.getChapterModel();

        // By default do not start exercise with chapter
        var startExerciseWithChapter = false;

        // Check if there params are set and that there are more then 1
        if (params.length > 1) {

            // If the second param is chapter
            if (params[1] === 'chapter') {

                // Start exercise with chapter is true
                startExerciseWithChapter = true;
            }
        }

        // Set the navigation url to the base url, this will remove any ID's
        // after the url
        Backbone.history.navigate(

            // Navigate to the base url
            '/activities/show/' + this.model.get('id'),

            // Using the following options
            {

                // Set url in browser but don't set it in the history
                replace: true
            }
        );

        // Create a subjects collection
        this.subjectsCollection = new SubjectsCollection()
        this.subjectsCollection.url = `/activities/get_subjects_status/${this.model.id}.json`

        // Start listening to
        this.listenTo(

            // Listen to the subjectsCollection
            this.subjectsCollection,

            // listen for the following events
            'change update reset',

            // When event triggered, call onChangeSubjectsCollection
            this.onChangeSubjectsCollection
        );

        // Create a data holder
        var subjectsFilter;

        // Check if there should be options available
        if (isPractice && !isEmptyPractice) {

            // Create a new option group
            this.optionGroup = this.addSvelteChildView('.js-option-group', OptionGroup, {

                // Add all the possible choices
                choices: [{
                    label: window.i18n.gettext('This section'),
                    type: 'section'
                }, {
                    label: window.i18n.gettext('This chapter'),
                    type: 'chapter'
                }, {
                    label: window.i18n.gettext('Own choice'),
                    type: 'pick',
                    click: 'always'
                }],

                // Set selected index based on the startExerciseWithChapter boolean
                selectedIndex: ((startExerciseWithChapter) ? 1 : 0),

                // Add the callback for when the selection changes
                callback: this.onChangePickedOption,
                doCallbackOnInitialize: true,
            })

        } else {
            // Load the subjects status
            this.subjectsCollection.fetch({

                // Add the data
                data: $.param({ subjectsFilter })
            })
        }

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle()
        Backbone.View.header.setCrumblepath(
            this.model.getAllCrumblepathModels(),
            'show'
        )

    }

    /**
    * This method will be called when the user changes the option within
    * the option group.
    *
    * @param  {Object} pickedOption     Picked option
    */
    onChangePickedOption(pickedOption) {

        // If there is a no tasks section message
        this.$('.js-subjects p').remove()

        // Start a switch case for the picked option's type
        switch (pickedOption.type) {

            // When type is pick
            case 'pick':

                // Open fullscreen component and load the SelectSubjects view within
                Backbone.View.Components.fullscreen.open(SelectSubjects, {

                    // Add the activity Id
                    activityId: this.model.id,

                    // Add the model to this view
                    model: this.chapterModel,

                    // Define which subjects should be already selected.
                    defaultSubjects: this.subjectsCollection,

                    // Add the buttons to the fullscreen component
                    buttons: [{
                        theme: 'secondary',
                        label: window.i18n.gettext('Cancel'),

                        callback: () => {

                            // Revert to previous option.
                            this.optionGroup.goToPrevious()

                            // Close the fullscreen modal
                            Backbone.View.Components.fullscreen.close()
                        }
                    }, {
                        label: window.i18n.gettext('Use selected subjects'),
                        callback: this.onSaveSelectedSubjects
                    }]
                });

                break;

            // When type is section
            case 'section':

                // Check if there aren't any practice subjects for this section
                if (this.model.get('practiceTasksForSection') === 0) {
                    this.$('.js-subjects').html(
                        `<p>${window.i18n.gettext('There aren\'t any subjects set for this section.')}</p>`
                    )
                }

                // Reload the subjects collection
                this.subjectsCollection.fetch({
                    data: $.param({

                        // Set the section id by getting it from the section model
                        section_id: this.model.getSectionModel().get('id')
                    })
                });
                break;

            // When type is chapter
            case 'chapter':

                // Reload the subjects collection
                this.subjectsCollection.fetch({

                    // Add the data
                    data: $.param({

                        // Set the section id by getting it from the section model
                        chapter_id: this.model.getChapterModel().get('id')
                    })
                });
                break;
        }
    }

    /**
    * This method will be called when the user clicks on the save button
    * within the select subjects fullscreen. It will get all the selected
    * subjects and put them into the subjects collection.
    */
    onSaveSelectedSubjects() {
        const selectedSubjects = Backbone.View.Components.fullscreen.subView.collection.where({isChecked: true})
        this.subjectsCollection.reset(selectedSubjects)
        Backbone.View.Components.fullscreen.close()
    }

    /**
    * This method will be called when the subjects collection changes.
    * Remove all existing subject progress labels and add new ones using the collection.
    */
    onChangeSubjectsCollection() {
        this.destroyChildViewsOfInstance(SubjectProgressLabel)
        this.subjectsCollection.each((model) => {
            this.addChildView(new SubjectProgressLabel({
                model
            }), '.js-subjects')
        })
    }

    /**
    * Create a new navigationbar with the correct listeners to it.
    */
    addNavigationBar() {
        this.navigationBar = this.addSvelteChildView(
            Backbone.View.layout.el,
            NavigationBar,
            {
                taskGroups: this.model.task_groups,
            }
        )
        this.navigationBar.activeItemKey.subscribe((value) => {
            this.onLoadNewActiveItem(value)
        })
    }

    /**
    * This function will be called when the user clicks on the start activity button.
    * It will activate the navigationBar which will make the first item active. This
    * will trigger the onLoadNewActiveItem function wich will animate the taskgroup
    * into the view
    */
    onClickStartActivity() {

        // Tell the body that it's at the work on view
        $('body').addClass('is-work-on');

        this.skipButton = Backbone.View.header.addButton(
            new HeroButton({
                firstLine: window.i18n.gettext('Skip task group'),
                icon: 'skip-next',
                callback: () => this.onClickNextbutton('navigation'),
            })
        );

        this.checkAnswersButton = this.addChildView(new Button({
            label: window.i18n.gettext('Check answers'),
            icon: 'lightbulb',
            inline: true,
            callback: () => this.onClickNextbutton('taskGroup'),
        }), '.js-activity-buttons')

        // Create a button
        this.goToNextButton = this.addChildView(new Button({
            label: window.i18n.gettext('Next exercise'),
            callback: () => this.onClickNextbutton('navigation'),
            inline: true,
            iconRight: 'arrow-right',
        }), '.js-activity-buttons')

        // initially hide goToNextButton
        this.goToNextButton.$el.hide()

        // Set the onbeforeunload method. This is an alert that pops up when user attemps to leave the page.
        window.onbeforeunload = function() {
            return window.i18n.gettext('Do you really want to stop practicing?');
        };
    }

    /**
     * This method will be called when there is a new active item. It will
     * switch between taskgroups sliding out the previous and sliding in the
     * next taskgroup
     *
     * @param  {Integer} activeItemKey          Key of active task group or start or end page
     */
    onLoadNewActiveItem(activeItemKey) {

        // Get the activity viewport container
        var taskGroupContainer = this.$('.js-taskgroup');

        // Get the animation distance for the x-axis
        const animateXTo = (
            (
                // First check if the direction is bigger then 0. Directions explaned:
                // positive direction (f.e. 1)
                //      means the clicked/navigated item is higher than
                //      the current active item
                //
                // negative direction (f.e. -1)
                //      means the clicked/navigated item is lower then
                //      the current active item
                activeItemKey >= (this.lastActiveItemKey || 0) ? -1 : 1
            ) * (

                // Get the window's width to be sure we animate the activity out of the
                // window add 100% of this width to it to make animation more fluid
                window.innerWidth * 2
            )
        );

        // Hide horizontal scrollbar while doing the transition animation
        this.$el.css('overflow-x', 'hidden');

        const taskGroupModel = this.model.task_groups.findWhere({sequence: activeItemKey})

        // Start the animation on the activity viewport
        TweenMax.to(this.$('.js-activity-viewport'), {
            // Animate the x to the determined x distance
            x: animateXTo,
            duration: 0.15,
            ease: 'expo.inOut',
            overwrite: true,

            // When animation is complete
            onComplete: () => {

                // Check if there is an active taskGroup, then destroy its view.
                if (this.activeItem) {
                    this.unregisterAndDestroyChildView(this.activeItem)
                }

                // Send time of previous time log (if present)
                this.model.stopTimeLog()

                // If active item key matches the sequence of a task group, render that task group.
                if (taskGroupModel) {

                    // Start time log for new task group.
                    this.model.startTimeLog(taskGroupModel)

                    this.parseNewItemTaskgroup(taskGroupModel)
                    this.lastActiveItemKey = activeItemKey

                } else if (activeItemKey === -1 || activeItemKey === this.model.task_groups.size()) {
                    // Otherwise if key is outside of task group sequence range, show start/end summary view.
                    this.parseSummary()
                    this.lastActiveItemKey = activeItemKey
                }

                // Scroll back to top of page after first half of the page transition is completed.
                $(window).scrollTop(0);

                // Add the taskgroup to the taskGroupContainer
                taskGroupContainer.html(this.activeItem.$el);

                // Check if there is a show method
                if (this.activeItem.show) {

                    // Call the show method
                    this.activeItem.show();
                }

                // Start a new animation to slide the new taskgroup in
                TweenMax.fromTo(this.$('.js-activity-viewport'), {
                    // Start at a reversed position of x
                    x: -animateXTo,
                    duration: 0.15,
                    ease: 'expo.inOut',
                    overwrite: true,
                }, {
                    overwrite: true,
                    onComplete: () => {
                        // Remove transform attribute since it messes stuff up
                        this.$el.css('overflow-x', '');
                        this.$('.js-activity-viewport').css('transform', '');

                        if (this.activeItem.onInView) {
                            this.activeItem.onInView();
                        }
                    },
                    // Go to a zero position
                    x: 0
                });
            }
        });
    }

    /**
     * This method will parse a new taskgroup and store it within the global
     * active item variable.
     *
     * @param   {TaskGroupModel} taskGroupModel  task group model
     */
    parseNewItemTaskgroup(taskGroupModel) {
        this.activeItem = new TaskGroup({
            work_on: this,
            model: taskGroupModel,
        });

        // Register taskgroup as childview
        this.registerChildView(this.activeItem);

        // Destroy the you've made this task group before banner from the previous task group, if any exist.
        if (this.madeBeforeBanner) {
            this.madeBeforeBanner.destroy()
        }

        // Check if the task group has been made before
        if (taskGroupModel.get('made_before')) {
            this.madeBeforeBanner = this.addChildView(new StatusCard({
                icon: 'autorenew',
                cardContent: window.i18n.gettext('You\'ve made this task group before. Do you still know the answer?')
            }), '.js-made-before-banner')
        }

        // Set the navigation url to pass the taskgroup id without triggering routing.
        Backbone.history.navigate(`/activities/show/${this.model.id}/${taskGroupModel.id}`, {trigger: false})
    }

    /**
     * Create start/end summary page of activity.
     */
    parseSummary() {

        // Destroy the you've made this task group before banner from the previous task group, if any exist.
        if (this.madeBeforeBanner) {
            this.madeBeforeBanner.destroy()
        }

        // Create a new summary and store it into this.activeItem
        this.activeItem = new Summary({
            adaptiveSubjects: this.subjectsCollection,
            model: this.model,
            showView: this
        });

        // Register the summary as childview
        this.registerChildView(this.activeItem);

        // Since this is the end of the adaptive activity, the button that
        // lets the user check their answers and navigate to the next item
        // should be destroyed. This will happen below

        // Remove the go to next button because there is no next
        this.unregisterAndDestroyChildView(this.goToNextButton);
        this.unregisterAndDestroyChildView(this.checkAnswersButton);

        // Remove the skip button because there is no next task, so there is
        // nothing to skip
        this.unregisterAndDestroyChildView(this.skipButton);
    }

    /**
    * This method will restart the session for the student. It will remove
    * the navigationbar, fetch new taskgroups and create a new navigationbar.
    * After creatign a new navigation bar it will execute the start method directly
    */
    restart() {

        // Check if there are any subjects selected
        if (this.subjectsCollection.length > 0) {

            // Check if there is a navigationbar
            if (this.navigationBar) {

                // Remove the navigationbar from this view
                this.unregisterAndDestroyChildView(this.navigationBar);
            }

            // If the skip button is created (won't be at the start or summary screen)
            if (this.skipButton) {

                // Remove the skipbutton from this view
                this.unregisterAndDestroyChildView(this.skipButton);
            }

            // Reset the task_groups collection
            this.model.task_groups.reset();

            // Reset the responses collection
            this.model.responses.reset();

            this.startButton.disable(true)

            const data = this.model.get('type') !== 'adaptive_student' ?
                {} :
                $.param({ subject_ids: this.subjectsCollection.pluck('id') })

            // Fetch the task groups
            this.model.task_groups.fetch({

                // Add data to the fetching
                data,

                success: (collection, response) => {

                    if (response.status === 'error') {
                        this.startButton.enable()

                        if (response.message === 'insufficient content') {
                            Backbone.View.layout.openStatus(
                                window.i18n.gettext('There are not enough tasks for these subjects. Select more or different subjects.'),
                                'warning'
                            )
                        }

                        return;
                    }

                    // Associate parent activity model with each task group.
                    collection.each((taskGroupModel) => {
                        taskGroupModel.activityModel = this.model
                    })

                    // Call the add navigation method
                    this.addNavigationBar();

                    // Start the activity
                    this.onClickStartActivity();

                    // Check if there is a startbutton set
                    if (this.startButton) {

                        // Destroy it
                        this.unregisterAndDestroyChildView(this.startButton);
                    }

                },
                error: () => {
                    this.startButton.enable()
                }
            });

        // Else there aren't any subjects active
        } else {

            // Open a new status message
            Backbone.View.layout.openStatus(

                // Tell the user that there aren't any subjects selected
                window.i18n.gettext('No subjects are selected.'),

                // Set type of status message to warning
                'warning'
            );
        }
    }

    /**
    * This method will be called when the user clicks on the goToNextButton.
    * It will check the state of the button (taskGroup or navigation) and will
    * act accordingly. For taskgroups it will show the student if their answers
    * where correctly. For navigation it will navigate the student to the next
    * task group
    *
    * @param  {String} applyTo     String to at with state this button is
    * @param  {String} calledBy    String to indicate who calls this method
    */
    onClickNextbutton(applyTo, calledBy) {

        // close sidebar before navigating to next task
        if (applyTo === 'navigation' && Backbone.View.sidebar.isOpen) {
            Backbone.View.sidebar.closeSidebar()
        }

        // Start a switch / case on the apply to string
        switch (applyTo) {

            // When the applyto string is taskgroup
            case 'taskGroup': {

                this.activeItem.model.set('showScores', true)

                // Default the unscoredTasks to false
                var unscoredTasks = false;

                // Loop trough each task view
                _.each(this.activeItem.taskViews, (taskView) => {

                    // Stop listening to this taskview for when there already was a
                    // listener
                    this.stopListening(taskView);

                    // Fix to prevent multiple calls on the showAnswer when
                    // a task is graded by a student.
                    if (!calledBy || calledBy !== 'scored-response') {

                        // Show answer for each task
                        taskView.showAnswer();
                    }

                    // Check if this task needs student grading. Tell the check that the student
                    // saw the model answer
                    if (taskView.needsStudentGrading(true)) {

                        // Listen to the is-scored event once
                        this.listenToOnce(
                            taskView,
                            'is-scored',
                            () => this.onClickNextbutton('taskGroup', 'scored-response')
                        )

                        // Set unscoredTasks to true
                        unscoredTasks = true;
                    }
                })
                const label = unscoredTasks
                    ? window.i18n.gettext('Skip')
                    : window.i18n.gettext('Next exercise')

                this.goToNextButton.changeLabel(label)

                this.checkAnswersButton.$el.hide()
                this.goToNextButton.$el.show()

                break;
            }

            // When the applyto string is navigation
            case 'navigation':

                // Go to next item
                this.navigationBar.toNextItem()

                this.goToNextButton.$el.hide()
                this.checkAnswersButton.$el.show()
                break
        }
    }

    beforeDestroy() {

        // Remove the is-work-on class
        $('body').removeClass('is-work-on');

        // Remove the onbeforeunload logic
        window.onbeforeunload = undefined;
    }

    hide(callback) {

        // reset possitioning of .center-panel
        $('body').removeClass('is-work-on')

        // Remove the onbeforeunload logic
        window.onbeforeunload = undefined

        // Call callback from the first argument of the hide method
        callback(this);

        if (ISMOBILE) {
            $('.js-hero').css('margin-top', '0');
        }
    }

}
