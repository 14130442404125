import Styles from './Show.scss';

import Template from './Show.hbs';
import LinearActivity from 'views/pages/activities/show/types/linear/Linear'
import ExamActivity from 'views/pages/activities/show/types/exam/Exam'
import AdaptiveActivity from 'views/pages/activities/show/types/adaptive/Adaptive'
import PresentationActivity from 'views/pages/activities/show/types/presentation/Presentation'
import VideoActivity from 'views/pages/activities/show/types/video/Video'
import WordsActivity from 'views/pages/words/List'
import WordsSessionActivity from 'views/pages/words/Session'
import LicensePanel from 'views/pages/activities/show/licensePanel/LicensePanel'
import ActivityModel from 'models/ActivityModel'
import Spinner from 'views/components/spinner/Spinner'
import CompetenciesActivity from 'views/pages/activities/show/types/competencies/Competencies';
import LtiActivity from 'views/pages/activities/show/types/lti/Lti';

export default class ActivityShow extends BaseView {

    initialize(options) {

        // Bind this scope to following methods
        _.bindAll(
            this,
            'fetchActivity',
            'onLoadActivity',
            'responseSaveStateChanged',
            'showOrderForm'
        );

        // Make the params accessible for every method
        this.params = options.params;

        // Isolate activityID from URL parameters.
        this.activityID = parseInt(this.params[0]);

        // retrieve words session ID if present
        this.wordsSessionId = this.params[2];

        // Create the view, passing the styling with it
        this.setElement(Template({
            Styles
        }));

        // Create a spinner to indicate we're loading this activity
        this.spinner = this.addChildView(new Spinner({}), '.js-spinner');

        // Create a new model of the type ActivityModel using the ID from the URL
        this.model = new ActivityModel({id: this.activityID});

        // Look for any responses for the current activity that are still waiting to be posted to the server.
        var localActivityResponses = Backbone.Model.user.responsesBuffer.getUnsyncedActivityResponses(this.activityID)
        // If any responses aren't posted yet, listen for updates (add and remove events) on the
        // local responses collection. If no responses are left behind, fetch the activity data right away.
        if (localActivityResponses.length) {
            this.listenTo(
                Backbone.Model.user.responsesBuffer,
                'update',
                this.responseSaveStateChanged
            );
            Backbone.Model.user.responsesBuffer.syncResponseToServer();
        } else {
            this.fetchActivity();
        }
    }

    /**
    * Check if there are any responses left that haven't been posted to the server yet.
    * If this is no longer the case, fetch the activity data.
    */
    responseSaveStateChanged() {
        const localActivityResponses = Backbone.Model.user.responsesBuffer.getUnsyncedActivityResponses(this.activityID)
        if (localActivityResponses.length === 0) {
            this.stopListening(
                Backbone.Model.user.responsesBuffer,
                'update',
                this.responseSaveStateChanged
            );
            this.fetchActivity();
        }
    }

    /**
     * Retrieve data on the current activity and load the activity if successful.
     */
    fetchActivity() {

        // Call the fetch function on this model
        this.activityModelXHR = this.model.fetch({

            // When fetching is successful call the onLoadActivity method
            success: this.onLoadActivity,

            // When the fetch is unsuccessful call the onErrorLoadingActivity method
            error: this.onErrorLoadingActivity
        });

    }

    /**
    * This function will be remapped to the onShowAndLoaded method. The
    * onShowAndLoaded method will be loose from the prototype and will
    * only be called after two times. It will remove the spinner and
    * add right view for this activity type to the view
    */
    async showAndLoaded() {

        // Remove the spinner.
        this.removeSpinner();

        // If the activity contains a standalone object and user is a student
        if (
            this.model.has('standalone') &&
            Backbone.Model.user.get('is_student')
        ) {

            // Do the following to any type but presentation
            if (this.model.get('type') !== 'presentation') {
                Backbone.View.menubar.destroy()
            }

            Backbone.Model.user.set({

                // Set this flag so we can easily perform a check in views where
                // the activity model is not available
                isStandaloneUser: true,

                // Make sure the email modal doesn't popup, because the user doesn't have an email
                has_seen_email_modal: true
            });

        }

        let currentWorkOnPageId
        // Check if there are any params
        if (this.params.length > 1) {

            // If the second param starts with 'task_id:' set the gotoTaskId
            if (this.params[1].substring(0, 8) === 'task_id:') {
                const gotoTask = this.model.tasks.get(this.params[1].substring(8))
                if (gotoTask) {
                    currentWorkOnPageId = gotoTask.get('task_group_id');
                    gotoTask.set('showStudentAnswers', true);
                }

                // here is passed the feedback id to scroll to
                if (this.params[2]) {
                    // wait for Answer view to render
                    setTimeout(() => {
                        this.scrollToAnswer(this.params[2])
                    }, 1000)
                }
            } else {
                currentWorkOnPageId = this.params[1]
            }
        }

        let activityView

        // check if activity belongs to group
        if (!this.model.getGroupModel()) {
            // if not, try to update global collections
            await Backbone.Model.user.updateCollections()

            // if group really does not exist, log to sentry and abort
            if (!this.model.getGroupModel()) {

                Backbone.View.layout.openStatus(
                    window.i18n.gettext('Something went wrong'),
                    'error',
                )

                window.sentry.withScope(scope => {
                    scope.setLevel('info')
                    window.sentry.captureMessage('Activity has no matching group.')
                })

                Backbone.history.navigate('/users/home', { trigger: true })

                return
            }

        }

        // Switch case on the model's type
        switch (this.model.get('type')) {

            // Adaptive type activities contains a static number
            // of taskgroups which are chosen trough the adaptive
            // alghoritm in the backend.
            case 'adaptive':
            case 'adaptive_student':

                activityView = new AdaptiveActivity({

                    // Pass on the params
                    params: this.params,

                    // Add this show view to it
                    activityShow: this,

                    // Pas on the model
                    model: this.model
                });

                break;

            case 'exam':
            case 'generated_exam':
            case 'diagnostic_exam':

                activityView = new ExamActivity({

                    // Pass on the currentWorkOnPageId
                    currentWorkOnPageId,

                    // Make this view accessible with exam activity
                    activityShow: this,

                    // Pass on the model
                    model: this.model

                });

                break;

            case 'presentation':

                activityView = new PresentationActivity({

                    // Make this view accessible within presentation activity
                    activityShow: this,

                    // Pass on the model
                    model: this.model,

                    params: this.params
                });
                break;

            case 'video':

                activityView = new VideoActivity({

                    // Pass on the model
                    model: this.model

                });

                break;

            case 'training': {
                activityView = this.wordsSessionId ?
                    new WordsSessionActivity({
                        sessionId: this.wordsSessionId,
                        model: this.model,
                    }) :
                    new WordsActivity({
                        model: this.model,
                        activityModel: this.model,
                        sessionId: this.wordsSessionId,
                    });
                break;
            }

            case 'competencies': {
                activityView = new CompetenciesActivity({
                    model: this.model,
                    params: this.params,
                });
                break;
            }

            case 'lti': {
                activityView = new LtiActivity({
                    model: this.model
                });
                break;
            }

            // When not one of the above types, it's probably a
            // linear type. So just render the linear activity.
            // A linea activty is an activity with a predefined
            // set of taskgroups trough which the student or teacher
            // can navigatie to make tasks
            default:

                // Set the activity holder to a new linear activity
                activityView = new LinearActivity({

                    // Make this view accessible with linear activity
                    activityShow: this,

                    // Pass on the model
                    model: this.model,

                    // Pass on the currentWorkOnPageId
                    currentWorkOnPageId
                });

        }

        // Register the activity as childview
        this.registerChildView(activityView);

        // Append the activity to this view
        activityView.appendTo(this.$el);

        // Check if the activity has a show method and that its a function
        if (typeof activityView.show === 'function') {

            // Defered call the show function of the activity
            // Bind the activity to the show to keep scope in line
            _.defer(_.bind(activityView.show, activityView));
        }

        // Check if the added activity has an hide method
        if (typeof activityView.hide === 'function') {

            // Do a hack to execute the hide method of the subview and the hide
            // method of this view. This is important to destroy all views nicely
            // while being able to do some stuff in the hide method of subviews
            // this is being used in adaptive activity and is a fix for issue:
            // LB5751
            //
            // Overrite the hide method with a custom one to obtain the
            // callback method
            this.hide = (callback) => {

                // Taking for granted that all hide methods will call the
                // callback with 'this' as the parameter we need to call
                // the hide method ourselfs, passing our own callback as
                // new callback function, hijacking the first argument
                // to be 'this' view instead of the activity view.
                // When 'this' view is being destroyed the activity will
                // be destroyed with it since it's a child view of 'this'
                return _.bind(activityView.hide, activityView)(_.partial(callback, this));
            }
        }

    }

    /**
    * This function will be called when the activity model is loaded
    *
    * @param  {Backbone.Model} model    The activity model as loaded
    * @param  {Object} response         Plain response from backend
    */
    onLoadActivity(model, response) {
        // If response does not give an error code, check if the activity
        // is available by checking for a valid license status.
        // If the activity is available, load the appropriate view for
        // that type of activity. If activity is not available due to it
        // for example not being paid for yet, show view for obtaining a
        // license instead.
        if (!response.err_code) {

            if (model.isAvailable()) {

                // If activity is hosted externally, navigate to the URL
                // external_url and stop further execution.
                if (response.external_url) {
                    document.location = response.external_url;
                    return;
                }

                // Load activity type appropriate activity view.
                this.onShowAndLoaded('loaded');

            } else {

                // Remove the spinner.
                this.removeSpinner();

                this.addChildView(new LicensePanel({model}), this.$el);

            }
        } else {
            this.onErrorLoadingActivity(model, response);
        }
    }

    /**
    * This function will be called when there went something wrong with
    * the loading of the activity. It will handle error codes if known, when
    * no error code it will throw a global error
    *
    * @param  {Backbone.Model} model    The activity model as loaded
    * @param  {Object} response         Plain response from backend
    */
    onErrorLoadingActivity(model, response) {
        // If the request for the activity data gets aborted (most likely due to navigation away from
        // the activity page), allow the request to fail silently.
        if (response.statusText === 'abort') {
            return
        }

        // Set default error message to an empty string
        var errorMessage = '';

        // Switch case the error codes
        switch (response.err_code) {

            case 23906:
                // student reaches activity with external url
                document.location = response.external_url;
                return;

            // Student is now allowed to view this activity.
            case 23902:
                errorMessage = window.i18n.gettext(
                    'You are not allowed to view this activity'
                );
                break;

            // Teacher is not the owner of this activity.
            case 23903:
                errorMessage = window.i18n.gettext(
                    'You are not the owner of this activity'
                );
                break;

            // User is not a teacher or a student allowed to view this activity.
            case 23904:
                errorMessage = window.i18n.gettext(
                    'You must be logged in as a teacher or student to view this activity'
                );
                break;

            // Activity does not exist
            case 23905:
                errorMessage = window.i18n.gettext(
                    'Activity does not exist'
                );

                break;

            // Exam is not open for student
            case 40404:

                Backbone.Model.user.unset('lockToUrl');
                break;

            // If none of the above, global error
            default:
                errorMessage = window.i18n.gettext(
                    'Something went wrong loading this activity'
                );

                // Check if there was an error code (but we didn't catch it), add
                // it to the error message string
                if (response.err_code) {
                    errorMessage += ' (error code ' + response.err_code + ')';
                }
                break;
        }

        if (errorMessage) {
            // Open a new status message, using the error message
            Backbone.View.layout.openStatus(errorMessage);
        }

        // If activity can't be loaded and current page starts with a string
        // equal to the user lockToURL. Let backend inform frontend with new
        // data on what to do.
        if (Backbone.history.getPath().indexOf(Backbone.Model.user.get('lockToURL')) === 0) {

            // Check status will inform if the user should or should not be in an exam
            window.app.checkStatus()
        } else {

            // Navigate back to the home page
            Backbone.history.navigate('users/home', {trigger: true});
        }
    }

    /**
    * Removed spinner view and spinner container element.
    */
    removeSpinner() {
        // Destroy and remove the spinner as child view
        this.unregisterAndDestroyChildView(this.spinner);

        // Remove the spinner holder
        this.$('.js-spinner-holder').remove();
    }

    /**
    * This function is triggered before the view is destroyed and unregistered from its parent.
    */
    beforeDestroy() {
        // Make sure the current op activity attribute is undefined
        delete Backbone.Model.user.currentOpenActivity;
    }

    scrollToAnswer(id) {
        const answerElement = this.el.querySelector(`[feedback-id='${id}']`)
        if (answerElement) {
            const answerPosition = answerElement.getBoundingClientRect().top
            const currentPosition = window.pageYOffset
            const stickyAnswerElement = this.el.querySelector('.js-correct-answer-holder')
            const stickyAnswerHeight = stickyAnswerElement ? stickyAnswerElement.offsetHeight : 0
            const newPosition = answerPosition + currentPosition - stickyAnswerHeight
            window.scrollTo(0, newPosition)
        }
    }

    showOrderForm() {
        this.destroyChildViewsOfInstance(LinearActivity)
        this.addChildView(new LicensePanel({model: this.model}), this.$el);
    }

    onDestroy() {
        this.activityModelXHR?.abort()
    }

}
