import Styles from './SideNavigationItem.scss';

import Template from './SideNavigationItem.hbs';
import Util from 'util/util'
import SideNavigationSubItemTemplate from 'views/pages/activities/show/types/linear/PresentationMode/SideNavigation/NavigationItem/SideNavigationSubItem.hbs'

export default class SideNavigationItem extends BaseView {

    get events() {
        return {
            'click .js-task-group-button': 'onClickSideNavButton',
            'click .js-element-button': 'onClickSubSideNavButton',
        }
    }

    initialize({ presentationMode }) {
        this.presentationMode = presentationMode

        this.setElement(Template({
            Styles,
            label: this.model.get('index'),
            id: this.model.id,
            title: this.model.getShortText(),
        }))

        let counter = 0
        for (const elementModel of this.model.elements) {
            const isTask = elementModel.get('element_type') === 'task'
            this.$('.js-sub-items').append(SideNavigationSubItemTemplate({
                Styles,
                label: isTask && Util.numToLetter(counter),
                icon: !isTask && elementModel.getElementIcon(),
                id: elementModel.get('element_type') + elementModel.id,
                title: elementModel.getShortText(),
            }))
            if (isTask) {
                counter++
            }
        }
    }

    onClickSideNavButton() {
        this.presentationMode.setActiveElement(
            this.model
        )
    }

    onClickSubSideNavButton(e) {
        this.presentationMode.setActiveElement(
            this.model,
            this.model.elements.get(e.currentTarget.dataset.elementId)
        )
    }
}
