import TaskGroupsCollection from 'collections/TaskGroupsCollection';
import ResponsesCollection from 'collections/ResponsesCollection';
import TasksCollection from 'collections/TasksCollection';
import ExamTaskGroupSetsCollection from 'collections/ExamTaskGroupSetsCollection';
import Util from 'util/util';
import TimeLogModel from 'models/TimeLogModel';
import DeadlineModel from './DeadlineModel';
import CompetencyModel from './CompetencyModel';
import LtiModel from 'models/LtiModel';
import ContentLabelModel from './ContentLabelModel'
import ElementsCollection from 'collections/ElementsCollection'

export default class ActivityModel extends Backbone.Model {

    preinitialize() {
        this.constructor.type = 'activity'
        this.constructor.pluralType = 'activities'
    }

    initialize(attr, options = {}) {

        this.task_groups = new TaskGroupsCollection();
        this.task_groups.parent_id = this.id;

        this.set({
            index: Util.numToLetter(parseInt(this.get('sequence'), 10)),
            type: this.get('type') || 'linear'
        });

        this.simple = options.simple;

        this.on('change:sequence', _.debounce(this.setIndex))
    }

    /**
     * Find values to sort by. Do NOT put this in initialize(). Lazy call
     * this function when teacher is in a progress view
     */
    findSortingValues() {
        const sortingSettings = Backbone.Model.user.getSetting('sortProgressBy')

        if (sortingSettings) {
            const [ sortProgressBy, orderProgressBy ] = sortingSettings.split(':')

            this.set({
                sortProgressBy,
                orderProgressBy,
            })
        }
    }

    // The time log is used to measure how long a student is working on a task group
    // This function stops the time log if present and sends it to the backend
    stopTimeLog() {

        // If no timeLog is present, do nothing
        if (!this.timeLog) {
            return
        }

        // Stop the time log and send it to the backend
        this.timeLog.stopAndSave()

        // Unset the time log reference to prevent duplicate saving
        delete this.timeLog

    }

    // The time log is used to measure how long a student is working on a task group
    // This function starts the time log for the given task group
    // When a time log is running, you need to stop it before starting a new one
    startTimeLog(newTaskGroup) {

        if (!ACL.isAllowed(ACL.resources.RESPONSES, ACL.actions.ADD)) {
            return
        }

        // In exams, do not count the time reviewing the exam
        if (this.isExam() && this.get('show_answers')) {
            return
        }

        // Only start a time log if there isn't one yet
        if (this.timeLog) {
            return
        }

        // If the page is a taskgroup (not null or the start/end page), create new time log
        if (newTaskGroup && newTaskGroup.constructor.type === 'taskGroup') {

            this.timeLog = new TimeLogModel()
            this.timeLog.start({
                userId: Backbone.Model.user.id,
                activityId: this.id,
                taskGroupId: newTaskGroup.id
            })

        }

    }

    parse(response, options) {
        if (options.patch) {
            return;
        }

        if (response.status === 'error') {
            return
        }

        this.task_groups = new TaskGroupsCollection(response.TaskGroup, {parse: true})
        this.task_groups.activity_id = response.id;
        this.elements = new ElementsCollection()
        this.tasks = new TasksCollection();

        this.task_groups.each((taskGroupModel) => {
            // Set child-parent relation
            taskGroupModel.activityModel = this;
            taskGroupModel.set({
                parent_id: this.id,
                // Use introduction text as the name of the task group
                name: taskGroupModel.getShortText()
            });

            // Set child-parent relation and filter all tasks of the activity.
            taskGroupModel.elements.each((element) => {
                element.set({task_group_id: taskGroupModel.id});
                if (element.get('element_type') === 'task') {
                    this.tasks.push(element)
                }
                this.elements.push(element)
            });
        });

        this.responses = new ResponsesCollection(response.Response);
        this.responses_original = new ResponsesCollection(response.ResponseOriginal);

        this.responses.invoke('set', 'saved_state', 'server');

        this.durations = new Backbone.Collection(response.Duration);

        this.feedback = response.Feedback || [];

        this.section = Backbone.Collection.sections.get(response.section_id);

        // Map the metadata as object from the backend to a backbone model
        response.metadata = new Backbone.Model(response.Metadata)

        // Set default type to linear.
        response.type = response.type || 'linear';

        response.index = Util.numToLetter(response.sequence);

        // Add generated exam data
        // TODO filter responses per student with tasks.
        response.generatedExams = new Backbone.Collection(response.GeneratedExams)
        response.generatedExamTaskGroupSets = new ExamTaskGroupSetsCollection(
            response.ExamSets,
            {activity_id: response.id}
        )

        /**
         * Adaptive activities get an extra property Progress
         * from the backend. Progress is calculated
         * differently from linear and exam activities
         */
        if (response.Progress) {
            this.adaptiveProgress = new Backbone.Collection(response.Progress)
        }

        if (response.type === 'competencies' && response.competency) {
            response.competency = new CompetencyModel({
                id: response.competency.id,
                criteria: response.competency.criteria,
                components: response.competency.components,
                passing_score: response.competency.passing_score
            })
        }

        if (response.type === 'lti' && response.lti) {
            response.lti = new LtiModel(
                response.lti
            )
        }

        this.deadline = new DeadlineModel({
            activityId: response.id,
            hasDeadline: parseInt(response.Metadata?.has_deadline) === 1,
            deadline: response.Deadlines?.deadline,
            exceptions: response.Deadlines?.exceptions || []
        })

        delete response.Metadata;
        delete response.TaskGroup;
        delete response.Response;
        delete response.ResponseOriginal
        delete response.Duration
        delete response.Feedback
        delete response.GeneratedExams
        delete response.ExamSets
        delete response.Progress
        delete response.scheduled_exam
        delete response.Deadlines

        // bind currentOpenActivity to UserModel to make syncing responses easier
        Backbone.Model.user.currentOpenActivity = this;

        return response;
    }

    onCompletedCopyActivity(response) {
        if (response.status === 'success') {
            Backbone.View.layout.openStatus(window.i18n.gettext('Activity has been copied'));
        }
    }

    setIndex() {
        this.set({ index: Util.numToLetter(this.get('sequence')) })
    }

    // Only show a copy button if all task groups in the activity belong to one of the following subsets:
    // - Current user is the author
    // - Other teacher in the group is the author and collaboration is on
    // - Task group is explicitly marked as 'cloneable'
    hasCopyButton() {
        const copyable = this.task_groups.canBeCopied()
        if (!copyable) {
            return false
        }

        return copyable
    }

    url() {
        if (APPLICATION === 'author') {
            return '/activities/author' + (this.id ? '/' + this.id : '') + '.json';
        }
        if (this.isPreview && this.publishedContentId) {
            return '/activities/preview/' + this.id + '/' + this.publishedContentId + '.json';
        }

        if (this.id) {
            if (this.simple) {
                return '/activities/get_simple_activity/' + this.id + '.json';
            }
            return '/activities/backbone_backend/' + this.id + '.json';
        }
        return '/activities/backbone_backend.json';
    }

    /**
     * getParent
     *
     * Return parent model.
     *
     * @returns {SectionModel}          parent section
     */
    getParent() {
        return this.getSectionModel();
    }

    /**
    * getChildren
    *
    * Return child collection in the order of content.
    *
    * @return {TaskGroupsCollection}    task groups connected to this activity.
    */
    getChildren() {
        return this.task_groups;
    }

    /**
    * getSiblings
    *
    * Get collection of sibling models.
    *
    * @return {ActivitiesCollection}    All activities with the same parent section.
    */
    getSiblings() {
        return this.getParent().activities;
    }

    getSectionModel() {
        return Backbone.Collection.sections.get(this.get('section_id'))
    }

    getChapterModel() {
        return Backbone.Collection.chapters.get(this.getSectionModel()?.get('chapter_id'))
    }

    getGroupModel() {
        return Backbone.Collection.groups.get(this.getChapterModel()?.get('group_id'))
    }

    getAllCrumblepathModels() {
        const groupModel = this.getGroupModel()
        const crumblepathModels = []
        crumblepathModels.push(groupModel.getCrumblepathModel())

        if (groupModel.get('layers') > 2) {
            crumblepathModels.push(this.getChapterModel().getCrumblepathModel())
        }

        if (groupModel.get('layers') > 1) {
            crumblepathModels.push(this.getSectionModel().getCrumblepathModel())
        }

        crumblepathModels.push(this.getCrumblepathModel())

        return crumblepathModels
    }

    getCrumblepathModel() {

        // Create a model object for the crumblepath
        var modelObject = {

            // If the activity is standalone don't set an index
            index: this.has('standalone') || ContentLabelModel.isNumberless(this) ? '' : this.get('index'),
            label: this.get('name'),
            level: 'activities',
            path_id: this.id,
        };

        // Check if this activity model is of the type adaptive student
        if (this.get('type') === 'adaptive_student') {

            modelObject.type = this.get('type');
            modelObject.label = window.i18n.gettext('Practice by yourself');
        }

        // Check if this activity model is of the type lti
        if (this.get('type') === 'lti') {
            modelObject.type = this.get('type');
        }

        // If the activity is a standalone activity
        if (this.has('standalone')) {

            // Set the type to standalone
            modelObject.type = 'standalone';
        }

        // Return the crumblepath model
        return new Backbone.Model(modelObject);
    }

    getSourceId() {
        return this.get('source_activity_id')
    }

    savePatchWithKeyToServer(key, value, callback, options = {}) {
        return new Promise((resolve) => {

            const payload = {
                [key]: value,
            }

            this.save(
                payload,
                {
                    patch: true,
                    success(model, response) {
                        if (callback) {
                            callback()
                        }

                        resolve(response?.status)
                    },
                    error(error) {
                        window.sentry.withScope(scope => {
                            scope.setExtra('errorData', error)
                            scope.setExtra('objectToSend', payload)
                            window.sentry.captureMessage('Error trying to patch from Activity Model')
                        });

                    },
                    ...options
                }
            );
        })

    }

    getAboutActivity() {
        return this.task_groups.getAboutTaskGroupCollection()
    }

    /**
     * getActivityTypeLabel
     *
     * Get translated name of activity type.
     *
     * @param {String} type     activity type
     *
     * @returns {String}        translated activity type label
     */
    getActivityTypeLabel() {
        // Fixme: When we have more providers we should have a global providers collection from which we get the name
        if (this.get('type') === 'lti') {
            return 'ZorgPad Pro';
        }
        return ActivityModel.getActivityTypeLabel(this.get('type'))
    }

    static getActivityTypeLabel(type) {
        switch (type) {
            case 'exam': return window.i18n.gettext('Exam');
            case 'diagnostic_exam': return window.i18n.gettext('Diagnostic exam')
            case 'generated_exam':
                // Students do not need to see the difference between exam and generated exam
                if (Backbone.Model.user.get('is_student')) {
                    return window.i18n.gettext('Exam')
                }
                return window.i18n.gettext('Generated exam')
            case 'adaptive_student':
            case 'adaptive': return window.i18n.gettext('Adaptive');
            case 'presentation': return window.i18n.gettext('Presentation / Quiz');
            case 'video': return window.i18n.gettext('Video');
            case 'training': return window.i18n.gettext('Little words');
            case 'competencies': return window.i18n.gettext('Competencies');
            case 'lti': return window.i18n.gettext('LTI connection');
            default: return window.i18n.gettext('Standard');
        }
    }

    /**
    * getActivityTypeIcon
    *
    * Get icon of activity type.
    *
    * @param {String} type     activity type
    *
    * @returns {String}        icon name string for activity type
    */
    getActivityTypeIcon() {
        return ActivityModel.getActivityTypeIcon(this.get('type'))
    }

    static getActivityTypeIcon(type) {
        switch (type) {
            case 'exam':
            case 'diagnostic_exam':
            case 'generated_exam': return 'school'
            case 'adaptive_student':
            case 'adaptive': return 'exercises'
            case 'presentation': return 'presentation'
            case 'video': return 'video'
            case 'training': return 'words-menu'
            case 'competencies': return 'competencies'
            case 'lti': return 'external-link'
            default: return 'show'
        }
    }

    /**
     * getActivityTypeDescription
     *
     * Get short description of activity type.
     *
     * @param {String} type     activity type
     *
     * @returns {String}         Short description of activity type
     */
    getActivityTypeDescription() {
        return ActivityModel.getActivityTypeDescription(this.get('type'))
    }

    static getActivityTypeDescription(type) {
        switch (type) {
            case 'exam': return window.i18n.gettext('Take an exam and monitor the students')
            case 'diagnostic_exam': return window.i18n.gettext('Take a diagnostic exam that students take at their own pace')
            case 'generated_exam': return window.i18n.gettext('Take an exam with a varying selection of exercises for each student')
            case 'adaptive_student': return window.i18n.gettext('Let students practice with questions selected by Learnbeat at the level of the student')
            case 'adaptive': return window.i18n.gettext('Let students practice with questions selected by Learnbeat at the level of the student')
            case 'presentation': return window.i18n.gettext('Give an interactive presentation with explanation and questions')
            case 'video': return window.i18n.gettext('Play a video on full screen')
            case 'training': return window.i18n.gettext('Let students practice with words or definitions')
            case 'competencies': return window.i18n.gettext('Assess students on specific skills')
            case 'lti': return window.i18n.gettext('Connect an external LTI activity to Learnbeat')
            case 'linear': return window.i18n.gettext('Have students work on exercises')
            default: return ''
        }
    }

    /**
     * Simple helper to check if an activity is a exam type, that being either a normal exam 'exam', a generated
     * exan 'generated_exam' or a diagnostic exam 'diagnostic_exam'.
     *
     * @param {String} type     activity type
     * @returns {Boolean}       true if activity type is any kind of exam.
     */
    isExam(type = this.get('type')) {
        return /exam$/.test(type)
    }

    /**
     * isAvailable
     *
     * Get if activity is available by checking if it has an active or unknown
     * license state or no license at all. Possible values are:
     * - needs_payment
     * - is_revoked
     * - is_expired
     * - active
     * - unknown
     *
     * @returns {boolean} activity availability
     */
    isAvailable() {
        return this.get('status') === 'active' || this.get('status') === 'unknown' || !this.get('status')
    }

    /**
     * getAttainableExamPoints
     *
     * Get the maximum attainable amount of exam points for the given task.
     * Only works for tasks within an exam activity.
     *
     * @param  {number} taskID  task identifier
     * @return {number}         exam points for task
     */
    getAttainableExamPoints(taskID) {
        return this.get('ExamPoint')[taskID]
    }

    /**
     * setAttainableExamPoints
     *
     * Set the amount of attainable exam points for a given task.
     * Convert to an absolute number. If NaN, use 0 points instead.
     * Round this amount to a half point, patch it to the server and
     * trigger a change event.
     *
     * @param  {number} taskID  task identifier
     * @param  {number|string} points  points for task
     */
    setAttainableExamPoints(taskID, points) {
        if (_.isString(points)) {
            points = points.replace(/,/, '.')
        }
        points = Math.abs(points) || 0
        if (this.getAttainableExamPoints(taskID) !== points) {
            points = Math.round(points * 2) / 2;
            this.get('ExamPoint')[taskID] = points;
            $.post(
                '/activities/set_exam_points/' + this.id + '/' + taskID + '.json',
                {points},
                () => {
                    this.trigger('ExamPointChanged');
                }
            );
        }
    }

    /**
     * getExamPointsFromScore
     *
     * Converts score to equivalent amount of exam points rounded to a half point.
     * Scores of -1 are converted to 0 exam points.
     *
     * @param  {number} taskID  task identifier
     * @param  {number} score   task score
     * @return {number}         exam points rounded by a half point
     */
    getExamPointsFromScore(taskID, score) {
        return score === -1 ? -1 : Math.round((this.getAttainableExamPoints(taskID) * score) * 2) / 2;
    }

    /**
     * getMetadata
     *
     * Get activity metadata value
     *
     * @param  {string} key     keyword
     * @return {*}              value
     */
    getMetadata(key) {
        var value;
        if (this.get('metadata') instanceof Backbone.Model) {
            value = this.get('metadata').get(key);
        }
        return value;
    }

    /**
     * setMetadata
     *
     * Set and save activity metadata. This is for data that is not activity
     * type agnostic. Meaning that this is a place for storing data that is
     * relevant to only a particular type of activity and not others. You can
     * find this data in the activities_metadata table in the database.
     *
     * @param  {string} key             keyword
     * @param  {*} value                value
     * @param  {boolean} saveToBackend  whether to send the metadata to the backend or not
     */
    setMetadata(key, value, saveToBackend = true) {

        // If value is a boolean type, cast it to a 0 (= false) or 1 (= true).
        // This way the value won't be saved as string ("false"/"true"), which
        // is more cumbersome to cask back to a proper boolean.
        if (_.isBoolean(value)) {
            value = value | 0
        }

        // Check if value is different from what is already assigned to the key to prevent unnecessary post calls.
        if (this.get('metadata') && this.get('metadata').get(key) !== value) {

            // Update value in model.
            if (value !== undefined) {
                this.get('metadata').set(key, value);
            } else {
                this.get('metadata').unset(key);
            }

            // Add/Update metadata entry for the current activity.
            if (saveToBackend) {
                $.post(
                    '/activities/set_meta_data/' + this.id + '.json',
                    {
                        keyword: key,
                        value
                    }
                );
            }

            // Trigger change event.
            this.trigger('change_metadata:' + key);
        }

    }

    isSEBExam() {
        return !!parseInt(this.getMetadata('exam_safe_exam_browser_enforced'))
    }

    unsetMetadata(key) {
        this.setMetadata(key, undefined);
    }

    toggleMetadata(key) {
        this.getMetadata(key) ?
            this.unsetMetadata(key) :
            this.setMetadata(key, 1)
    }

    /**
     * getGeneratedExamTaskGroups
     *
     * For use for activity of type 'generated_exam', where the composition and order of task group may be different
     * for every student. For the viewing of progress only the task groups that where served to this student should
     * be shown.
     *
     * @param {Number} studentId        student who this exam is generated for.
     * @returns {TaskGroupsCollection}  task group collection with just the task groups for this student.
     */
    getGeneratedExamTaskGroups(studentId) {
        // If studentId is 0, return all taskgroups in the group instead.
        if (studentId === 0) {
            return this.task_groups
        }
        // Find list of generated exam tasks groups id and generate a new TaskGroupsCollection with just the task
        // the task groups for this particular generated exam. Returns an empty collection if no exam has been
        // generated for the particular student or exam activity does not have any task groups.
        const generatedExamStudent = this.getGeneratedExamByStudent(studentId)

        const tasks = generatedExamStudent && generatedExamStudent.get('exam').reduce((m, taskGroupId) => {
            const taskGroup = this.task_groups.get(taskGroupId)
            /**
             * Filter out tasks that are not related this generated exam (anymore).
             */
            if (taskGroup) {
                m.push(taskGroup)
            }
            return m
        }, [])
        const generatedExamTaskGroupsCollection = new TaskGroupsCollection(tasks, {parse: true})
        generatedExamTaskGroupsCollection.activity_id = this.id
        return generatedExamTaskGroupsCollection
    }

    /**
     * For the activity of type 'generated_exam', get the list of task group ids served to a specific student.
     *
     * @param {Number} studentId        student who this exam is generated for.
     * @returns {Backbone.Model}        Simple model containing the user_id and list of task groups ids.
     */
    getGeneratedExamByStudent(studentId) {
        return this.get('generatedExams').findWhere({user_id: studentId})
    }

    // Returns true if the user has an expired license for this activity
    hasLicenseExpired() {
        return this.get('status') === 'is_expired';
    }

    getTaskGroups(studentId) {
        return this.get('type') === 'generated_exam' ?
            this.getGeneratedExamTaskGroups(studentId) :
            this.task_groups
    }

    sortByFirstName(students) {

        return students.sort((studentA, studentB) => {
            return (
                Backbone.Collection.students.get(studentA.get('id')).first_name_last_name() || ''
            ).localeCompare(
                Backbone.Collection.students.get(studentB.get('id')).first_name_last_name(),
                window.app_version.language,
                {
                    sensitivity: 'base',
                }
            )
        })

    }

    // Get the background image of the activity, or the chapter, or the course
    getBackgroundImage() {
        return this.get('background_image') || this.getChapterModel()?.getBackgroundImage()
    }

    // Get a random header background image based on the course we are in
    getHeaderImage() {

        // Make sure we get the same image when calling this function repeatedly
        if (!this.headerImage) {
            this.headerImage = this.getGroupModel()?.getHeaderImage()
        }

        return this.headerImage
    }

    // Check if this is an exam activity without task groups
    isExamWithoutTaskgroups() {
        if (this.get('type') === 'exam' || this.get('type') === 'diagnostic_exam') {
            return this.task_groups.length === 0
        }

        if (this.get('type') === 'generated_exam') {
            for (const taskGroup of this.task_groups) {
                if (taskGroup.get('generated_exam_set_id') > 0) {
                    return false
                }
            }
            return true
        }

        return false
    }

    /**
     *@param {boolean} withUngradedCount prepend text with ungraded count
    * @returns {string | Object} e.g. "2 answers not yet graded" or e.g. "answer not yet graded"
    */
    getUngradedAnswersText(withUngradedCount = true) {
        const ungradedAnswersCount = this.getUngradedAnswersCount()

        let text =
            '<span>' +
            window.i18n.ngettext(
                'answer not yet graded',
                'answers not yet graded',
                ungradedAnswersCount
            ) +
            '</span>'

        if (withUngradedCount) {
            text = '<strong>' + ungradedAnswersCount + '</strong> ' + text
        }

        return text
    }

    /**
     * Get count of ungraded answers.
     *
     * @returns {number} ungraded answers count
     */
    getUngradedAnswersCount() {
        if (this.get('count_tasks')) {
            return parseInt(this.get('made')) - parseInt(this.get('checked'))
        }

        // Else calculate ungraded tasks based on responses
        return this.responses.reduce((unchecked, response) => {
            if (
                response.get('user_id') === Backbone.Model.user.id &&
                response.has('json_answer') &&
                response.get('score') === -1
            ) {
                unchecked++
            }
            return unchecked
        }, 0)
    }

    /**
     * can never be a root layer
     * @returns {boolean} false
     */
    isRootLayer() {
        return false
    }

    getPath(type, withoutId = false) {
        const base = `/activities/${type}`

        if (withoutId) {
            return base
        }
        return `${base}/${this.id}`
    }

}
