import Styles from './Menubar.scss';

import Template from './Menubar.hbs';
import MenubarItem from './menubarItem/MenubarItem';
import FlyoutTemplate from './partials/flyout.hbs';

export default class Menubar extends BaseView {

    get events() {
        return {
            'click .js-toggle-menu': 'toggleMenu',
            'click .js-feedback-button': 'onClickSupportButton',
            'click .js-menu-more': 'onClickMoreButton',
            'click .js-topbar': 'onClickTopbar',
            'click .js-group-button': 'onClickGroupButton'
        }
    }

    get defaultButtons() {
        return [{
            type: 'home',
            title: Backbone.Model.user.get('is_student')
                ? window.i18n.gettext('My courses')
                : window.i18n.gettext('My groups'),
            icon: 'home',
            url: '/users/home',
            isMainItem: true,
            condition: ACL.isAllowed(ACL.resources.HOME, ACL.actions.VIEW)
        }, {
            type: 'connections',
            title: window.i18n.gettext('Students'),
            icon: 'home',
            url: '/accessor/connections',
            isMainItem: true,
            condition: ACL.isAllowed(ACL.resources.ACCESSOR, ACL.actions.VIEW)
        }, {
            type: 'show',
            title: window.i18n.gettext('Learning materials'),
            icon: 'show',
            url: '/groups/show',
            isMainItem: true,
            condition: ACL.isAllowed(ACL.resources.GROUPS, ACL.actions.VIEW)
        }, {
            type: 'planner',
            title: window.i18n.gettext('Study planner'),
            icon: 'planner',
            url: '/groups/planner',
            isMainItem: true,
            condition: ACL.isAllowed(ACL.resources.PLANNER, ACL.actions.VIEW)
        }, {
            type: 'progress',
            title: window.i18n.gettext('Progress'),
            icon: 'progress',
            url: '/groups/progress',
            isMainItem: true,
            condition: ACL.isAllowed(ACL.resources.PROGRESS, ACL.actions.VIEW)
        }, {
            type: 'results',
            title: window.i18n.gettext('Results'),
            icon: 'results',
            url: Backbone.Model.user.get('is_student')
                ? '/students/results'
                : '/groups/results',
            isMainItem: true,
            condition: ACL.isAllowed(ACL.resources.RESULTS, ACL.actions.VIEW, {type: 'users'})
                || ACL.isAllowed(ACL.resources.RESULTS, ACL.actions.VIEW, {type: 'groups'})
        }, {
            type: 'author',
            title: window.i18n.gettext('Author'),
            icon: 'author',
            url: '/groups/author',
            isMainItem: true,
            condition: ACL.isAllowed(ACL.resources.AUTHOR, ACL.actions.VIEW)
        }, {
            type: 'library',
            title: window.i18n.gettext('Library'),
            icon: 'library',
            url: '/library/home',
            isMainItem: true,
            condition: ACL.isAllowed(ACL.resources.LIBRARY, ACL.actions.VIEW)
        }, {
            type: 'words',
            title: window.i18n.gettext('Little words'),
            icon: 'words-menu',
            url: '/words/home',
            isMainItem: true,
            condition: ACL.isAllowed(ACL.resources.WORDS, ACL.actions.VIEW)
        }, {
            type: 'feedback',
            title: window.i18n.gettext('Feedback'),
            icon: 'feedback',
            url: '/users/feedback',
            isMainItem: Backbone.Model.user.get('exam_product') || ISMOBILE,
            condition: ACL.isAllowed(ACL.resources.FEEDBACK, ACL.actions.VIEW, {type: 'list'})
        }, {
            type: 'annotate',
            title: window.i18n.gettext('Annotations'),
            icon: 'annotations',
            url: '/groups/annotate',
            isMainItem: ISMOBILE,
            condition: ACL.isAllowed(ACL.resources.ANNOTATIONS, ACL.actions.VIEW)
        }, {
            type: 'portfolio',
            title: window.i18n.gettext('Portfolio'),
            icon: 'portfolio',
            url: Backbone.Model.user.get('is_student')
                ? '/portfolio/projects'
                : '/portfolio/students',
            isMainItem: ISMOBILE,
            condition: ACL.isAllowed(ACL.resources.PORTFOLIO, ACL.actions.VIEW, {type: 'projectList'})
                || ACL.isAllowed(ACL.resources.PORTFOLIO, ACL.actions.VIEW, {type: 'studentList'})
        }]
    }

    initialize() {

        _.bindAll(
            this,
            'setLabel',
            'closeMenu',
            'toggleFlyout',
            'onClickTopbar',
            'resizeMenu'
        )

        this.setElement(Template({
            Styles
        }));

        this.attachButtons(
            '.js-default-menu',
            this.defaultButtons
        );

        let settingsLabel = window.i18n.gettext('Settings');

        if (!ISMOBILE && Backbone.Model.user.get('is_student')) {
            settingsLabel = Backbone.Model.user.get('first_name');
        }

        if (!ISMOBILE && Backbone.Model.user.get('is_teacher')) {
            settingsLabel = `${Backbone.Model.user.get('prefix')} ${Backbone.Model.user.get('sortable_last_name')}`
        }

        this.attachButtons(
            '.js-lower-menu',
            [{
                type: 'support',
                title: window.i18n.gettext('Support'),
                icon: 'support',
                isMainItem: true,
                condition: ACL.isAllowed(ACL.resources.SUPPORT, ACL.actions.ADD)
            }, {
                type: 'settings',
                title: settingsLabel,
                url: '/users/settings',
                avatar: true,
                isMainItem: true,
                condition: ACL.isAllowed(ACL.resources.SETTINGS, ACL.actions.VIEW)
            }, {
                type: 'logout',
                title: window.i18n.gettext('Logout'),
                icon: 'off',
                isMainItem: true,
                condition: ISMOBILE
                    || !ACL.isAllowed(ACL.resources.SETTINGS, ACL.actions.VIEW)
            }]
        );

        // If the user has only 1 group, we can preselect it
        if (Backbone.Collection.groups?.length === 1) {
            this.setGroup(Backbone.Collection.groups.at(0))
        } else {
            this.setGroup()
        }

        // When the width of body changes, resize the menu when needed
        window.addEventListener('resize', _.throttle(this.resizeMenu, 100))
        this.resizeMenu()
    }

    attachButtons(selector, buttons) {

        if (selector === '.js-default-menu'
            && Backbone.Model.user.get('exam_product')
        ) {
            /**
             * Function to change the value position of an item in an array.
             *
             * @param {Array} arr    Array that we should move items in
             * @param {int}   init   Index of the item that we want to move
             * @param {int}   target Index of where the item should move to
             *
             * @returns {Array} with modified indices
             */
            const changeValuePosition = (arr, init, target) => {
                [arr[init], arr[target]] = [arr[target], arr[init]];
                return arr
            };

            // Because we want to move the feedback button after the progress index,
            // we first should get the feedback index to use within our
            // changeValuePosition function. Then we should get the index of
            // the progress button so we know where we should move the feedback button
            // to
            const feedbackIndex = _.findIndex(buttons, { type: 'feedback' });
            const progressIndex = _.findIndex(buttons, { type: 'progress' });

            // Call changeValuePosition to move the feedback button after the progress button
            buttons = changeValuePosition(buttons, feedbackIndex, progressIndex + 1);
        }

        if (selector === '.js-default-menu' &&
            Backbone.Model.user.get('is_student') &&
            ISMOBILE
        ) {
            // In the mobile version of Learnbeat, words should be at the
            // bottom of the menubar. To accomplish this, we first need to
            // find its index in de current buttons array.
            const wordsIndex = _.findIndex(buttons, { type: 'words' });

            // Use the previously gotton index to remove the button from
            // the buttons array, and push it to it to "append" it.
            // the button will now be rendered as the last button
            buttons.push(...buttons.splice(wordsIndex, 1));
        }

        buttons.forEach((button) => {

            if (button.condition === undefined || button.condition) {

                // Check if the button is not a main item. This determines whether
                // the button should be in the main menubar or hidden under the
                // "flyout" which is the popup shown after clicking on 'more'.
                // If there is a non-main item, and flyout doesn't exist yet,
                // we'll create it here. We do this check to prevent adding the more
                // button when there aren't any items that are in the flyout.
                if (!button.isMainItem && !this.flyout) {
                    this.addChildView(
                        new MenubarItem({
                            type: 'more',
                            title: window.i18n.gettext('More'),
                            icon: 'apps',
                            menubar: this,
                            isMainItem: true
                        }),
                        selector
                    );

                    this.flyout = $(FlyoutTemplate({ Styles }));
                    this.$(selector).append(this.flyout);
                }

                this.addChildView(
                    new MenubarItem({ ...button, menubar: this }),
                    button.isMainItem
                        ? selector
                        : this.flyout
                );
            }
        });
    }

    toggleFlyout(e) {
        if (!this.flyout) {
            return;
        }

        if (e) {
            this.stopAllEvents(e);
        }

        if (this.flyout.hasClass(Styles['is-visible'])) {
            this.flyout.removeClass(Styles['is-visible']);
            $(document).off('click', this.toggleFlyout)
        } else {
            this.flyout.addClass(Styles['is-visible']);
            $(document).one('click', this.toggleFlyout)
        }
    }

    // Add the number of unread messages to the feedback item
    setUnreadFeedback(numberOfUnreadFeedback) {

        for (const menuItem of this.getChildViewsOfInstance(MenubarItem)) {
            // We should only set a badge to the 'more' and 'feedback' menu items.
            // so if the type isn't one of those. Continue
            if (!['more', 'feedback'].includes(menuItem.type)) {
                continue;
            }

            menuItem.setBadge(numberOfUnreadFeedback)
        }
    }

    // Highlight the correct menu item when the url changes
    onExternalChangeLinkItem(controller, action) {
        this.trigger('changedLink', { controller, action });
    }

    /**
     * setLabel
     *
     * This function will add a label to the menubar, given by the crumblepath.
     *
     * @param  {string} label - String containing the current location inside the app
     */
    setLabel(label) {
        this.el.querySelector('.js-menu-label').textContent = label;
    }

    onClickTopbar(event) {
        // If the event isn't on the primary navigation (to prevent closing when clicking an item)
        // And we're on mobile (because when not, we don't have the overlay)
        // And the menubar is open (because else we don't have to close it)
        // we'll close the menu
        if (event.target !== this.el.querySelector('.js-primary-navigation')
            && ISMOBILE
            && this.el.classList.contains(Styles['is-open'])
        ) {
            this.closeMenu();
        }
    }

    onClickGroupButton() {
        Backbone.View.layout.openGroupChooser(this.selectedGroupAction)
    }

    // Show the currently selected group in the menu bar
    // The 'action' parameter should be something like 'progress', 'author' or 'planner'. It sets the url
    // of the group chooser to the format '/groups/progress/[groupId]'.
    setGroup(groupModel, action = 'show') {
        this.selectedGroup = groupModel
        this.selectedGroupAction = action
        let label = Backbone.Model.user.get('is_student') ?
            window.i18n.gettext('No course selected') :
            window.i18n.gettext('No group selected')
        if (groupModel) {
            label = groupModel.getCrumblepathModel().get('label')
        }
        this.el.querySelector('.js-group-label').textContent = label
        this.el.querySelector('.js-group-button').setAttribute('title', label)
    }

    getGroup() {
        return this.selectedGroup
    }

    toggleMenu(e) {
        this.stopAllEvents(e);
        this.el.classList.toggle(Styles['is-open']);

        const stateIsOpen = this.el.classList.contains(Styles['is-open'])
        this.el.toggleAttribute('data-disable-scrolling', stateIsOpen)
    }

    closeMenu() {
        this.el.classList.remove(Styles['is-open']);
        this.el.removeAttribute('data-disable-scrolling')
    }

    // Set the preferred width of the menu (that is: the width if the screen size allows it)
    // Valid sizes are:
    // 'small' (menu is not important on this page, the labels can be hidden to make space)
    // 'large' (menu is important on the page, keep showing the labels even on smaller screens)
    // empty string (no preference, hide the labels only when this is really needed due to screen size)
    setPreferredSize(size = '') {
        this.preferredSize = size
        this.resizeMenu()
    }

    resizeMenu() {
        if (this.preferredSize === 'small' ||
            this.preferredSize !== 'large' && window.innerWidth < 1024)
        {
            this.el.classList.add('small-menubar')
        } else {
            this.el.classList.remove('small-menubar')
        }
    }

    onDestroy() {
        $(document).off('click', this.toggleFlyout)
    }
}
