<script>
    import Spinner from 'views/components/spinner/Spinner.svelte';
    import SectionSlot from './sectionSlot/SectionSlot.svelte';
    import StatusCard from 'views/components/statusCard/StatusCard.svelte';
    import { onMount } from 'svelte';
    import HeroButton from 'views/components/heroButton/HeroButton';
    import SetVisibility from 'views/components/modals/setVisibility/SetVisibility';

    export let params

    const model = Backbone.Collection.chapters.get(parseInt(params[0]))

    Backbone.View.header.clearButtons()
    Backbone.View.header.setTitle(model.getName('show'))
    Backbone.View.header.setCrumblepath(
        model.getAllCrumblepathModels(),
        'show'
    )

    let isLoading = true
    jQuery.post({
        url: '/chapters/get_sections_progress.json',
        data: { chapter_ids: [model.get('id')] },
        success: (response) => {
            for (const sectionProgress of response[0].progress || []) {
                model.sections.get(sectionProgress.section_id)?.set({
                    progress: sectionProgress.progress
                })
            }
        },
        error: (response) => {
            response.failSilently = true
        },
        complete: () => {
            isLoading = false
        }
    })

    if (Backbone.Model.user.get('is_teacher')) {
        Backbone.View.header.addButton(
            new HeroButton({
                firstLine: window.i18n.gettext('Set visibility'),
                icon: 'eye',
                callback: () => {
                    Backbone.View.Components.modal.open(SetVisibility, {
                        title: window.i18n.gettext('Set visibility'),
                        model
                    })
                }
            })
        )
    }

    onMount(() => {
        Backbone.View.header?.setBackgroundImage(model)

        return () => {
            Backbone.View.header?.removeBackgroundImage()
        }
    })
</script>

<div class="center-panel container">
    {#if isLoading}
        <div class="spinner">
            <Spinner></Spinner>
        </div>
    {:else}

        {#each model.sections.models as sectionModel}
            <SectionSlot model={sectionModel}></SectionSlot>
        {:else}
            <StatusCard icon="info-circle">
                {window.i18n.gettext('This chapter doesn\'t have any content yet.')}
            </StatusCard>
        {/each}

    {/if}
</div>

<style lang="scss">
    .container {
        margin-bottom: 48px;
    }

    .spinner {
        margin-top: 48px;
        color: $line-gray;
    }
</style>
