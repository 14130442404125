<script>
    import {tick} from 'svelte'
    import InputUtil from 'util/InputUtil'
    import Util from 'util/util'

    // Optional initial value of text area.
    export let value = ''

    // Optional placeholder value.
    export let placeholder = ''

    // Whether the textarea should claim focus after it has been initialized.
    export let autoFocus = false

    // Optional callback function to call for paste events. By default, we trim whitespace
    export let pasteCallback = (event) => {
        event.preventDefault()
        const text = event.clipboardData.getData('text').trim()
        document.execCommand('insertText', false, text)
    }

    // Optional callback function to call for input events.
    export let inputCallback = null

    // Whether to skip content sanitization when calling .getValue() (WARNING: USE WITH CARE!)
    export let skipValidation = false

    // If enabled, user can insert \t characters without it moving focus away from the textarea element.
    export let enableInsertTabs = false

    // If enabled, user can use key shortcuts to make selected text bold, italicized, underlined,
    // sub and super scripted.
    export let enableHTMLshortcuts = false

    // Minimum number of rows the textarea needs to accommodate space for.
    export let minLines = 3

    // If true, disable automatic capitalization.
    export let noAutoCapitalize = false

    // If true, disable autocorrect behaviour, including Grammarly suggestions.
    export let noAutoCorrect = false

    // Optional string to use as <label>.
    export let label = ''

    // Disables the text area input.
    export let disabled = false

    minLines = Math.max(1, minLines)
    let rows = minLines
    let dummyElement

    const cid = _.uniqueId()

    export function getValue() {
        if (skipValidation) {
            return value.trim()
        }

        // Strip unwanted things like <script> tags. Unescape is here to make characters like & work.
        return Util.unescape(Util.renderContentSafely(value)).trim()
    }

    export function setValue(newValue) {
        value = newValue
        setTextareaHeight()
    }

    export function clearValue() {
        value = ''
    }

    function triggerInputCallback() {
        if (inputCallback) {
            inputCallback(getValue())
        }
    }

    async function setAutoFocus(el) {
        if (autoFocus) {
            // Wait one tick before trying to set focus in textarea, since the component might not be fully
            // rendered at the moment .setAutoFocus() is called.
            await tick()
            Util.placeCaret(el)
        }
    }

    async function setTextareaHeight(el) {
        // Wait for value of `value` to have updated, since updating of Svelte attributes happens asynchronously.
        await tick()
        // Use a dummy element to measure the height the textarea needs
        // We append a '.' character to prevent blank lines at the end from being skipped
        // Set dummy element content equal to that of the text area.
        // Create an empty line to suggests to the user they can type as many lines as
        // they want, except for when the textarea is in single line mode.
        // 24 is the same as line-height defined in styling for textarea and dummy element.
        // Get dummyElement from initial use directive, which gives the Textarea element as argument.
        if (!dummyElement) {
            dummyElement = el.nextElementSibling
        }
        dummyElement.innerHTML = Util.nl2br(_.escape(value + '\n' + (minLines === 1 ? '' : '.')))
        rows = Math.max(
            minLines,
            // Ask jQuery to get the inner height (without margin, border and padding) of the element.
            // Doing this without jQuery is needlessly complicated: https://stackoverflow.com/a/29881817
            Math.ceil(jQuery(dummyElement).height() / 24)
        )
    }

    function enterTab(e) {
        if (!enableInsertTabs) {
            return
        }
        InputUtil.inputTabCharacter(e)
    }

    function enterHTMLShortcut(e) {
        if (!enableHTMLshortcuts) {
            return
        }
        InputUtil.enterHTMLShortcut(e)
    }

</script>

<div class="js-textarea-wrapper container" class:container--has-label={label}>
    {#if label}
        <label for={cid}>{label}</label>
    {/if}
    <textarea
        id={cid}
        bind:value
        {rows}
        on:paste={pasteCallback}
        on:input={triggerInputCallback}
        on:input={setTextareaHeight}
        on:keydown={enterTab}
        on:keydown={enterHTMLShortcut}
        autocorrect={noAutoCorrect ? 'off' : 'on'}
        autocomplete={noAutoCorrect ? 'off' : 'on'}
        spellcheck={!noAutoCorrect}
        data-gramm_editor={!noAutoCorrect}
        data-enable-grammarly={!noAutoCorrect}
        data-gramm={!noAutoCorrect}
        autocapitalize={noAutoCapitalize ? 'off' : 'on'}
        {placeholder}
        {disabled}
        use:setTextareaHeight
        use:setAutoFocus
        component="textarea__input"
    ></textarea>
    <div class="dummy"></div>
</div>

<style lang="scss">
    .container {
        @include text-input;

        padding: 0;
        position: relative;

        // If <label> element is present, add some space to the top of
        // the component to make room for the absolute positioned label.
        &--has-label {
            margin-top: 28px;
        }

        label {
            @include label-text;
            position: absolute;
            top: -8px;
            left: 0;
            right: 0;
            transform: translateY(-100%);
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $blue;
        }

        .dummy {
            visibility: hidden;
            position: absolute;
            top: 0;
        }

        textarea,
        .dummy {
            display: block;
            width: 100%;
            height: auto;
            padding: 14px 16px;
            border: none;
            background-color: transparent;
            overflow: hidden;

            /**
            * Use fixed measurements for typography for reliable height measurements even if
            * the correct font isn't yet loaded.
            */
            font-size: 16px;
            line-height: 24px;

            &:focus {
                outline: none;
            }

            @include text-input-disabled-state;
        }
    }
</style>
