export function getRecipient(conversation) {
    if (conversation.conversation_context.group_id !== null) {
        return {
            name: window.i18n.gettext('Whole group'),
            avatar: 'group'
        }
    }

    if (ACL.checkRole(ACL.roles.STUDENT)) {
        return {
            name: Backbone.Model.user.first_name_last_name(),
            avatar: Backbone.Model.user.get('avatar')
        }
    }

    for (const participant of conversation.participants) {
        const studentModel = Backbone.Collection.students.get(participant.user_id)
        if (studentModel) {
            return {
                name: studentModel.first_name_last_name(),
                avatar: studentModel.get('avatar')
            }
        }
    }

    return { name: window.i18n.gettext('Anonymous user') }
}

export function getSender(message) {
    if (message.user_id === Backbone.Model.user.id) {
        return {
            name: window.i18n.gettext('You'),
            avatar: Backbone.Model.user.get('avatar')
        }
    }

    const teacherModel = Backbone.Collection.teachers.get(message.user_id)
    if (teacherModel) {
        return {
            name: teacherModel.first_name_last_name(),
            avatar: teacherModel.get('avatar')
        }
    }

    const studentModel = Backbone.Collection.students.get(message.user_id)
    if (studentModel) {
        return {
            name: studentModel.first_name_last_name(),
            avatar: studentModel.get('avatar')
        }
    }

    return { name: window.i18n.gettext('Anonymous user') }
}
