import SectionsCollection from 'collections/SectionsCollection';
import ContentLabelModel from './ContentLabelModel';

export default class ChapterModel extends Backbone.Model {

    preinitialize() {
        this.constructor.type = 'chapter'
        this.constructor.pluralType = 'chapters'
    }

    initialize() {

        // Push sections to the global collection (use push instead of add because order in global collection
        // does not matter, in addition of it saving a lot of CPU time)
        this.sections = new SectionsCollection(
            Backbone.Collection.sections.push(this.get('Section'), {
                silent: true,
                parse: true,
            })
        )
        this.unset('Section', {
            silent: true
        })

        this.on('change:sequence', _.debounce(this.setIndex))
    }

    url() {
        if (this.id) {
            return `/chapters/backbone_backend/${this.id}.json`
        }
        return '/chapters/backbone_backend.json'
    }

    /**
         * getParent
         *
         * Return parent model.
         *
         * @returns {GroupModel}            parent group
         */
    getParent() {
        return this.getGroupModel()
    }

    /**
         * getChildren
         *
         * Return child collection in the order of content.
         *
         * @return {SectionsCollection}      Sections connected to this chapter.
         */
    getChildren() {
        return this.sections
    }

    /**
         * getSiblings
         *
         * Get collection of sibling models.
         *
         * @return {ChaptersCollection}      All chapters with the same parent group.
         */
    getSiblings() {
        return this.getParent().chapters
    }

    getGroupModel() {
        return Backbone.Collection.groups.get(this.get('group_id'))
    }

    getCrumblepathModel() {
        return new Backbone.Model({
            label: this.get('name'),
            index: ContentLabelModel.isNumberless(this) ? '' : this.get('index'),
            level: 'chapters',
            path_id: this.get('id'),
        })
    }

    getAllCrumblepathModels() {
        const groupModel = this.getGroupModel()
        const crumblepathModels = []
        crumblepathModels.push(groupModel.getCrumblepathModel())

        if (groupModel.get('layers') > 2) {
            crumblepathModels.push(this.getCrumblepathModel())
        }

        return crumblepathModels
    }

    getSourceId() {
        return this.get('source_chapter_id')
    }

    getName(type = 'show') {
        if (this.getGroupModel().get('layers') === 2) {
            switch (type) {
                case 'show':
                    return window.i18n.gettext('Learning material')
                case 'progress':
                    return window.i18n.gettext('Progress')
                case 'author':
                    return window.i18n.gettext('Author')
            }
        }
        return this.get('name')
    }

    setIndex() {
        let newIndex = this.get('sequence') + 1
        if (!this.get('numberless')) {
            const otherChapters = this.getSiblings().filter((otherChapter) => {
                return !otherChapter.get('numberless') && otherChapter.get('sequence') < this.get('sequence')
            })
            newIndex = otherChapters.length + 1
        }
        this.set({
            index: newIndex,
        })
    }

    savePatchWithKeyToServer(key, value) {
        return new Promise(resolve => {
            const payload = {
                [key]: value
            }

            this.save(payload, {
                patch: true,
                success: (model, response) => {
                    resolve(response?.status)
                }
            })
        })

    }

    isHidden() {
        return this.getGroupModel()?.get('layers') < 2
    }

    isRootLayer() {
        return this.getGroupModel()?.get('layers') === 2
    }

    getPath(type, withoutId = false) {
        const base = `/chapters/${type}`

        if (withoutId) {
            return base
        }
        return `${base}/${this.id}`
    }

    // Get the background image of the chapter or the course
    getBackgroundImage() {
        return this.get('image') || this.getGroupModel()?.getBackgroundImage()
    }

    // Get a random header background image based on the course we are in
    getHeaderImage() {
        return this.getGroupModel()?.getHeaderImage()
    }

}
