<script>
    import Checkbox from '../checkbox/Checkbox.svelte';
    import ContentLabel from '../contentLabel/ContentLabel.svelte';
    import RadioButton from '../radioButton/RadioButton.svelte';

    // Array of objects with either 'label' or 'model' in each object.
    // If 'model' is present, ContentLabel is used to render the model.
    // For multi select, the 'isChecked' attribute sets the selected state.
    // Attributes 'subText', 'rightAlignedText', 'isDisabled' are passed on
    // to the RadioButton or Checkbox.
    export let list

    // If true, use checkboxes. If false, use radio buttons.
    export let isMultiSelect = false

    // For radio buttons, index of the button that is selected
    export let selectedIndex = null

    // For checkboxes, show an option to select all, and label for it
    export let showSelectAllInput = false
    export let selectAllText = window.i18n.gettext('Select all items')

    // Custom styling, currently supports:
    // sidebar: use less whitespace to align with other sidebar elements
    export let style = ''

    // Function to execute when the selection changes
    export let callback = () => {}

    // Message to show if the list has a size of 0
    export let emptyStateMessage = window.i18n.gettext('Empty')

    const cid = _.uniqueId()

    // For checkboxes with select all, we keep a boolean with the select all state
    let allSelected
    if (isMultiSelect && showSelectAllInput) {
        allSelected = isAllSelected()
    }

    export function getSelection() {
        if (!isMultiSelect) {
            return list[selectedIndex]
        }

        return list.filter((item) => {
            return item.isChecked
        })
    }

    function onSelectAll(state) {
        for (const item of list) {
            if (!item.isDisabled) {
                item.isChecked = state
            }
        }
        list = list
        callback()
    }

    function isAllSelected() {
        for (const item of list) {
            if (!item.isDisabled && !item.isChecked) {
                return false
            }
        }

        return true
    }

    function onSelectCheckbox() {
        allSelected = isAllSelected()
        callback()
    }

    function onSelectRadioButton(index) {
        selectedIndex = index
        callback(index)
    }

</script>

<div class="chooser {style}">
    {#if showSelectAllInput && isMultiSelect && list.length > 1}
        <Checkbox
            parentGroup={cid}
            label={selectAllText}
            value="ALL"
            isChecked={allSelected}
            callback={onSelectAll}
        ></Checkbox>
    {/if}

    {#each list as { label, isChecked, rightAlignedText, subText, isDisabled, onClickDisabled, model }, index ((model?.cid || label) + index)}

        {#if isMultiSelect}

            <Checkbox
                parentGroup={cid}
                {label}
                value={index}
                {rightAlignedText}
                {subText}
                {isDisabled}
                {onClickDisabled}
                bind:isChecked
                callback={onSelectCheckbox}>

                <div slot="label">
                    {#if model}
                        <ContentLabel slot="label" {model}></ContentLabel>
                    {:else}
                        {label}
                    {/if}
                </div>

            </Checkbox>

        {:else}

            <RadioButton
                parentGroup={cid}
                value={index}
                isChecked={index === selectedIndex}
                {rightAlignedText}
                {subText}
                {isDisabled}
                callback={onSelectRadioButton}>

                <div slot="label">
                    {#if model}
                        <ContentLabel slot="label" {model}></ContentLabel>
                    {:else}
                        {label}
                    {/if}
                </div>

            </RadioButton>

        {/if}

    {:else}

        <div class="empty">{emptyStateMessage}</div>

    {/each}

</div>

<style lang="scss">
    .chooser {
        > :global(label) {
            display: flex;
            padding: 16px 24px;
            background-color: $white;

            &:has(input[value='ALL']) {
                position: sticky !important;
                top: 0;
                z-index: 1;
                background-color: $background-gray;
            }

            &:hover {
                background-color: $white-highlight;
            }

            &:not(:last-of-type) {
                border-bottom: 1px solid $background-gray;
            }
        }

        .empty {
            padding: 16px 24px;
            background-color: $white;
        }

        &.sidebar {
            > :global(label) {
                padding: 12px 16px;
            }

            .empty {
                padding: 12px 16px;
            }
        }
    }
</style>
