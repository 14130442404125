import Styles from './MenubarItem.scss';

import Template from './MenubarItem.hbs';
import Avatar from 'views/components/avatar/Avatar.svelte';

export default class MenubarItem extends BaseView {

    shouldRender() {
        const menuTypesToShowForExamProduct = [
            'home', 'show', 'progress', 'author', 'library', 'feedback', 'support', 'settings', 'logout'
        ];

        if (Backbone.Model.user.get('exam_product')
            && !menuTypesToShowForExamProduct.includes(this.type)
        ) {
            return false;
        }

        return true;
    }

    initialize({
        type,
        title,
        icon,
        url,
        isMainItem,
        menubar,
        avatar
    }) {

        this.type = type;

        this.setElement(Template({
            Styles,
            url,
            title,
            icon,
            isMainItem
        }));

        this.listenTo(menubar, 'changedLink', this.onChangeLink);

        // Add type specific logic here. For example: if it's a logout
        // button we shouldn't use the a-tag href logic, but call logout
        // on the usermodel.
        switch (this.type) {
            case 'more':
                this.$el.on('click', menubar.toggleFlyout)
                break;
            case 'support':
                this.$el.on('click', this.onClickSupportButton)
                break;
            case 'logout':
                this.$el.on('click', Backbone.Model.user.logOut)
                break;
        }

        if (ISMOBILE) {
            this.$el.on('click', menubar.closeMenu)
        }

        if (avatar) {
            this.addSvelteChildView('.js-icon-holder', Avatar, {
                avatar: Backbone.Model.user.get('avatar')
            })
        }
    }

    onChangeLink({ action, controller }) {
        this.$el.removeClass(Styles['is-active']);

        // Prevents library/home from activating the home button
        if (this.type === 'home' && controller === 'library') {
            return;
        }

        // Prevents words/home from activating the home button
        if (this.type === 'home' && controller === 'words') {
            return;
        }

        if (this.type === controller || this.type === action) {
            this.$el.addClass(Styles['is-active']);
        }
    }

    onClickSupportButton() {

        // Open the support modal
        Backbone.View.layout.showSupport();

        // Log statsTracker for when user clicks on support button with the current URL.
        // This is logged in Matomo under the category 'supportButton'.
        window.statsTracker.trackEvent(
            'supportButton',
            window.app.controller.activePath.join('/')
        );
    }

    setBadge(number) {
        this.$('.js-badge').attr('badge-number', number);
    }
}
