<script>
    import Button from 'views/components/button/Button.svelte';
    import Wysiwyg from 'views/components/wysiwyg/Wysiwyg';
    import { getRecipient } from 'views/pages/users/feedback/FeedbackUtil';

    export let conversation
    export let message
    export let callback

    let wysiwyg
    let sendInProgress
    let saveToStorageFunction

    const storageId = `feedback:edit:${message.id}`;

    function sendFeedback() {
        if (Backbone.Model.user.get('is_demo')) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Demo accounts are not allowed to send feedback'),
                'warning'
            )
            Backbone.View.sidebar.closeSidebar()
            return
        }

        const messageContent = wysiwyg.getContent()
        if (!messageContent) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You did not enter a message.'),
                'info'
            )
            return
        }

        sendInProgress = true
        jQuery.ajax({
            url: `/feedback/messages/${message.id}.json`,
            type: 'PATCH',
            data: {
                message: messageContent
            },
            success: (response) => {
                saveToStorageFunction?.cancel()
                window.sessionStorage.removeItem(storageId)
                callback(response)
                Backbone.View.sidebar.closeSidebar()
            },
            complete: () => {
                sendInProgress = false
            }
        })
    }

    function addWysiwig(element) {
        wysiwyg = new Wysiwyg({
            content: window.sessionStorage.getItem(storageId) || message.message,
            autoFocus: true,
            buttonsPreset: 'sendFeedback'
        })

        element.append(wysiwyg.el)

        saveToStorageFunction = _.debounce(() => {
            const editorContent = wysiwyg.getContent()

            if (!editorContent && window.sessionStorage.getItem(storageId) === null) {
                return
            }

            if (editorContent === '') {
                window.sessionStorage.removeItem(storageId);
                return
            }

            window.sessionStorage.setItem(storageId, editorContent);
        }, 800)

        wysiwyg.on('change', saveToStorageFunction);
    }
</script>

<div class="container">
    <div class="send-to">
        {window.i18n.gettext('Send to')} <strong>{getRecipient(conversation).name}</strong>
    </div>

    <div use:addWysiwig></div>

    <div>
        <Button
            disabled={sendInProgress}
            hasSpinner={sendInProgress}
            label={window.i18n.gettext('Save')}
            inline={true}
            callback={sendFeedback}>
        </Button>
    </div>
</div>

<style lang="scss">
    .container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px;
    }

    .send-to {
        @include large-text;
    }
</style>
