import Styles from './Summary.scss';

import Template from './Summary.hbs';
import Button from 'views/components/button/Button'
import ExamIntroductionTemplate from 'views/pages/activities/author/types/exam/examIntroduction/ExamIntroduction.hbs';
import ExamIntroductionStyles from 'views/pages/activities/author/types/exam/examIntroduction/ExamIntroduction.scss';
import SubjectRowTemplate from 'views/pages/activities/show/types/exam/summary/subjectRow/SubjectRow.hbs';
import ProgressBar from 'views/components/progressBar/ProgressBar.svelte';
import HeroButton from 'views/components/heroButton/HeroButton'
import MissingTask from './MissingTask.svelte'

export default class ExamActivitySummary extends BaseView {

    initialize({
        work_on,
        isExamStart,
    }) {

        this.work_on = work_on

        _.bindAll(
            this,
            'openSEBSession'
        );

        const isStudentExamMode = this.work_on.isStudentExamMode;
        const isStudentRedirectedToSEB = this.work_on.isStudentRedirectedToSEB;

        const ungradedTasksCount = ACL.checkRole(ACL.roles.STUDENT) &&
            this.model.get('exam_session').closed &&
            this.model.getUngradedAnswersCount()

        const hasExamGrade = ungradedTasksCount === 0 && this.hasExamGrade()

        // Calculate the total points per subject (metadata for a task) for the exam
        let pointsPerSubject
        if (
            (ACL.checkRole(ACL.roles.STUDENT) && hasExamGrade) ||
            (ACL.checkRole(ACL.roles.TEACHER) && this.model.responses.length > 0)
        ) {
            pointsPerSubject = this.calculatePointsPerSubject()
        }

        this.setElement(Template({
            Styles,
            numTaskGroups: this.model.task_groups.length,
            pluralTaskGroups: window.i18n.ngettext('task group', 'task groups', this.model.task_groups.length),
            hasExamGrade,
            examGrade: this.model.get('ExamGrade')?.exam_grade,
            examAttainablePoints: this.model.get('ExamGrade')?.exam_attainable_points,
            examAttainedPoints: this.model.get('ExamGrade')?.exam_attained_points,
            examTypeLabel: this.model.getActivityTypeLabel(),
            examTitle: this.model.get('name'),
            examEndTitle: window.i18n.sprintf(
                window.i18n.gettext('End of %s'),
                this.model.getActivityTypeLabel().toLowerCase()
            ),
            isExamStart,
            showNumTaskGroups:
                ACL.checkRole(ACL.roles.TEACHER) ||
                (
                    isExamStart && !hasExamGrade && !ungradedTasksCount && !isStudentRedirectedToSEB
                ),
            ungradedTasksCount,
            ungradedCountText: ungradedTasksCount && this.model.getUngradedAnswersText(false),
            showPointsPerSubject: !!pointsPerSubject,
        }));

        if (pointsPerSubject) {
            this.renderSubjectsBlock(pointsPerSubject)
        }

        // Add either a start or end button depending on the location of this activity summary.
        if (isExamStart) {

            if (isStudentRedirectedToSEB) {

                // Add button to hero for link to support article with instructions on how to
                // download and install SEB.
                Backbone.View.header.addButton(
                    new HeroButton({
                        firstLine: window.i18n.gettext('Download Safe Exam Browser'),
                        callback: () => {
                            window.open('https://support.learnbeat.nl/article/ocfejs0wmv-safe-exam-browser-downloaden');
                        }
                    })
                )

                let introductionText = window.i18n.gettext(
                    'This exam can only be made with Safe Exam Browser.'
                );

                // If user is using an Android or Chromebook device, show information in these
                // OSes not being supported by SEB. Otherwise show start exam button.
                const clientOS = window.uaparser.getOS().name;
                if (clientOS === 'Android') {
                    introductionText += ' ';
                    introductionText += window.i18n.gettext(
                        'This application is not available for Android devices.'
                    );
                } else if (clientOS === 'Chromium OS') {
                    introductionText += ' ';
                    introductionText += window.i18n.gettext(
                        'This application is not available for Chromebook devices.'
                    );
                } else {
                    // Button for opening a SEB session.
                    this.openSEBBtn = this.addChildView(new Button({
                        label: window.i18n.gettext('Start exam'),
                        iconRight: 'arrow-right',
                        size: 'medium',
                        callback: this.openSEBSession
                    }), '.js-seb-start-btn');
                }

                this.$('.js-introduction').text(introductionText);

            } else {

                const examIntroductionText = this.model.getMetadata('exam_introduction_text');
                if (
                    // If user is student in exam mode OR user is teacher.
                    (isStudentExamMode || ACL.checkRole(ACL.roles.TEACHER)) &&
                    // AND examIntroductionText exists.
                    examIntroductionText
                ) {
                    const author = Backbone.Collection.teachers.get(
                        this.model.getMetadata('exam_introduction_author')
                    );
                    this.$('.js-introduction').append(ExamIntroductionTemplate({
                        Styles: ExamIntroductionStyles,
                        content: examIntroductionText,
                        authorAvatar: author && author.get('avatar'),
                        authorName: author && author.first_name_last_name()
                    }));
                }

                this.addChildView(new Button({
                    label: ACL.checkRole(ACL.roles.STUDENT) && this.model.get('show_answers') ?
                        window.i18n.gettext('Review exam') :
                        window.i18n.gettext('Begin'),
                    iconRight: 'arrow-right',
                    size: 'medium',
                    callback: this.work_on.navigationBar.toNextItem,
                }), '.js-start-finish-btn');

            }

        } else {

            if (isStudentExamMode) {
                var unfinishedTasks = this.work_on.getUnfinishedTasks();

                if (unfinishedTasks.length) {
                    this.$('.js-missing-tasks-title').text(this.work_on.getUnfinishedTasksText());

                    // Only display seperate task labels if there are less than 11 unfinishedTasks to
                    // prevent overwhelming the user.
                    if (unfinishedTasks.length < 11) {
                        for (const taskModel of unfinishedTasks) {
                            const taskGroupModel = this.model.task_groups.get(taskModel.get('task_group_id'))
                            this.addSvelteChildView(this.$('.js-missing-tasks'), MissingTask, {
                                label: taskGroupModel.get('index') + taskModel.get('index'),
                                clickCallback: () => {
                                    this.work_on.navigationBar.setActiveItem(taskGroupModel.get('sequence'))
                                }
                            })
                        }
                    }
                }
            }

            let finishExamButtonLabel = window.i18n.gettext('Finish')
            if (isStudentExamMode) {
                finishExamButtonLabel = this.model.get('type') === 'diagnostic_exam' ?
                    window.i18n.gettext('Hand in diagnostic exam') :
                    window.i18n.gettext('Hand in exam')
            }
            this.addChildView(new Button({
                label: finishExamButtonLabel,
                icon: isStudentExamMode ? 'take-in' : 'cross',
                size: 'medium',
                callback: this.work_on.onClickFinishExamActivity
            }), '.js-start-finish-btn');
        }

        if (ISMOBILE && !isStudentRedirectedToSEB && !_.isEmpty(this.model.task_groups)) {
            this.addChildView(new Button({
                label: window.i18n.gettext('Jump to task group'),
                theme: 'transparent',
                callback: this.work_on.showSidebarActivityNavigation,
                iconRight: 'arrow-right',
            }), '.js-jump-to-task-group')
        }
    }

    /**
     * @returns {Map} subjects with their received points and attainable points
     */
    calculatePointsPerSubject() {

        // Calculate the attainable points per subject by looping through all tasks in the exam
        const attainablePointsPerSubject = new Map()
        const subjectNames = new Map()
        for (const task of this.model.tasks) {
            if (!task.get('Subject').subject) {
                continue
            }
            const newPoints = this.model.get('ExamPoint')[task.id]
            const existingPoints = attainablePointsPerSubject.get(task.get('subject_id')) || 0
            attainablePointsPerSubject.set(task.get('subject_id'), existingPoints + newPoints)

            const subjectName = task.get('subject_id') === 0 ? window.i18n.gettext('Other tasks') : task.get('Subject').subject
            subjectNames.set(task.get('subject_id'), subjectName)
        }

        // Only show the scores per subject if there are 2 or more subjects
        if (subjectNames.size < 2) {
            return null
        }

        // Calculate the received points per subject by looping through all responses
        const receivedPointsPerSubject = new Map()
        const uniqueStudents = new Set()
        for (const response of this.model.responses) {
            const subjectId = this.model.tasks.get(response.get('task_id'))?.get('subject_id')
            if (subjectId === undefined) {
                continue
            }

            uniqueStudents.add(response.get('user_id'))

            const attainablePoints = this.model.get('ExamPoint')[response.get('task_id')]
            const score = Math.max(response.get('score'), 0)
            const newPoints = Math.round(score * attainablePoints * 2) / 2
            const existingPoints = receivedPointsPerSubject.get(subjectId) || 0
            receivedPointsPerSubject.set(subjectId, existingPoints + newPoints)
        }

        // Calculate the average received points if needed (for teachers)
        if (uniqueStudents.size > 1) {
            for (const [subjectId, points] of receivedPointsPerSubject) {
                const averagePoints = Math.round(points / uniqueStudents.size * 10) / 10
                receivedPointsPerSubject.set(subjectId, averagePoints)
            }
        }

        // Combine the results in one map
        let pointsPerSubject = new Map()
        for (const [subjectId, subjectName] of subjectNames) {
            pointsPerSubject.set(subjectId, {
                subjectName,
                receivedPoints: receivedPointsPerSubject.get(subjectId) || 0,
                attainablePoints: attainablePointsPerSubject.get(subjectId)
            })
        }

        // Sort the map by points
        pointsPerSubject = this.sortSubjectsOnPoints(pointsPerSubject)

        return pointsPerSubject
    }

    /**
     * @param {Map} pointsPerSubject subjects with responses
     * @returns {Map} sorted map containing subjects with responses
     */
    sortSubjectsOnPoints(pointsPerSubject) {

        // do not include subject id 0 in sorting: always show it as the last item
        // remove and append to the Map after sorting
        let noSubject
        if (pointsPerSubject.has(0)) {
            noSubject = pointsPerSubject.get(0)
            pointsPerSubject.delete(0)
        }

        // sample subject:
        // [subjectId, subjectObject]
        // [1234, { subjectName: 'abc', receivedPoints: 1.5, attainablePoints: 2 }]
        const sortedMap = new Map([...pointsPerSubject].sort(
            (
                [, {
                    subjectName: previousSubjectName,
                    receivedPoints: previousReceivedPoints,
                    attainablePoints: previousAttainablePoints
                }],
                [, {
                    subjectName: nextSubjectName,
                    receivedPoints: nextReceivedPoints,
                    attainablePoints: nextAttainablePoints
                }]
            ) => {
                // triple sorting: if receivedPoints / attainablePoints is equal , the difference will be 0
                // = falsy. evaluate next right hand side, etc.
                // sort on : 1) received points / attainable points ratio, 2) attainable points and 3) name
                return (
                    (nextReceivedPoints / nextAttainablePoints) - (previousReceivedPoints / previousAttainablePoints) ||
                    nextAttainablePoints - previousAttainablePoints ||
                    previousSubjectName.localeCompare(nextSubjectName, window.app_version.language, { sensitivity: 'base' })
                )
            }
        ))

        if (noSubject) {
            sortedMap.set(0, noSubject)
        }

        return sortedMap
    }

    hasExamGrade() {
        return _.isFinite(this.model.get('ExamGrade')?.exam_grade)
    }

    /**
     * Redirect student via the Safe Exam Browser URL to start making the exam inside the
     * SEB environment. If the student does not have a valid exam session, meaning that they
     * have either already handed in the exam or the teacher has taken in the exam, show a
     * alert telling them such and redirect them to Learnbeat home.
     *
     * @param  {Object} response    GET call response object
     */
    openSEBSession() {
        if (this.model.get('seb_link')) {

            // Check exam state in backend when user switches tab visibility. Needed to redirect
            // user to home view after quitting SEB
            window.addEventListener('focus', this.checkExamState);

            // Navigate to generated SEB URL.
            document.location = this.model.get('seb_link');

        } else {

            Backbone.View.layout.openStatus(window.i18n.gettext(
                'Something went wrong loading this activity'
            ), 'error');

            window.sentry.withScope(() => {
                window.sentry.captureMessage('Can\'t find SEB URL', {level: 'info'});
            });

        }
    }

    /**
     * Ask backend if user should be in exam or not.
     */
    checkExamState() {
        window.app.checkStatus()
    }

    /**
     * Fade in and slide up the whole view when transition animation
     * called by work_on is finised to ensure a smooth transition.
     */
    onInView() {
        TweenMax.to(this.$('> div'), {
            duration: 0.3,
            // Set opacity to 1
            opacity: 1,
            // Set y axis to 0
            y: 0
        });
    }

    // Display subjects block on summary screen
    renderSubjectsBlock(pointsPerSubject) {

        const container = this.el.querySelector('.js-subjects')

        for (const [ subjectId, { subjectName, receivedPoints, attainablePoints } ] of pointsPerSubject) {

            container.insertAdjacentHTML('beforeend', SubjectRowTemplate({
                subjectName,
                receivedPoints: receivedPoints.toLocaleString(),
                attainablePoints: attainablePoints.toLocaleString(),
                subjectId,
                Styles
            }))

            this.addSvelteChildView(container.querySelector(`[data-progress="${subjectId}"]`), ProgressBar, {
                fragments: { blue: receivedPoints / attainablePoints },
            })
        }

    }

    beforeDestroy() {
        window.removeEventListener('focus', this.checkExamState);
    }

}
