<script>
    import { fade, scale } from 'svelte/transition'
    import Util from 'util/util'
    import Icon from 'views/components/icon/Icon.svelte'

    export let slides = []
    export let openAtSlide = null
    export let isOpen = true

    openAtSlide = openAtSlide === null && slides.length > 0 ? slides[0].url : openAtSlide

    let isZoomedIn = false
    let slidesContainer
    let currentSlideElement

    // Padding around each slide in pixels.
    const slidePadding = 14

    function gotoSlide(slideElement, instant = false) {
        if (slideElement) {
            isZoomedIn = false
            slideElement.scrollIntoView({
                behavior: instant ? 'instant' : 'smooth',
                inline: 'center',
            })
            if (instant) {
                currentSlideElement = slideElement
            }
        }
    }

    function setInitialSlide(el) {
        if (openAtSlide) {
            gotoSlide(el.querySelector(`#slide-${CSS.escape(openAtSlide)}`), true)
        }
    }

    function onKeyNavigation({key}) {
        if (key === 'ArrowLeft') {
            gotoSlide(currentSlideElement?.previousElementSibling)
        } else if (key === 'ArrowRight') {
            gotoSlide(currentSlideElement?.nextElementSibling)
        } else if (key === 'Escape') {
            isOpen = false
        }
    }

    function getCurrentSlide() {
        for (const slideElement of slidesContainer.children) {
            const slideRect = slideElement.children[0].getBoundingClientRect()
            if (slideRect.left > 0 && slideRect.right < window.innerWidth) {
                currentSlideElement = slideElement
                break
            }
        }
    }

    function setCanToggleZoom({target}) {
        // Allow for zooming if height of image file in pixels is taller than the inner height of
        // the window minus padding, while the displayed width of the image is smaller than the
        // width of the window minus padding, meaning that images that stretch the full width of
        // the container cannot be zoomed.
        if (
            target.naturalHeight > window.innerHeight - (slidePadding + slidePadding) &&
            target.width < window.innerWidth - (slidePadding + slidePadding)
        ) {
            target.dataset.canZoom = true
        }
    }

    function toggleZoom({target}) {
        if (target.dataset.canZoom) {
            isZoomedIn = !isZoomedIn
        }
    }
</script>

<svelte:document on:keyup={onKeyNavigation} />

{#if isOpen}
    <div
        data-disable-scrolling
        class="background"
        class:zoomed-in={isZoomedIn}
        out:fade={{ duration: 200 }}
        use:Util.setFocusTrap
    >
        <button class="close-button" on:click={() => { isOpen = false }}><Icon name="cross"></Icon></button>

        {#if slides.length > 1}
            <button
                class="navigate-left"
                disabled={!currentSlideElement?.previousElementSibling}
                on:click={() => { gotoSlide(currentSlideElement?.previousElementSibling) }}
            >
                <Icon name="arrow-left"></Icon>
            </button>
            <button
                class="navigate-right"
                disabled={!currentSlideElement?.nextElementSibling}
                on:click={() => { gotoSlide(currentSlideElement?.nextElementSibling) }}
            >
                <Icon name="arrow-right"></Icon>
            </button>
        {/if}

        <div
            class="container"
            role="dialog"
            aria-modal="true"
            out:scale={{ duration: 200, start: 0.8 }}
            bind:this={slidesContainer}
            on:scroll={getCurrentSlide}
            use:setInitialSlide
        >
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            {#each slides as slide (slide.url)}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <figure
                    id="slide-{slide.url}"
                    style:--slide-padding={slidePadding + 'px'}
                    on:click|self={() => { isOpen = false }}
                >
                    {#if slide.isVideo}
                        <video
                            src={slide.url}
                            autoplay
                            playsinline
                            loop
                            muted
                        ></video>
                    {:else}
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
                        <img
                            src={slide.url}
                            alt={slide.caption || ''}
                            on:load={setCanToggleZoom}
                            on:click={toggleZoom}
                        />
                    {/if}
                </figure>
            {/each}
        </div>
    </div>
{/if}

<style lang="scss">
    .background {
        @include z-index-modal;

        position: fixed;
        inset: 0;
        display: flex;
        background-color: $black-50;
        animation: backgroundIn 0.35s forwards;

        @keyframes backgroundIn {
            0% { opacity: 0; }
        }

        > button {
            @include button;
            position: absolute;
            z-index: 1;
            transition: transform 0.2s, opacity 0.2s;

            :global(svg) {
                height: 2rem;
                width: 2rem;
            }

            &:not(:disabled) {
                @include button-hover-state;
                @include button-active-state;

                &:hover {
                    opacity: 1;
                }

                &:focus:not(&:active) {
                    @include button-focus-state('hero');
                }
            }
        }
        .close-button {
            top: 0px;
            right: 0px;
            padding: 6px 6px 8px 8px;
            border-radius: 0px 0px 0px 12px;
        }
        .navigate-left,
        .navigate-right {
            top: 50%;
            padding: 8px;
            transform: translate(0, -50%);
        }
        .navigate-left {
            left: 0;
            border-radius: 0px 12px 12px 0px;

            &:disabled {
                transform: translate(-100%, -50%);
            }
        }
        .navigate-right {
            right: 0;
            border-radius: 12px 0px 0px 12px;

            &:disabled {
                transform: translate(100%, -50%);
            }
        }
    }

    .container {
        display: flex;
        width: 100%;
        height: 100%;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        overscroll-behavior: contain;
        animation: containerIn 0.35s forwards;

        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        @keyframes containerIn {
            0% {
                transform: scale(0.8);
                opacity: 0.4;
            }
        }
    }

    figure {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        padding: var(--slide-padding);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        scroll-snap-align: center;

        img,
        video {
            object-fit: scale-down;
            max-width: 100%;
            max-height: 100%;
        }

        img {
            background-color: $white;
            border-radius: 4px;
        }

        img:global([data-can-zoom]) {
            cursor: zoom-in;
        }
    }

    .zoomed-in {
        > button {
            opacity: 0.4;
        }

        .container {
            overflow: hidden;

            figure {
                justify-content: unset;
                overflow: scroll;
                overscroll-behavior: none;

                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }

                img {
                    max-height: unset;
                    cursor: zoom-out;
                }
            }
        }
    }
</style>
