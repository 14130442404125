import Styles from './Item.scss';

import Template from './Item.hbs';
import Util from 'util/util';
import ZoomButton from 'views/components/lightbox/zoomButton/ZoomButton';
import ShapeList from 'util/ShapeLoader';

export default BaseView.extend({

    events: {
        'click .js-chained-item .js-item': 'onRemoveItem'
    },

    initialize(options) {

        _.bindAll(
            this,
            'onDrop',
            'onRemoveItem'
        );

        // Collection of items in the right column.

        this.columnBItems = options.columnBItems;

        this.setElement(Template({
            Styles,
            cid: this.model.cid,
            name: Util.renderContentSafely(this.model.get('data')),
            isImage: options.inputType === 'image',
            isColumnA: options.isColumnA,
            isChained: options.isChained,
            isAuthor: APPLICATION === 'author'
        }));

        // If inputType is image, add a ZoomButton to this view to toggle a fullscreen lightbox.
        if (options.inputType === 'image') {
            this.addChildView(new ZoomButton({
                imageElement: this.$('.js-media'),
                contextUrl: '/edu_files/open/' + this.model.get('data')
            }), '.js-media');
        }

        // If item is located in the left column, make this view a droppable.
        if (options.isColumnA) {

            this.$el.droppable({
                accept: '.js-item',
                tolerance: 'pointer',
                drop: this.onDrop,
                over: this.onHover,
                scope: options.taskID
            });

            // If item has something chained to it, render it.
            if (this.model.get('chained')) {
                this.onAddItem(this.model.get('chained'));
            }
        }

    },

    /**
     * onDrop
     *
     * Trigger when an element with the class .js-item gets dropped on this view.
     *
     * @param  {jQuery.event} e  jQuery event data.
     * @param  {Object}       ui jQuery UI draggable event data.
     */
    onDrop(e, ui) {

        var dragItem = $(ui.draggable);

        var itemModel = this.columnBItems.get($(dragItem).data('cid'));
        // Check if itemModel actually exists in this.columnBItems.
        // This is to prevent trying to chain an item to multiple items at once.
        if (itemModel) {
            this.columnBItems.add(this.model.get('chained'));
            this.model.set({
                chained: itemModel
            });
            this.columnBItems.remove(itemModel);
            this.columnBItems.trigger('answerUpdate');

            // Create a new item view inside this category view.
            this.onAddItem(itemModel);
        }

        // Hide the dragged item now it's replaced with another item view.
        dragItem.hide();

    },

    /**
     * onAddItem
     *
     * Create a item view for the item that is chained to the current view.
     *
     * @param  {Backbone.Model} model data from this.model.get('chained')
     */
    onAddItem(model) {
        // If chainedItem view instance exists, destroy it.
        if (this.chainedItem) {
            this.unregisterAndDestroyChildView(this.chainedItem);
        }
        // Create a new item view for the chained item using the chained item model
        // by creating a new instance of Item, which also happens to be the current view.
        this.chainedItem = this.addChildView(new this.constructor({
            model,
            isChained: true
        }), '.js-chained-item');
    },

    /**
     * onRemoveItem
     *
     * When user clicks on an item view that is chained, remove the chained data from this view,
     * destroy the chained view and trigger an answerUpdate.
     *
     * @param  {jQuery.event} e jQuery click event
     */
    onRemoveItem(e) {
        if (!$(e.currentTarget).hasClass('js-show-answers')) {
            this.columnBItems.add(this.model.get('chained'));
            this.model.unset('chained');
            this.unregisterAndDestroyChildView(this.chainedItem);
            this.columnBItems.trigger('answerUpdate');
        }
    },

    /**
     * lock
     *
     * This will function will set the visual state of the item to locked
     *
     */
    lock() {

        // Based on the chained state of the item, either a reset or move icon is shown
        // Switch the locked icon with the icon that is being displayed
        var iconToSwitch = this.model.get('chained') ? 'reset' : 'move';
        this.$('.js-icon-' + iconToSwitch).html(ShapeList.lock);

        this.$el.addClass(Styles['is-locked']);
        this.undelegateEvents();

        if (this.$el.hasClass('ui-draggable')) {
            this.$el.draggable('disable')
        }

    },

    /**
     * unlock
     *
     * This function will remove the locked visual styling of a locked item
     *
     */
    unlock() {

        // Based on the chained state of the item we have to switch the locked icon
        // for a reset or move icon
        var iconToSwitch = this.model.get('chained') ? 'reset' : 'move';
        this.$('.js-icon-' + iconToSwitch).html(
            ShapeList[this.model.get('chained') ? 'cross' : 'move']
        );

        this.$el.removeClass(Styles['is-locked']);
        this.delegateEvents();

        if (this.$el.hasClass('ui-draggable')) {
            this.$el.draggable('enable')
        }

    }

});
