import TaskGroupsCollection from 'collections/TaskGroupsCollection'

export default class AdaptiveTaskGroupsCollection extends TaskGroupsCollection {

    get url() {
        return `/activities/get_adaptive_taskgroups_for_subjects/${this.activity_id}.json`
    }

    modelId(attr) {
        // Adaptive activities might repeat task groups. These should be considered as unique and therefore should
        // have a unique ID when creating the collection.
        return `${attr.sequence}_${attr.id}`
    }

    initialize(attr, {activityModel}) {
        this.activity_id = activityModel.id
    }

}
