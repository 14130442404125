import Styles from './Keyboard.scss';

import Template from './Keyboard.hbs';
import TabsView from 'views/components/tabs/Tabs';
import KeyboardLayout from 'views/components/keyboard/keyboardLayout/KeyboardLayout';
import Spinner from 'views/components/spinner/Spinner'

export default TabsView.extend({

    // Optional view that is include at the top of the keyboard that shows a
    // preview of what the user is typing.
    inputView: undefined,

    isOpen: false,

    /**
     * initialize
     *
     * Initializing function, which will be called on creation. It
     * will create a DOM element based on the given template.
     *
     */
    initialize() {

        _.bindAll(
            this,
            'addKeyboardLayout',
            'onKeyPress',
            'setKeyMode',
            'close',
            'reset'
        );

        this.setElement(Template({
            Styles
        }));

        // Element containing the keyboard itself.
        this.keyboardPanel = this.$('.js-keyboard-panel');

        // Will prevent moving focus when keyboard buttons are clicked.
        this.keyboardPanel.on('mousedown', (e) => {
            this.stopAllEvents(e)
        })

    },

    /**
     * open
     *
     * Opens the keyboard view and creates a keyboard layout and inputView.
     *
     * @param  {Object} keyboardLayoutList List of keyboard layouts.
     * @param  {Backbone.View} InputView   View used to preview the typed content (optional).
     * @param  {Object} options            Options to pass to the InputView (optional).
     * @param  {Promise} onInputCallback   Async function to call on each new input (optional).
     * @param  {Promise} onCloseCallback   Async function to call when closing the keyboard view.
     */
    async open(keyboardLayoutList, InputView, options, onInputCallback, onCloseCallback) {

        // Reset the keyboard state from the previous time it was opened.
        this.reset();

        // Create a new keyboard layout from each array item. Assign them all to a seperate tab.
        _.each(keyboardLayoutList, this.addKeyboardLayout);
        this.addTabs(true);

        const submitButton = this.$('.js-submit')

        // If an InputView has been defined, render said view and attach a click listener for all keys that calls
        // the onVirtualKeyboardInput method of the inputView. If InputView is undefined, attach a click listener
        // that calls the onInputCallback on each click, if this function is defined.
        if (InputView) {
            // Blur focus of any active elements in the scope of window to prevent users
            // from typing outside the keyboard view.
            window.document.activeElement.blur();

            this.inputView = this.addChildView(new InputView(options), '.js-keyboard-output');
            try {
                // Wait for editor portion of InputView (e.g. MathQuill) to load.
                await this.inputView.loadEditor()
            } catch {
                // If editor cannot be loaded show error message, destroy input view and abort.
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('You are offline. Check your internet connection.'),
                    'warning'
                );
                this.inputView.destroy()
                return
            }
            this.keyboardPanel.on('click', _.partial(this.onKeyPress, _, this.inputView.onVirtualKeyboardInput));

            // Using an async function as the callback allows displaying the state of requested input as well as
            // offering a way to cancel a request.
            submitButton.on('click', () => {
                const spinner = this.addChildView(new Spinner({
                    isInsideButton: true
                }), '.js-spinner')
                onCloseCallback().finally(() => {
                    spinner.destroy()
                })
            })
        } else if (onInputCallback) {
            this.keyboardPanel.on('click', _.partial(this.onKeyPress, _, onInputCallback));
            submitButton.hide()
        }

        this.$('.js-close').one('click', this.close);

        // Intro animation.
        TweenMax.to(
            this.$el, {
                duration: 0.4,
                top: 0,
                display: 'flex',
                ease: 'expo.out'
            }
        );

        // Add a class to the body, so that users can still scroll to the bottom of the page
        document.body.classList.add('keyboard-open')

        this.isOpen = true;

    },

    /**
     * addKeyboardLayout
     *
     * Create a new tab for each keyGroup.
     *
     * @param {Object} keyGroupList object containing the title and a layout of keys.
     */
    addKeyboardLayout(keyGroupList) {
        this.tabs.push({
            type: keyGroupList.title,
            label: keyGroupList.title,
            view: KeyboardLayout,
            viewParameters: {
                keyGroupList: keyGroupList.keyGroups
            }
        });
    },

    /**
     * onKeyPress
     *
     * When a key is clicked/touched, trigger the callback.
     * If it's the CapsLock key, change the whole keyboard to UPPERCASE or lowercase.
     *
     * @param  {Event}    e event data
     * @param  {Function} callback Function to call
     */
    onKeyPress(e, callback) {

        this.stopAllEvents(e)

        // Check if clicked element is actually a key.
        if (e.target.classList.contains('js-key')) {

            // Check if the CapsLock and the view behind the currently active tab has a caps lock key.
            if (e.target.dataset.cmd === 'CapsLock' && this.activeView.capsLockKey.length) {

                // Switch UPPERCASE/lowercase for all keys that aren't special keys of the current layout.
                this.setKeyMode(e.target.parentElement.querySelectorAll('.js-key'))
                this.activeView.capsLock = !this.activeView.capsLock;
                this.activeView.setKeyActive(this.activeView.capsLockKey, this.activeView.capsLock);

            } else {

                const cmd = this.activeView.capsLock ?
                    e.target.dataset.shiftCmd || e.target.dataset.cmd.toUpperCase() :
                    e.target.dataset.cmd

                callback(cmd, !!e.target.dataset.s);

                // Reset Capslock mode after pressing non-special key.
                if (this.activeView.capsLockKey.length && this.activeView.capsLock) {
                    this.setKeyMode(e.target.parentElement.querySelectorAll('.js-key'))
                    this.activeView.capsLock = false;
                    this.activeView.setKeyActive(this.activeView.capsLockKey, false);
                }

            }
        }
    },

    /**
     * setKeyMode
     *
     * Switch UPPERCASE/lowercase of all keys of the current layout.
     *
     * @param {Array} keyGroupMembers Array of key elements.
     */
    setKeyMode(keyGroupMembers) {
        // Loop through all keys elements.
        for (const key of keyGroupMembers) {
            // If key invokes some sort of special command, do not convert case.
            if (key.dataset.s) {
                continue
            }

            if (this.activeView.capsLock) {
                key.innerHTML = key.dataset.key
            } else {
                key.innerHTML = key.dataset.shiftKey || key.innerHTML.toUpperCase();
            }
        }

    },

    /**
     * close
     *
     * Close keyboard.
     */
    close() {
        this.keyboardPanel.off('click');
        this.$('.js-submit,.js-close').off();
        TweenMax.to(
            this.$el, {
                duration: 0.8,
                display: 'none',
                top: '100%',
                ease: 'expo.out',
                onComplete: this.reset
            }
        );
        document.body.classList.remove('keyboard-open')
    },

    /**
     * reset
     *
     * Reset all data to it's initial values.
     */
    reset() {
        this.isOpen = false;
        this.inputView = undefined;
        this.destroyChildViews();
        this.tabs = [];
    }

});
